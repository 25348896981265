import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import Loader from "../../components/Loader";
import { useLocation, Link } from 'react-router-dom';
import './style.css'
import API from '../../utils/API';
import Swal from 'sweetalert2';
import GlobalSVG from '../../utils/GlobalSVG';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import GlobalFuncs from '../../utils/GlobalFuncs';

const OpHours = () => {

    const location = useLocation();
    const [building, setBuilding] = useState(null)
    const [getPutPost, setGetPutPost] = useState()
    const [buildingData, setBuildingData] = useState({ building: "" })
    const [opHours, setOpHours] = useState([])
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const context = useContext(AppContext)
    const queryParams = new URLSearchParams(location.search);
    const [yearViewed, setYearViewed] = useState(2021)
    const [monthViewed, setMonthViewed] = useState(1)
    const [daysOfMonth, setDaysOfMonth] = useState([])
    const [range, setRange] = useState([])
    const [start, setStart] = useState(null)
    const [end, setEnd] = useState(null)
    const [startEnd, setStartEnd] = useState(null)
    const [bulkDates, setBulkDates] = useState({})
    const [bulkUpdate, setBulkUpdate] = useState(false)

    const monthDict = {
        1: 'Jan', 2: 'Feb', 3: 'Mar', 4: 'Apr',
        5: 'May', 6: 'Jun', 7: 'Jul', 8: 'Aug',
        9: 'Sep', 10: 'Oct', 11: 'Nov', 12: 'Dec'
    };

    const hours = ["00:00:00", "01:00:00", "02:00:00", "03:00:00", "04:00:00", "05:00:00", "06:00:00", "07:00:00", "08:00:00", "09:00:00", "10:00:00", "11:00:00", "12:00:00", "13:00:00", "14:00:00", "15:00:00", "16:00:00", "17:00:00", "18:00:00", "19:00:00", "20:00:00", "21:00:00", "22:00:00", "23:00:00"]
    const dayOfWeekDict = { 0: 'Sunday', 1: 'Monday', 2: 'Tuesday', 3: 'Wednesday', 4: 'Thursday', 5: 'Friday', 6: 'Saturday' }

    useEffect(() => {
        if (getPutPost === 'put') return
        if (queryParams.size !== 0) {
            setBuilding(queryParams.get('building'))
            setGetPutPost('get')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams, location])

    useEffect(() => {
        if (!startEnd) return
        const startDate = new Date(startEnd[0]);
        startDate.setDate(startDate.getDate() + 1);
        setStart(startDate)
        const endDate = new Date(startEnd[1]);
        endDate.setDate(endDate.getDate() + 1);
        setEnd(endDate)
    }, [startEnd])

    useEffect(() => {
        const cancelToken = API.cancelToken();
        if (building !== null) {
            setLoading(true)
            const auth = context.getUser.token;
            API.getHours(cancelToken, auth, building).then(res => {
                setLoading(false)
                setOpHours(res.data)
                return
            }).catch(err => {
                setLoading(false)
                console.log(err)
                if (err.message === 'cancelling') return
                let message = 'Please try again later.'
                if (err.response && err.response.data && err.response.data.includes('error')) {
                    message = err.response.data.error
                }
                Swal.fire({
                    title: 'Error.',
                    text: message,
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });

            })
            API.getBuilding(cancelToken, auth, building).then(res => {
                setLoading(false)
                setBuildingData(res.data)
                return
            }).catch(err => {
                setLoading(false)
                console.log(err)
                if (err.message === 'cancelling') return
                let message = 'Please try again later.'
                if (err.response && err.response.data && err.response.data.includes('error')) {
                    message = err.response.data.error
                }
                Swal.fire({
                    title: 'Error.',
                    text: message,
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });

            })
        }
        return () => {
            API.cancel(cancelToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [building])

    const updateBuilding = (e) => {
        const { name, value } = e.target;
        if (name === 'image' || name === 'rep_image') {
            const selectedImage = e.target.files[0];
            if (selectedImage) {
                const reader = new FileReader();
                reader.onload = function (e) {
                    const base64ImageData = e.target.result.split(',')[1];
                    setBuildingData(prevValue => {
                        return {
                            ...prevValue,
                            [name]: base64ImageData
                        }
                    })
                };

                reader.readAsDataURL(selectedImage);
            }
        } else {
            setBuildingData(prevValue => {
                return {
                    ...prevValue,
                    [name]: value.trim()
                }
            })
        }
    }

    const handleYearChange = (type) => {
        if (type === "-" && yearViewed > 2021) setYearViewed(yearViewed - 1)
        if (type === "+") setYearViewed(yearViewed + 1)
    }

    const handleMonthChange = (type) => {
        if (type === "-" && monthViewed > 1) setMonthViewed(monthViewed - 1)
        if (type === "+" && monthViewed < 12) setMonthViewed(monthViewed + 1)
    }

    const handleHour = (timestamp) => {
        if (getPutPost === 'get') return
        if (opHours.includes(timestamp)) setOpHours(curr => curr.filter(e => e !== timestamp))
        else setOpHours(curr => [...curr, timestamp])
    }

    useEffect(() => {
        const daysInMonth = new Date(yearViewed, monthViewed, 0).getDate();
        const days = [];

        for (let day = 1; day <= daysInMonth; day++) {
            const date = new Date(yearViewed, monthViewed - 1, day);
            const formattedDate = date.toLocaleString('en-US', { weekday: 'short', day: 'numeric' });
            days.push(formattedDate);
        }
        setDaysOfMonth(days)
    }, [yearViewed, monthViewed])


    useEffect(() => {
        if (!startEnd) return
        const startDate = new Date(startEnd[0]);
        const endDate = new Date(startEnd[1]);
        const days = [];

        // Loop through the dates and push them to the array
        while (startDate <= endDate) {
            let date = new Date(startDate);
            days.push(date);
            startDate.setDate(startDate.getDate() + 1);
        }

        setRange(days)
    }, [startEnd])

    const updateHours = async (e) => {

        setLoading(true)
        let payload = {}

        if (bulkUpdate) {
            let newArr = []
            opHours.forEach(e => {
                const startDate = new Date(startEnd[0])
                const endDate = new Date(startEnd[1])
                const dateToCheck = new Date(e)
                if (startDate > dateToCheck || dateToCheck > endDate) {
                    newArr.push(e)
                }
            })
            range.forEach(d => {
                hours.forEach(h => {
                    if (`${d.getDay()}${h}` in bulkDates) {
                        newArr.push(`${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')} ${h}`)
                    }
                })
            })
            setOpHours(Array.from(new Set([...newArr])))
            payload = { timestamps: Array.from(new Set([...newArr])) }
        } else {
            payload = { timestamps: Array.from(new Set([...opHours])) }
        }

        const cancelToken = API.cancelToken();
        const auth = context.getUser.token;

        try {
            await API.putHours(cancelToken, auth, building, payload)
            setGetPutPost('get')
            setBulkUpdate(false)
        } catch (err) {
            console.log(err)
            if (err.message === 'cancelling') return
            if (err.response && err.response.data) {
                try {
                    if (err.response.data.includes('error')) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data.error,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    } else {
                        setError(err.response.data)
                    }
                } catch (e) {
                    Swal.fire({
                        title: 'Error.',
                        text: err.response.data,
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            }
        } finally {
            setLoading(false)
        }
    }

    const handlBulkHours = (e, day, hour) => {
        if (Object.keys(bulkDates).includes(`${day}${hour}`)) {
            setBulkDates(prev => {
                const newState = JSON.parse(JSON.stringify(prev));
                delete newState[`${day}${hour}`]
                return newState;
            })
        } else {
            setBulkDates(prev => ({ ...prev, [`${day}${hour}`]: [day, hour] }))
        }
    }


    const handleDateChange = (data) => {
        if (!data.value) return
        let startDate = data.value[0]
        let endDate = data.value[1]
        setStartEnd([GlobalFuncs.formatDate(startDate, 'YYYY-MM-DD'), GlobalFuncs.formatDate(endDate, 'YYYY-MM-DD')])
    }


    return (
        <div className='tablet:p-8 mobile:p-3 min-h-[80vh]'>
            <div className='flex flex-nowrap items-center gap-4 tablet:mb-8 mobile:mb-3 text-base'>
                <Link to="/settings/ophours">
                    Operating Hours
                </Link>
                {
                    GlobalSVG.rightArrow()
                }
                <p className='font-medium'>
                    {
                        ['get', 'put'].includes(getPutPost) ? buildingData.building : "Add Building"

                    }
                </p>
            </div>
            <div className='card tablet:p-8 mobile:p-3 min-h-[70vh]'>
                <div className="flex flex-nowrap justify-between items-center input-border mb-8 ">
                    <div className='items-center mb-6 w-10/12'>
                        <input
                            placeholder='Building Name'
                            className='text-3xl p-2 outline-none hide-input-background laptop:w-1/2 tablet:w-full mobile:w-full'
                            type="text"
                            name='building'
                            disabled={true}
                            defaultValue={buildingData.building ? buildingData.building : ""}
                            onChange={updateBuilding} />
                        {
                            error && error.building &&
                            <p className='text-red text-xs p-2'>{error.building}</p>

                        }
                    </div>
                    {getPutPost !== 'get' &&
                        <div className="ml-4 flex flex-nowrap items-between justify-between mt-2 mr-4 w-2/12" >
                            <div className='flex justify-end mr-2'>
                                <label
                                    className="inline-block pl-[0.15rem] hover:cursor-pointer"
                                    htmlFor='bulk-update'>
                                    <p className="font-body font-semibold line-height line-height text-sans text-[#332D41] md:decoration-dashed">Bulk Update</p>
                                </label>
                            </div>
                            <div className='flex justify-start ml-2'>
                                <label className="switch" htmlFor='bulk-update'>
                                    <input
                                        type="checkbox"
                                        id='bulk-update'
                                        checked={bulkUpdate}
                                        onClick={() => setBulkUpdate(!bulkUpdate)}
                                    />
                                    <span className={`slider round`}></span>
                                </label>
                            </div>
                        </div>}
                </div>
                {
                    loading &&
                    <Loader />
                }
                {
                    getPutPost !== 'get' &&
                    bulkUpdate &&
                    <div className='w-full m-4 p-2'>
                        <div className='flex flex-nowrap justify-between items-center'>
                            <p className='text-2xl'>Bulk Update</p>
                            <div className={`control-pane mx-2 p-1 font-sans text-[#377745] border-2 border-solid border-neutral-200 rounded-lg text-sm leading-4 font-bold`}>
                                <DateRangePickerComponent
                                    startDate={start}
                                    endDate={end}
                                    min={new Date('2021-01-01')}
                                    max={new Date('2027-01-01')}
                                    onChange={handleDateChange} />
                            </div>
                        </div>
                        <div className='overflow-x-auto w-full flex flex-col flex-nowrap mb-6'>
                            <table className='w-[1161px] overflow-auto mt-8'>
                                <tbody>
                                    <tr>
                                        <td className='w-[60px]'></td>
                                        <td className='w-[40px] font-normal text-xs text-center'>12 AM</td>
                                        <td className='w-[40px] font-normal text-xs text-center'>1 AM</td>
                                        <td className='w-[40px] font-normal text-xs text-center'>2 AM</td>
                                        <td className='w-[40px] font-normal text-xs text-center'>3 AM</td>
                                        <td className='w-[40px] font-normal text-xs text-center'>4 AM</td>
                                        <td className='w-[40px] font-normal text-xs text-center'>5 AM</td>
                                        <td className='w-[40px] font-normal text-xs text-center'>6 AM</td>
                                        <td className='w-[40px] font-normal text-xs text-center'>7 AM</td>
                                        <td className='w-[40px] font-normal text-xs text-center'>8 AM</td>
                                        <td className='w-[40px] font-normal text-xs text-center'>9 AM</td>
                                        <td className='w-[40px] font-normal text-xs text-center'>10 AM</td>
                                        <td className='w-[40px] font-normal text-xs text-center'>11 AM</td>
                                        <td className='w-[40px] font-normal text-xs text-center'>12 PM</td>
                                        <td className='w-[40px] font-normal text-xs text-center'>1 PM</td>
                                        <td className='w-[40px] font-normal text-xs text-center'>2 PM</td>
                                        <td className='w-[40px] font-normal text-xs text-center'>3 PM</td>
                                        <td className='w-[40px] font-normal text-xs text-center'>4 PM</td>
                                        <td className='w-[40px] font-normal text-xs text-center'>5 PM</td>
                                        <td className='w-[40px] font-normal text-xs text-center'>6 PM</td>
                                        <td className='w-[40px] font-normal text-xs text-center'>7 PM</td>
                                        <td className='w-[40px] font-normal text-xs text-center'>8 PM</td>
                                        <td className='w-[40px] font-normal text-xs text-center'>9 PM</td>
                                        <td className='w-[40px] font-normal text-xs text-center'>10 PM</td>
                                        <td className='w-[40px] font-normal text-xs text-center'>11 PM</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className='w-[1161px] overflow-auto'>
                                <table className=''>
                                    <tbody>

                                        {
                                            Object.keys(dayOfWeekDict).map((d, idx) => (
                                                <tr>
                                                    <td className='w-[69px] font-grey text-sm py-2'>{dayOfWeekDict[d]}</td>
                                                    {hours.map((h, index) => (
                                                        <td key={index + "hourstable"} className='w-[45px] text-center'>
                                                            <input className='cursor-pointer' type="checkbox" checked={Object.keys(bulkDates).includes(`${d}${h}`)} onChange={(e) => handlBulkHours(e, d, `${h}`)} />
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }

                {/* Date selector */}
                {
                    !bulkUpdate &&
                    <div className='flex flex-nowrap justify-center items-center'>
                        {/* <p className='text-grey text-lg font-medium mr-3'>Year</p> */}
                        <div className='flex flex-nowrap justify-center items-center w-8 h-8 rounded border mr-2 cursor-pointer' onClick={() => handleYearChange("-")}>
                            {GlobalSVG.leftArrow()}
                        </div>
                        <p className='text-lg w-10 text-center'>
                            {yearViewed}
                        </p>
                        <div className='flex flex-nowrap justify-center items-center w-8 h-8 rounded border ml-2 cursor-pointer' onClick={() => handleYearChange("+")}>
                            {GlobalSVG.rightArrow()}
                        </div>
                        <div className='w-10'></div>
                        {/* <p className='text-grey text-lg font-medium ml-8 mr-3'>Month</p> */}
                        <div className='flex flex-nowrap justify-center items-center w-8 h-8 rounded border mr-2 cursor-pointer' onClick={() => handleMonthChange("-")}>
                            {GlobalSVG.leftArrow()}
                        </div>
                        <p className='text-lg w-10 text-center'>
                            {monthDict[monthViewed]}
                        </p>
                        <div className='flex flex-nowrap justify-center items-center w-8 h-8 rounded border ml-2 cursor-pointer' onClick={() => handleMonthChange("+")}>
                            {GlobalSVG.rightArrow()}
                        </div>
                    </div>}
                {
                    !bulkUpdate &&
                    <div className='overflow-x-auto w-full flex flex-col flex-nowrap mb-6'>
                        <table className='w-[1161px] overflow-auto mt-8'>
                            <tbody>
                                <tr>
                                    <td className='w-[80px]'></td>
                                    <td className='w-[40px] font-normal text-xs text-center'>12 AM</td>
                                    <td className='w-[40px] font-normal text-xs text-center'>1 AM</td>
                                    <td className='w-[40px] font-normal text-xs text-center'>2 AM</td>
                                    <td className='w-[40px] font-normal text-xs text-center'>3 AM</td>
                                    <td className='w-[40px] font-normal text-xs text-center'>4 AM</td>
                                    <td className='w-[40px] font-normal text-xs text-center'>5 AM</td>
                                    <td className='w-[40px] font-normal text-xs text-center'>6 AM</td>
                                    <td className='w-[40px] font-normal text-xs text-center'>7 AM</td>
                                    <td className='w-[40px] font-normal text-xs text-center'>8 AM</td>
                                    <td className='w-[40px] font-normal text-xs text-center'>9 AM</td>
                                    <td className='w-[40px] font-normal text-xs text-center'>10 AM</td>
                                    <td className='w-[40px] font-normal text-xs text-center'>11 AM</td>
                                    <td className='w-[40px] font-normal text-xs text-center'>12 PM</td>
                                    <td className='w-[40px] font-normal text-xs text-center'>1 PM</td>
                                    <td className='w-[40px] font-normal text-xs text-center'>2 PM</td>
                                    <td className='w-[40px] font-normal text-xs text-center'>3 PM</td>
                                    <td className='w-[40px] font-normal text-xs text-center'>4 PM</td>
                                    <td className='w-[40px] font-normal text-xs text-center'>5 PM</td>
                                    <td className='w-[40px] font-normal text-xs text-center'>6 PM</td>
                                    <td className='w-[40px] font-normal text-xs text-center'>7 PM</td>
                                    <td className='w-[40px] font-normal text-xs text-center'>8 PM</td>
                                    <td className='w-[40px] font-normal text-xs text-center'>9 PM</td>
                                    <td className='w-[40px] font-normal text-xs text-center'>10 PM</td>
                                    <td className='w-[40px] font-normal text-xs text-center'>11 PM</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className='h-[250px] w-[1161px] overflow-auto'>
                            <table className=''>
                                <tbody>
                                    {
                                        daysOfMonth && daysOfMonth.map((e, idx) => (
                                            <tr key={idx + "daysofmont"}>
                                                <td className='w-[89px] font-grey text-sm py-2'>{e}</td>
                                                {hours.map((h, index) => (
                                                    <td key={index + "hourstable"} className='w-[45px] text-center'>
                                                        <input className='cursor-pointer' type="checkbox" checked={opHours && opHours.includes(`${yearViewed}-${String(monthViewed).padStart(2, '0')}-${e.split(" ")[0].padStart(2, '0')} ${h}`)} onChange={() => handleHour(`${yearViewed}-${String(monthViewed).padStart(2, '0')}-${e.split(" ")[0].padStart(2, '0')} ${h}`)} />
                                                    </td>
                                                ))}
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
                {
                    ['get'].includes(getPutPost) &&
                    <div className='w-full flex flex-nowrap justify-end text-green4 p-2.5'>
                        <p className='cursor-pointer' onClick={() => setGetPutPost('put')}>
                            Edit Building
                        </p>
                    </div>
                }
                <div className="flex flex-nowrap items-center justify-end w-full">
                    {
                        ['put'].includes(getPutPost) && !loading &&
                        <div className='mr-8 text-green3 cursor-pointer' onClick={() => setGetPutPost('get')}>
                            Cancel
                        </div>
                    }
                    {
                        getPutPost !== 'get' && buildingData.building !== "" && !loading && <button className='submit-button' onClick={updateHours}>
                            Save
                        </button>
                    }
                </div>
            </div>

        </div>
    );
};

export default OpHours;