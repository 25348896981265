import { useContext, useEffect, useState } from "react";
import API from "../../utils/API";
import { AppContext } from '../../utils/ContextProvider';
import GlobalSVG from '../../utils/GlobalSVG';
import Swal from 'sweetalert2';
import LoaderWheel from "../LoaderWheel";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { Link } from "react-router-dom";
import './style.css'

const MaintenanceGroups = (props) => {

    const context = useContext(AppContext);
    const statusColor = { 'Upcoming': '#107048', 'Due': '#F9941E', 'Set': '#B8BBB9', 'Overdue': '#F75D5F' }
    const [deleting, setDeleting] = useState([])
    const [filters, setFilters] = useState({})

    useEffect(() => {
        let tempEquipment = []
        let tempPerformed = []
        let tempThreshold = []
        let tempTolerance = []
        let tempDueIn = []
        let tempPercent = []
        let tempName = []

        props.groups.forEach(group => {
            if (!group.activities) return
            let tempChan = group.activities.map(activity => activity.channel_name)
            tempEquipment = new Set([...tempEquipment, ...tempChan])
        })

        let tempNm = props.groups.map(group => group.name)
        tempName = new Set([...tempNm])

        let tempPerf = props.groups.map(group => group.performedBy)
        tempPerformed = new Set([...tempPerf])

        props.groups.forEach(group => {
            let tempThresh = group.activities.map(group => parseFloat(group.threshold))
            tempThreshold = new Set([...tempThreshold, ...tempThresh])
        })

        props.groups.forEach(group => {
            let tempTol = group.activities.map(group => parseFloat(group.tolerance))
            tempTolerance = new Set([...tempTolerance, ...tempTol])
        })

        props.groups.forEach(group => {
            let tempDue = group.activities.map(group => parseFloat(group.dueIn))
            tempDueIn = new Set([...tempDueIn, ...tempDue])
        })

        props.groups.forEach(group => {
            let tempPerc = group.activities.map(group => parseFloat(group.percent))
            tempPercent = new Set([...tempPercent, ...tempPerc])
        })

        setFilters({
            name: Array.from(tempName).sort(),
            equipment: Array.from(tempEquipment).sort(),
            performedBy: Array.from(tempPerformed).sort(),
            threshold: Array.from(tempThreshold).sort(),
            tolerance: Array.from(tempTolerance).sort(),
            dueIn: Array.from(tempDueIn).sort(),
            percent: Array.from(tempPercent).sort(),
        })

    }, [props.groups])

    const handleSort = (field) => {
        props.setSorting(e => e.hasOwnProperty(field) ? { [field]: !e[field] } : { [field]: true })
    }

    const handleDelete = (id) => {
        const auth = context.getUser.token;
        setDeleting(e => [...e, id])
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                API.updateMaintenanceGroup(auth, { deleted: true }, id).then(async res => {
                    props.setGroups(e => e.filter(item => item.id !== id));
                }).catch(e => {
                    console.log(e)
                    Swal.fire({
                        title: 'Oops!',
                        text: 'An error occurred, please try again later.',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                })
            } else {
                setDeleting(e => e.filter(item => item !== id))
            }
        });
    }

    return (
        <div className="min-h-[200px]">
            <div className="flex flex-nowrap">
                {
                    props.filters.hasOwnProperty('lastActionDate') &&
                    <div className="flex flex-nowrap justify-center items-center rounded-md border border-[#46775A] mr-4 pl-2">
                        <div className='text-[12px] min-w-[70px] font-semibold'>Last Action:</div>
                        <DatePickerComponent
                            className='p-2'
                            onChange={(event) => props.setFilters(e => ({ ...e, lastActionDate: event.value }))} />
                        <div className='w-[5px]'></div>
                        <div className="flex flex-nowrap justify-center items-center h-full rounded-r-sm w-[30px] h-full bg-[#46775A] cursor-pointer"
                            onClick={() => props.setFilters(({ lastActionDate: _, ...rest }) => rest)}>
                            {GlobalSVG.close('white')}
                        </div>
                    </div>
                }
                {
                    props.filters.hasOwnProperty('currentStatus') &&
                    <div className="flex flex-nowrap justify-center items-center rounded-md border border-[#46775A] mr-4 pl-2">
                        <select className='border-none text-[12px] font-semibold h-[33px]' name="currentStatus" onChange={(event) => props.setFilters(e => ({ ...e, currentStatus: event.target.value }))}>
                            <option value={null}>Status</option>
                            <option value="Set">Set</option>
                            <option value="Due">Due</option>
                            <option value="Upcoming">Upcoming</option>
                            <option value="Overdue">Overdue</option>
                        </select>
                        <div className='w-[5px]'></div>
                        <div className="flex flex-nowrap justify-center items-center h-full rounded-r-sm w-[30px] h-full bg-[#46775A] cursor-pointer"
                            onClick={() => props.setFilters(({ currentStatus: _, ...rest }) => rest)}>
                            {GlobalSVG.close('white')}
                        </div>
                    </div>
                }
                {
                    props.filters.hasOwnProperty('type') &&
                    <div className="flex flex-nowrap justify-center items-center rounded-md border border-[#46775A] mr-4 pl-2">
                        <select className='border-none text-[12px] font-semibold h-[33px]' name="type" onChange={(event) => props.setFilters(e => ({ ...e, type: event.target.value }))}>
                            <option value={null}>Type</option>
                            <option value="Filter Change">Filter Change</option>
                            <option value="Cleaning">Cleaning</option>
                            <option value="Repair">Repair</option>
                            <option value="Upgrade">Upgrade</option>
                            <option value="Replacement">Replacement</option>
                            <option value="Inspection">Inspection</option>                            
                        </select>
                        <div className='w-[5px]'></div>
                        <div className="flex flex-nowrap justify-center items-center h-full rounded-r-sm w-[30px] h-full bg-[#46775A] cursor-pointer"
                            onClick={() => props.setFilters(({ type: _, ...rest }) => rest)}>
                            {GlobalSVG.close('white')}
                        </div>
                    </div>
                }
                {
                    props.filters.hasOwnProperty('equipment') &&
                    <div className="flex flex-nowrap justify-center items-center rounded-md border border-[#46775A] mr-4 pl-2">
                        <select className='border-none text-[12px] font-semibold h-[33px]' name="equipment" onChange={(event) => props.setFilters(e => ({ ...e, equipment: event.target.value }))}>
                            <option value={null}>Equipment</option>
                            {filters && filters.equipment.map((e, ix) => (
                                <option key={e + ix} value={e}>{e}</option>
                            ))}
                        </select>
                        <div className='w-[5px]'></div>
                        <div className="flex flex-nowrap justify-center items-center h-full rounded-r-sm w-[30px] h-full bg-[#46775A] cursor-pointer"
                            onClick={() => props.setFilters(({ equipment: _, ...rest }) => rest)}>
                            {GlobalSVG.close('white')}
                        </div>
                    </div>
                }
                {
                    props.filters.hasOwnProperty('name') &&
                    <div className="flex flex-nowrap justify-center items-center rounded-md border border-[#46775A] mr-4 pl-2">
                        <select className='border-none text-[12px] font-semibold h-[33px]' name="name" onChange={(event) => props.setFilters(e => ({ ...e, name: event.target.value }))}>
                            <option value={null}>Name</option>
                            {filters && filters.name.map((e, ix) => (
                                <option key={e + ix} value={e}>{e}</option>
                            ))}
                        </select>
                        <div className='w-[5px]'></div>
                        <div className="flex flex-nowrap justify-center items-center h-full rounded-r-sm w-[30px] h-full bg-[#46775A] cursor-pointer"
                            onClick={() => props.setFilters(({ name: _, ...rest }) => rest)}>
                            {GlobalSVG.close('white')}
                        </div>
                    </div>
                }
                {
                    props.filters.hasOwnProperty('performedBy') &&
                    <div className="flex flex-nowrap justify-center items-center rounded-md border border-[#46775A] mr-4 pl-2">
                        <select className='border-none text-[12px] font-semibold h-[33px]' name="performedBy" onChange={(event) => props.setFilters(e => ({ ...e, performedBy: event.target.value }))}>
                            <option value={null}>Performed By</option>
                            {filters.performedBy.map((e, ix) => (
                                <option key={e + ix} value={e}>{e}</option>
                            ))}
                        </select>
                        <div className='w-[5px]'></div>
                        <div className="flex flex-nowrap justify-center items-center h-full rounded-r-sm w-[30px] h-full bg-[#46775A] cursor-pointer"
                            onClick={() => props.setFilters(({ performedBy: _, ...rest }) => rest)}>
                            {GlobalSVG.close('white')}
                        </div>
                    </div>
                }
                {
                    props.filters.hasOwnProperty('threshold') &&
                    <div className="flex flex-nowrap justify-center items-center rounded-md border border-[#46775A] mr-4 pl-2">
                        <select className='border-none text-[12px] font-semibold h-[33px]' name="threshold" onChange={(event) => props.setFilters(e => ({ ...e, threshold: event.target.value }))}>
                            <option value={null}>Target Maint. Interval</option>
                            {filters.threshold.map((e, ix) => (
                                <option key={e + ix} value={e}>{e}</option>
                            ))}
                        </select>
                        <div className='w-[5px]'></div>
                        <div className="flex flex-nowrap justify-center items-center h-full rounded-r-sm w-[30px] h-full bg-[#46775A] cursor-pointer"
                            onClick={() => props.setFilters(({ threshold: _, ...rest }) => rest)}>
                            {GlobalSVG.close('white')}
                        </div>
                    </div>
                }
                {
                    props.filters.hasOwnProperty('tolerance') &&
                    <div className="flex flex-nowrap justify-center items-center rounded-md border border-[#46775A] mr-4 pl-2">
                        <select className='border-none text-[12px] font-semibold h-[33px]' name="tolerance" onChange={(event) => props.setFilters(e => ({ ...e, tolerance: event.target.value }))}>
                            <option value={null}>Tolerance</option>
                            {filters.tolerance.map((e, ix) => (
                                <option key={e + ix} value={e}>{e}</option>
                            ))}
                        </select>
                        <div className='w-[5px]'></div>
                        <div className="flex flex-nowrap justify-center items-center h-full rounded-r-sm w-[30px] h-full bg-[#46775A] cursor-pointer"
                            onClick={() => props.setFilters(({ tolerance: _, ...rest }) => rest)}>
                            {GlobalSVG.close('white')}
                        </div>
                    </div>
                }
                {
                    props.filters.hasOwnProperty('dueIn') &&
                    <div className="flex flex-nowrap justify-center items-center rounded-md border border-[#46775A] mr-4 pl-2">
                        <select className='border-none text-[12px] font-semibold h-[33px]' name="dueIn" onChange={(event) => props.setFilters(e => ({ ...e, dueIn: event.target.value }))}>
                            <option value={null}>Due In</option>
                            {filters.dueIn.map((e, ix) => (
                                <option key={e + ix} value={e}>{parseInt(e)}</option>
                            ))}
                        </select>
                        <div className='w-[5px]'></div>
                        <div className="flex flex-nowrap justify-center items-center h-full rounded-r-sm w-[30px] h-full bg-[#46775A] cursor-pointer"
                            onClick={() => props.setFilters(({ dueIn: _, ...rest }) => rest)}>
                            {GlobalSVG.close('white')}
                        </div>
                    </div>
                }
                {
                    props.filters.hasOwnProperty('percent') &&
                    <div className="flex flex-nowrap justify-center items-center rounded-md border border-[#46775A] mr-4 pl-2">
                        <select className='border-none text-[12px] font-semibold h-[33px]' name="percent" onChange={(event) => props.setFilters(e => ({ ...e, percent: event.target.value }))}>
                            <option value={null}>Threshold Used</option>
                            {filters.percent.map((e, ix) => (
                                <option key={e + ix} value={e}>{parseInt(e * 100)}%</option>
                            ))}
                        </select>
                        <div className='w-[5px]'></div>
                        <div className="flex flex-nowrap justify-center items-center h-full rounded-r-sm w-[30px] h-full bg-[#46775A] cursor-pointer"
                            onClick={() => props.setFilters(({ percent: _, ...rest }) => rest)}>
                            {GlobalSVG.close('white')}
                        </div>
                    </div>
                }
            </div>
            <div className='overflow-auto'>
                <div className='flex flex-nowrap justify-between p-4 my-2 w-full min-w-[1156px]'>
                    <div className='flex flex-nowrap justify-start items-center w-[130px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('lastActionDate')}>
                        <span className={`mr-2 ${props.sorting.hasOwnProperty("lastActionDate") ? 'text-[green]' : ''}`}>Last Action Date</span>
                        {props.sorting.hasOwnProperty("lastActionDate") && props.sorting.lastActionDate === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            props.sorting.hasOwnProperty("lastActionDate") && props.sorting.lastActionDate === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div>
                    <div className='flex flex-nowrap justify-start items-center w-[110px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('type')}>
                        <span className={`mr-2 ${props.sorting.hasOwnProperty("type") ? 'text-[green]' : ''}`}>Maint. Type</span>
                        {props.sorting.hasOwnProperty("type") && props.sorting.type === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            props.sorting.hasOwnProperty("type") && props.sorting.type === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div>
                    <div className='flex flex-nowrap justify-start w-[150px] text-[12px] text-[#B8BBB9] font-semibold'>
                        Equipment
                    </div>
                    <div className='flex flex-nowrap justify-start items-center w-[130px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('name')}>
                        <span className={`mr-2 ${props.sorting.hasOwnProperty("name") ? 'text-[green]' : ''}`}>Group Name</span>
                        {props.sorting.hasOwnProperty("name") && props.sorting.name === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            props.sorting.hasOwnProperty("name") && props.sorting.name === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div>
                    {/* <div className='flex flex-nowrap justify-start items-center w-1/12 text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('performedBy')}>
                        <span className={`mr-2 ${props.sorting.hasOwnProperty("performedBy") ? 'text-[green]' : ''}`}>Performed By</span>
                        {props.sorting.hasOwnProperty("performedBy") && props.sorting.performedBy === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            props.sorting.hasOwnProperty("performedBy") && props.sorting.performedBy === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div> */}
                    <div className='flex flex-nowrap justify-start items-center w-[140px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('threshold')}>
                        <span className={`mr-2 ${props.sorting.hasOwnProperty("threshold") ? 'text-[green]' : ''}`}>Target Maint. Interval</span>
                        {props.sorting.hasOwnProperty("threshold") && props.sorting.threshold === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            props.sorting.hasOwnProperty("threshold") && props.sorting.threshold === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div>
                    {/* <div className='flex flex-nowrap justify-start items-center w-1/12 text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('tolerance')}>
                        <span className={`mr-2 ${props.sorting.hasOwnProperty("tolerance") ? 'text-[green]' : ''}`}>Tolerance</span>
                        {props.sorting.hasOwnProperty("tolerance") && props.sorting.tolerance === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            props.sorting.hasOwnProperty("tolerance") && props.sorting.tolerance === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div> */}
                    <div className='flex flex-nowrap justify-start items-center w-[100px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('dueIn')}>
                        <span className={`mr-2 ${props.sorting.hasOwnProperty("dueIn") ? 'text-[green]' : ''}`}>Due In</span>
                        {props.sorting.hasOwnProperty("dueIn") && props.sorting.dueIn === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            props.sorting.hasOwnProperty("dueIn") && props.sorting.dueIn === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div>
                    <div className='flex flex-nowrap justify-start items-center w-[110px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer'
                        onClick={() => handleSort('percent')}>
                        <span className={`mr-2 ${props.sorting.hasOwnProperty("percent") ? 'text-[green]' : ''}`}>Threshold Used</span>
                        {props.sorting.hasOwnProperty("percent") && props.sorting.percent === true ?
                            GlobalSVG.sortArrowAsc('green') :
                            props.sorting.hasOwnProperty("percent") && props.sorting.percent === false ?
                                GlobalSVG.sortArrowDesc('green') :
                                GlobalSVG.sortArrowAsc('#B8BBB9')}
                    </div>
                    <div className='flex flex-nowrap justify-start w-[25px]'></div>
                    <div className='flex flex-nowrap justify-start w-[25px]'></div>
                </div>
                {
                    props.filteredGroups.map((e, ix) => (
                        <div key={'activitykey' + ix} style={{ color: statusColor[e.currentStatus] }} className='flex flex-nowrap justify-between items-center p-4 my-2 w-full min-w-[1156px] bg-[white] rounded-md '>
                            <div className={`w-[130px] text-[12px] font-medium`}>{e.lastActionDate ? e.lastActionDate : " - "}</div>
                            <div className={`w-[110px] text-[12px] font-medium`}>{e.type ? e.type : " - "}</div>
                            <div className={`w-[150px] text-[12px] font-medium overflow-hidden overflow-ellipsis whitespace-nowrap`}>{e.equipment ? (e.equipment).join(', ') : " - "}</div>
                            <div className={`w-[130px] text-[12px] font-medium`}>{e.name ? e.name : " - "}</div>
                            {/* <div className={`w-1/12 text-[12px] font-medium ${e.performedBy ? "" : "pl-[25px]"}`}>{e.performedBy ? e.performedBy : " - "}</div> */}
                            <div className={`w-[140px] text-[12px] font-medium ${e.threshold ? "" : "pl-[25px]"}`}>{e.threshold ? e.threshold.toLocaleString() : " - "} {e.tolerance ? " +/-" + e.tolerance.toLocaleString() + (e.timebase === 'runtime' ? "h" : " days") : " - "}</div>
                            {/* <div className={`w-1/12 text-[12px] font-medium ${e.tolerance ? "" : "pl-[25px]"}`}>{e.tolerance ? e.tolerance.toLocaleString() : " - "}</div> */}
                            <div className={`w-[100px] text-[12px] font-medium ${e.dueIn ? "" : "pl-[25px]"}`}>{e.dueIn ? parseInt(e.dueIn).toLocaleString() + (e.timebase === 'runtime' ? "h" : " days") : " - "}</div>
                            <div className={`w-[110px] text-[12px] font-medium ${e.percent ? "" : "pl-[25px]"}`}>{e.percent ? parseInt(e.percent * 100).toLocaleString() + "%" : " - "}</div>
                            {!props.portfolio && <Link className='cursor-pointer w-[25px]' to={`/maintenance?building=${context.getBuildingInfo.building_id}&group=${e.id}`}>{GlobalSVG.edit2()}</Link>}
                            <div className='cursor-pointer w-[25px]'>
                                {context.getUserInfo.update_buildings_and_operators &&
                                    (deleting.includes(e.id) ?
                                        <LoaderWheel /> :
                                        <div className='cursor-pointer w-[15px] h-[18px]' onClick={() => handleDelete(e.id)}>{GlobalSVG.delete()}</div>)
                                }
                            </div>

                        </div>
                    ))
                }
            </div>
        </div>
    )
};

export default MaintenanceGroups;