import { useState, useEffect } from "react";

const MultipleSelect = (props) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (show && !event.target.closest('.multi-select')) {
        setShow(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [show]);



  return (
    <>
      {/* Search Bar */}
      <div className={`flex flex-nowrap ${props.reports ? 'justify-start' : 'justify-center'}  items-center tablet:items-end gap-2.5 rounded-md bg-[#FFF] multi-select`}>
        <div className={`relative w-61 h-10 flex ${props.reports ? 'justify-start' : 'laptop:justify-center laptop:items-start tablet:items-end'} flex-col`}>

          {props.data && props.data.length > 0 && (
            <div className="relative flex items-center h-[36px] w-[220px] justify-start border border-1 rounded transparent  border-[#C1C1C1] text-sm text-normal text-[grey] cursor-pointer">
              <div onClick={() => setShow(!show)} className="w-full mx-3 flex flex-nowrap items-center justify-center">
                <div className={`w-full text-base text-[grey]`}>{props.placeholder}</div>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={`relative inline-block w-3.5 h-3.5 transform transition duration-150 ${show ? "rotate-180" : ""}`}>
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                </svg>
              </div>
              {show && <div className={`absolute top-[100%] w-full h-[250px] overflow-auto flex flex-col p-1 whitespace-nowrap border border-[#C1C1C1] z-10 rounded bg-[#FFF]`}>
                <div className="flex flex-row " key="all" onClick={() => props.selectItem({ target: { name: props.dataType, value: "all" } })} >
                  <button className="inline-block p-1 cursor-pointer" >
                    <p className="text-ellipsis overflow-hidden">
                      {props.deselectAll ? "Select All" : "Reset selections"}
                    </p>
                  </button>
                </div>
                {props.deselectAll &&
                  <div className="flex flex-row " onClick={() => props.selectItem({ target: { name: props.dataType, value: "none" } })} >
                    <button className="inline-block p-1 cursor-pointer" >
                      <p className="text-ellipsis overflow-hidden">
                        Deselect All
                      </p>
                    </button>
                  </div>}
                {props.data.map((e, i) => {
                  return (
                    <div className="flex flex-row p-1" key={i} onClick={() => props.selectItem({ target: { name: props.dataType, value: e === null ? null : (typeof e === "string" ? e : e[props.idAttr])} })} >
                      <input type="checkbox" key={i} checked={props.selectedList.includes(e === null ? null : (typeof e === "string" ? e : e[props.idAttr]))} className="inline-block mr-2 cursor-pointer" />
                      <p className="text-ellipsis overflow-hidden">{e === null ? null : (typeof e === "string" ? e : e[props.valAttr])}</p>
                    </div>
                  );
                })}
              </div>}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default MultipleSelect;
