import SlideToggle from '../SlideToggle';
import './style.css'
import { useState, useEffect, useRef } from 'react';
import Loader from "../Loader";
import GlobalSVG from '../../utils/GlobalSVG';
import { HighchartsReact } from 'highcharts-react-official';
import Highcharts from "highcharts/highstock";
import MultipleSelect from "../../components/MultipleSelect";
import GlobalFuncs from '../../utils/GlobalFuncs';
import highchartsExporting from 'highcharts/modules/exporting';
import highchartsExportData from 'highcharts/modules/export-data';
import moment from 'moment';
import GraphFuncs from '../../utils/GraphFuncs';

export default function ReportGraph1(props) {

    const [visible, setVisible] = useState({
        co: false, powerfactor: false
    })

    const [options, setOptions] = useState()
    const [currentData, setCurrentData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [open, setOpen] = useState(true)
    const [totalCo, setTotalCo] = useState(0)
    const chartRef = useRef(null);
    const unitDict = { kwh: "kWh", kva: "kVA", price: "$" }

    require('highcharts/modules/exporting')(Highcharts);
    highchartsExporting(Highcharts);
    highchartsExportData(Highcharts);

    useEffect(() => {
        if (props.circuits === null || props.view === 'generation') return
        setLoading(true)
        let priceDict = { kwh_consumption_dollar: 0, kva_consumption_dollar: 0, carbon_charge_dollar: 0, municipal_tax_dollar: 0, pst_dollar: 0, gst_dollar: 0, total: 0 }
        for (const key in props.circuits) {
            let item = props.circuits[key]
            priceDict.kwh_consumption_dollar += item.price.on.kwh_consumption_dollar + item.price.off.kwh_consumption_dollar
            priceDict.kva_consumption_dollar += item.price.on.kva_consumption_dollar + item.price.off.kva_consumption_dollar
            priceDict.carbon_charge_dollar += item.price.on.carbon_charge_dollar + item.price.off.carbon_charge_dollar
            priceDict.municipal_tax_dollar += item.price.on.municipal_tax_dollar + item.price.off.municipal_tax_dollar
            priceDict.pst_dollar += item.price.on.pst_dollar + item.price.off.pst_dollar
            priceDict.gst_dollar += item.price.on.gst_dollar + item.price.off.gst_dollar
            priceDict.total += item.price.on.total + item.price.off.total
        }

        let tempOnSeries = []
        let tempOffSeries = []

        let sortedList = Object.keys(props.circuits)
        sortedList.sort(function (a, b) {
            var itemA = props.circuits[a];
            var itemB = props.circuits[b];

            switch (props.unit) {
                case "price": default:
                    itemA = itemA.price.on.total + itemA.price.off.total
                    itemB = itemB.price.on.total + itemB.price.off.total
                    break
                case "kva":
                    itemA = itemA.realPeakOn ? itemA.kva.on : itemA.kva.off
                    itemB = itemB.realPeakOn ? itemB.kva.on : itemB.kva.off
                    break
                case "kwh":
                    itemA = itemA.kwh.on + itemA.kwh.off
                    itemB = itemB.kwh.on + itemB.kwh.off
                    break
            }

            if (itemA > itemB) {
                return -1;
            }
            if (itemA < itemB) {
                return 1;
            }
            return 0;
        });

        let catDict = {}
        let subCatDict = {}
        let catCoDict = {}
        let subCatCoDict = {}

        let tempTotalCo = 0
        for (let key of sortedList) {
            let item = props.circuits[key]
            if (item.subcategory === null) item.subcategory = 'Other'
            if (item.category.toLowerCase() === 'capacitor bank') continue
            catCoDict[item.category] = catCoDict[item.category] || 0
            catCoDict[item.subcategory] = catCoDict[item.subcategory] || 0
            switch (props.unit) {
                case "price": default:
                    if (props.view === 'circuit') {
                        tempOnSeries.push({ name: key, y: parseFloat(item.price.on.total.toFixed(2)), onNumber: parseFloat(item.price.on.total.toFixed(2)), offNumber: parseFloat(item.price.off.total.toFixed(2)), co: parseFloat(item.co.toFixed(1)), pf: parseFloat(item.pf.toFixed(2)) })
                        tempOffSeries.push({ name: key, y: parseFloat(item.price.off.total.toFixed(2)), onNumber: parseFloat(item.price.on.total.toFixed(2)), offNumber: parseFloat(item.price.off.total.toFixed(2)), co: parseFloat(item.co.toFixed(1)), pf: parseFloat(item.pf.toFixed(2)) })
                    } else if (props.view === 'category') {
                        catDict[item.category] = catDict[item.category] || { on: 0, off: 0 }
                        catDict[item.category].on += item.price.on.total
                        catDict[item.category].off += item.price.off.total
                    } else if (props.view === 'subcategory') {
                        subCatDict[item.subcategory] = subCatDict[item.subcategory] || { on: 0, off: 0 }
                        subCatDict[item.subcategory].on += item.price.on.total
                        subCatDict[item.subcategory].off += item.price.off.total
                    }

                    break
                case "kva":
                    if (props.view === 'circuit') {
                        tempOnSeries.push({ name: key, y: parseFloat(item.kva.on.toFixed(2)), onNumber: parseFloat(item.kva.on.toFixed(2)), offNumber: parseFloat(item.kva.off.toFixed(2)), co: parseFloat(item.co.toFixed(1)), pf: parseFloat(item.pf.toFixed(2)) })
                        tempOffSeries.push({ name: key, y: parseFloat(item.kva.off.toFixed(2)), onNumber: parseFloat(item.kva.on.toFixed(2)), offNumber: parseFloat(item.kva.off.toFixed(2)), co: parseFloat(item.co.toFixed(1)), pf: parseFloat(item.pf.toFixed(2)) })
                    } else if (props.view === 'category') {
                        catDict[item.category] = catDict[item.category] || { on: 0, off: 0 }
                        catDict[item.category].on += item.kva.on
                        catDict[item.category].off += item.kva.on
                    } else if (props.view === 'subcategory') {
                        subCatDict[item.subcategory] = subCatDict[item.subcategory] || { on: 0, off: 0 }
                        subCatDict[item.subcategory].on += item.kva.on
                        subCatDict[item.subcategory].off += item.kva.on
                    }
                    break
                case "kwh":
                    tempTotalCo += item.co
                    if (props.view === 'circuit') {
                        tempOnSeries.push({ name: key, y: parseFloat(item.kwh.on.toFixed(2)), onNumber: parseFloat(item.kwh.on.toFixed(2)), offNumber: parseFloat(item.kwh.off.toFixed(2)), co: parseFloat(item.co.toFixed(1)), pf: parseFloat(item.pf.toFixed(2)) })
                        tempOffSeries.push({ name: key, y: parseFloat(item.kwh.off.toFixed(2)), onNumber: parseFloat(item.kwh.on.toFixed(2)), offNumber: parseFloat(item.kwh.off.toFixed(2)), co: parseFloat(item.co.toFixed(1)), pf: parseFloat(item.pf.toFixed(2)) })
                    } else if (props.view === 'category') {
                        catDict[item.category] = catDict[item.category] || { on: 0, off: 0 }
                        catDict[item.category].on += item.kwh.on
                        catDict[item.category].off += item.kwh.off
                    } else if (props.view === 'subcategory') {
                        subCatDict[item.subcategory] = subCatDict[item.subcategory] || { on: 0, off: 0 }
                        subCatDict[item.subcategory].on += item.kwh.on
                        subCatDict[item.subcategory].off += item.kwh.off
                    }
                    break
            }
            setTotalCo(tempTotalCo)
            catCoDict[item.category] += item.co
            subCatCoDict[item.subcategory] += item.co
        }

        if (props.view === 'category') {
            let sortedCatList = Object.keys(catDict)
            sortedCatList.sort(function (a, b) {
                var itemA = catDict[a].on + catDict[a].off
                var itemB = catDict[b].on + catDict[b].off

                if (itemA > itemB) {
                    return -1;
                }
                if (itemA < itemB) {
                    return 1;
                }
                return 0;
            });
            for (let cat of sortedCatList) {
                if (cat.toLowerCase() === 'capacitor bank') continue
                tempOnSeries.push({ name: cat, y: parseFloat(catDict[cat].on.toFixed(2)), onNumber: parseFloat(catDict[cat].on.toFixed(2)), offNumber: parseFloat(catDict[cat].off.toFixed(2)), co: parseFloat(catCoDict[cat].toFixed(1)) })
                tempOffSeries.push({ name: cat, y: parseFloat(catDict[cat].off.toFixed(2)), onNumber: parseFloat(catDict[cat].on.toFixed(2)), offNumber: parseFloat(catDict[cat].off.toFixed(2)), co: parseFloat(catCoDict[cat].toFixed(1)) })
            }
        } else if (props.view === 'subcategory') {
            let sortedCatList = Object.keys(subCatDict)
            sortedCatList.sort(function (a, b) {
                var itemA = subCatDict[a].on + subCatDict[a].off
                var itemB = subCatDict[b].on + subCatDict[b].off

                if (itemA > itemB) {
                    return -1;
                }
                if (itemA < itemB) {
                    return 1;
                }
                return 0;
            });
            for (let cat of sortedCatList) {
                if (cat.toLowerCase() === 'capacitor bank') continue
                tempOnSeries.push({ name: cat, y: parseFloat(subCatDict[cat].on.toFixed(2)), onNumber: parseFloat(subCatDict[cat].on.toFixed(2)), offNumber: parseFloat(subCatDict[cat].off.toFixed(2)), co: parseFloat(catCoDict[cat].toFixed(1)) })
                tempOffSeries.push({ name: cat, y: parseFloat(subCatDict[cat].off.toFixed(2)), onNumber: parseFloat(subCatDict[cat].on.toFixed(2)), offNumber: parseFloat(subCatDict[cat].off.toFixed(2)), co: parseFloat(catCoDict[cat].toFixed(1)) })
            }
        }

        let onSeries = {
            name: "Occupied Hours",
            data: tempOnSeries,
            color: "#619E7B",
            dataLabels: {
                enabled: true,
                format: visible.powerfactor && props.unit === 'kva' ? '<b>{point.options.pf}</b>' : '',
                verticalAlign: 'top',
                y: -30,
                style: {
                    fontSize: '14px',
                    color: 'black'
                }
            }
        }
        let offSeries = {
            name: "Unoccupied Hours",
            data: tempOffSeries,
            color: "#D8D8D8",
            dataLabels: {
                enabled: true,
                format: visible.co && props.unit === 'kwh' ? '<b>{point.options.co} t</b>' : (visible.powerfactor && props.unit === 'kva' ? '<b>{point.options.pf}</b>' : ''),
                verticalAlign: 'top',
                y: -30,
                style: {
                    fontSize: '14px',
                    color: 'black'
                },
                allowOverlap: true,
                crop: false,
                overflow: 'allow'
            }
        }
        setCurrentData([onSeries, offSeries, sortedList])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.circuits, props.unit, props.view, visible])


    useEffect(() => {
        if (props.view !== 'generation') return
        props.setUnit({ currentTarget: { value: 'kwh' } })
        setLoading(true)
        if (!props.generationCircuits) return
        let tempOnSeries = []
        let tempOffSeries = []

        let sortedList = Object.keys(props.generationCircuits)
        sortedList.sort(function (a, b) {
            var itemA = props.generationCircuits[a];
            var itemB = props.generationCircuits[b];
            itemA = itemA.kwh.on + itemA.kwh.off
            itemB = itemB.kwh.on + itemB.kwh.off
            if (itemA > itemB) {
                return -1;
            }
            if (itemA < itemB) {
                return 1;
            }
            return 0;
        });

        for (let key of sortedList) {
            let item = props.generationCircuits[key]
            tempOnSeries.push([key, parseFloat(item.kwh.on.toFixed(2))])
            tempOffSeries.push([key, parseFloat(item.kwh.off.toFixed(2))])
        }
        let onSeries = {
            name: "Occupied Hours",
            data: tempOnSeries,
            color: "#619E7B",
        }
        let offSeries = {
            name: "Unoccupied Hours",
            data: tempOffSeries,
            color: "#D8D8D8"
        }
        setCurrentData([onSeries, offSeries, sortedList])

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.generationCircuits, props.view])


    useEffect(() => {
        if (!currentData || currentData.length === 0) return
        setOptions({
            chart: {
                type: 'column',
                height: 500

            },
            credits: {
                enabled: false,
            },
            legend: {
                enabled: true,
                reversed: true,
                align: 'left',
                layout: 'horizontal',
                verticalAlign: 'bottom',
                itemMarginBottom: 0,
                itemMarginTop: 0,
            },
            title: {
                text: '',
                align: 'left'
            },
            subtitle: {
                text: '',
                align: 'left'
            },
            xAxis: {
                type: 'category',
                min: 0,
                max: currentData[1].data.length <= 20 ? currentData[1].data.length - 1 : 20,
                accessibility: {
                    description: 'Circuits'
                },
                scrollbar: {
                    enabled: true
                },
                events: {
                    setExtremes: function (e) {
                        if (e.max >= this.dataMax || !e.max) {
                            return false
                        }
                        if (e.min < 1 && e.max < 1) return false
                    },
                    afterSetExtremes: function (e) {
                        if (e.min < 0 && e.max < 5) this.setExtremes(0, 5)
                        else if (e.min < 0) this.setExtremes(0, e.max)
                    }
                }
            },
            yAxis: {
                title: {
                    align: 'high',
                    offset: 0,
                    text: '',
                    rotation: 0,
                    y: 10,
                },
                labels: {
                    formatter: function () {
                        return this.value;
                    }
                }
            },
            tooltip: {
                formatter: function () {
                    if (props.unit === 'kva') {
                        return '<b>' + (this.x + 1) + " - " + this.point.name + '</b><br/><br/><b>Demand: </b>' + parseInt(this.point.y).toLocaleString() + 'kVA';
                    } else {
                        return '<b>' + (this.x + 1) + " - " + this.point.name + '</b><br/><br/><b>Occupied Hours: </b>' + (unitDict[props.unit] === "$" ? "$" : "") + parseInt(this.point.onNumber).toLocaleString() + (unitDict[props.unit] !== "$" ? unitDict[props.unit] : "") + '<br/><b>Unoccupied Hours: </b>' + (unitDict[props.unit] === "$" ? "$" : "") + parseInt(this.point.offNumber).toLocaleString() + (unitDict[props.unit] !== "$" ? unitDict[props.unit] : "");
                    }
                },
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: false
                    },
                    stacking: 'normal',
                    states: {
                        inactive: {
                            enabled: false
                        },
                        // hover: {
                        //     color: 'blue'
                        // }
                    }
                }
            },
            series: props.unit === 'kva' ? (Object.values(props.circuits)[0].realPeakOn ? currentData[0] : currentData[1]) : [currentData[1], currentData[0]],
            exporting: {
                buttons: {
                    contextButton: {
                        menuItems: [
                            'viewFullscreen', 'separator', 'downloadCSV', 'downloadPNG', 'downloadPDF'
                        ]
                    },
                },
                enabled: true,
            },
        })
        if (!chartRef.current) return
        chartRef.current.chart.xAxis[0].setExtremes(0, currentData[1].data.length <= 20 ? currentData[1].data.length - 1 : 20);
        const chart = chartRef.current.chart;
        Highcharts.addEvent(chart, 'exportData', function (e) {
            if (props.unit === 'kva') {
                if (e.dataRows[0].includes('PF')) return
                e.dataRows[0].push('PF');
                currentData[0].data.forEach((item, index) => {
                    if (e.dataRows[index + 1]) e.dataRows[index + 1].push(item.pf);
                });
            } else if (props.unit === 'kwh') {
                if (e.dataRows[0].includes('t CO₂e')) return
                e.dataRows[0].push('t CO₂e');
                currentData[0].data.forEach((item, index) => {
                    if (e.dataRows[index + 1]) e.dataRows[index + 1].push(item.co);
                });
            }
        });
        setLoading(false)
        props.setComplete(e => e + "1")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentData, chartRef, props.unit])

    const handleViewChange = (e) => {
        props.setView(e.currentTarget.value)
    }

    const handleSelectCircuits = (e) => {
        let val = e.target.value
        if (val === 'all') {
            props.selectCircuit(props.circuitOptions)
            return
        }
        if (val === 'none') {
            props.selectCircuit(prev => [])
            return
        }
        if (props.selectedCircuits.includes(val)) {
            props.selectCircuit(prev => prev.filter(v => v !== val))
        } else {
            props.selectCircuit(prev => [...prev, val])

        }
    }

    const handleSelectCategories = (e) => {
        let val = e.target.value
        if (val === 'all') {
            props.selectCategory(props.categoryOptions)
            return
        }
        if (val === 'none') {
            props.selectCategory([])
            return
        }
        if (props.selectedCategories.includes(val)) {
            props.selectCategory(prev => prev.filter(v => v !== val))
        } else {
            props.selectCategory(prev => [...prev, val])

        }
    }

    const handleSelectSubCategories = (e) => {
        let val = e.target.value
        if (val === 'all') {
            props.selectSubCategory(props.subCategoryOptions)
            return
        }
        if (val === 'none') {
            props.selectSubCategory([])
            return
        }
        if (props.selectedSubCategories.includes(val)) {
            props.selectSubCategory(prev => prev.filter(v => v !== val))
        } else {
            props.selectSubCategory(prev => [...prev, val])

        }
    }

    const handleSelectDistribution = (e) => {
        let val = e.target.value
        if (val === 'all') {
            props.selectDistribution(props.distributionOptions)
            return
        }
        if (val === 'none') {
            props.selectDistribution([])
            return
        }
        if (props.selectedDistributions.includes(val)) {
            props.selectDistribution(prev => prev.filter(v => v !== val))
        } else {
            props.selectDistribution(prev => [...prev, val])

        }
    }

    return (
        <div>
            <div className={`card tablet:p-8 rounded-lg relative mt-8`}>
                <div className="flex laptop:flex-row mobile:flex-col mobile:justify-start mobile:items-start laptop:justify-between laptop:p-0 tablet:p-8 mobile:p-8">
                    <div className=''>
                        <h2 className="capitalize w-full laptop:text-3xl tablet:text-xl mb-2 font-bold text-[#332D41] md:decoration-dashed">Consumption Breakdown</h2>
                        {props.startEnd && <p className='mb-4 font-light text-sm'>Date Range: <span className='font-normal'>{moment(props.startEnd[0]).format("MMM DD, YYYY")} - {moment(props.startEnd[1]).format("MMM DD, YYYY")} ({GraphFuncs.getDifferenceInDays(props.startEnd[0], props.startEnd[1])} days)</span></p>}
                        {props.compare && props.compareStartEnd.length === 2 && <p className='mb-4 font-light text-sm'>Comparative Range: <span className='font-normal'>{moment(props.compareStartEnd[0]).format("MMM DD, YYYY")} - {moment(props.compareStartEnd[1]).format("MMM DD, YYYY")} ({GraphFuncs.getDifferenceInDays(props.compareStartEnd[0], props.compareStartEnd[1])} days)</span></p>}
                    </div>
                    <div>
                        {props.view !== 'category' && props.selectedCategories.length === 1 && <h2 className='mb-2 font-medium mt-4'>Selected Category</h2>}
                        {
                            props.view !== 'category' && props.selectedCategories.length === 1 &&
                            <p className=''>{props.selectedCategories[0]}</p>
                        }
                        {props.view !== 'subcategory' && props.selectedSubCategories.length === 1 && <h2 className='mb-2 font-medium mt-4'>Selected Sub-Category</h2>}
                        {
                            props.view !== 'subcategory' && props.selectedSubCategories.length === 1 &&
                            <p className=''>{props.selectedSubCategories[0]}</p>
                        }
                    </div>
                </div>
                <div className="flex flex-nowrap tablet:justify-end laptop:justify-start mobile:justify-end items-center rounded-lg min-h-[500px]">
                    <div className={`${open ? 'laptop:w-3/12 tablet:w-1/2 mobile:w-full' : 'w-0'} side-card tablet:p-5 mobile:p-4 flex-col z-10 laptop:static tablet:absolute mobile:absolute tablet:left-0 tablet:top-0 mobile:left-0 mobile:top-0 laptop:min-h-[500px] laptop:h-auto tablet:h-full mobile:h-full backround-white tablet:[card] transition-width duration-300 ease-in-out`}>
                        {open && <div className='flex flex-col justify-between laptop:min-h-[500px] laptop:h-auto tablet:h-full mobile:h-full mr-3'>
                            <div>
                                <div className=" grid grid-col justify-self-center">
                                    {props.unit === 'kwh' && <h2 className='mb-2 font-medium mt-4'>{props.compare ? "Building Total Difference" : "Building Total"}</h2>}
                                    {props.unit === 'kwh' && <p>{props.compare ? parseFloat(props.virtualTotalCompare).toLocaleString() : parseFloat(props.virtualTotal).toLocaleString()} kWh</p>}
                                    {props.unit === 'kwh' && visible.co && <p>{props.compare ? parseFloat(totalCo.toFixed(2)).toLocaleString() : parseFloat(totalCo.toFixed(2)).toLocaleString()} t CO₂e</p>}
                                    {props.unit === 'price' && <h2 className='mb-2 font-medium mt-4'>{props.compare ? "Building Total Difference" : "Building Total"}</h2>}
                                    {props.unit === 'price' && <p>${props.compare ? parseInt(props.totalPriceCompare).toLocaleString() : parseInt(props.totalPrice).toLocaleString()}</p>}
                                    {props.unit === 'kva' && <h2 className='mb-2 font-medium mt-4'>Building Peak</h2>}
                                    <div className='flex flex-nowrap justify-between items-center'>
                                        {props.unit === 'kva' && <p>{props.compare && props.realPeakCompare ? parseFloat(Object.values(props.realPeak)[0] - Object.values(props.realPeakCompare)[0]).toLocaleString() : parseFloat(Object.values(props.realPeak)[0]).toLocaleString()} kVA</p>}
                                        {props.unit === 'kva' && <p className='mr-5'>PF: {props.compare ? parseFloat(props.peakPf - props.peakPfCompare).toLocaleString() : parseFloat(props.peakPf).toLocaleString()}</p>}
                                    </div>
                                    {props.unit === 'kva' && <h2 className='mb-2 font-medium mt-4'>Reached At</h2>}
                                    {props.unit === 'kva' && <p className='text-sm'>{GlobalFuncs.get15MinRange(Object.keys(props.realPeak)[0])} - {Object.keys(props.realPeak)[0].split(" ")[1]}</p>}
                                    {props.unit === 'kva' && props.compare && <h2 className='mb-2 font-medium mt-4'>And</h2>}
                                    {props.unit === 'kva' && props.compare && props.realPeakCompare && <p className='text-sm'>{GlobalFuncs.get15MinRange(Object.keys(props.realPeakCompare)[0])} - {Object.keys(props.realPeakCompare)[0].split(" ")[1]}</p>}
                                    {/* {props.unit !== 'kva' && parseInt(kwhTotal) !==  Object.keys(props.circuits).length && <h2 className='font-medium mt-4'>Selected Total</h2>}
                                    {props.unit === 'kwh' && props.selectedCircuits.length !== Object.keys(props.circuits).length && <p className='mb-2'>{parseFloat(kwhTotal).toLocaleString()} kWh</p>} */}
                                    <h2 className='mb-2 font-medium mt-4'>Type</h2>
                                    <select className='w-1/2 p-2 border border-1 rounded  border-[#C1C1C1] text-[#377745] w-[220px]' value={props.unit} onChange={props.setUnit}>
                                        {props.view !== 'generation' && <option value="price">Cost ($)</option>}
                                        {props.view !== 'generation' && <option value="kva">Demand (kVA)</option>}
                                        <option value="kwh">Energy (kWh)</option>

                                    </select>
                                    <h2 className='mb-2 font-medium mt-4'>View</h2>
                                    <select className='w-1/2 p-2 border border-1 rounded  border-[#C1C1C1] text-[#377745] w-[220px]' value={props.view} onChange={handleViewChange}>
                                        <option value="circuit">Circuits</option>
                                        <option value="category">Categories</option>
                                        <option value="subcategory">Sub-Categories</option>
                                        <option value="generation">Source</option>

                                    </select>
                                    {props.view === 'circuit' && <h2 className='mb-2 font-medium mt-4'>Circuit</h2>}
                                    {
                                        props.view === 'circuit' && props.circuitOptions && props.circuitOptions.length > 0 &&
                                        <MultipleSelect reports={true} data={props.circuitOptions} dataType='circuits' selectItem={handleSelectCircuits} selectedList={props.selectedCircuits} placeholder="Select Circuits" deselectAll={true} />
                                    }
                                    {props.view === 'category' && <h2 className='mb-2 font-medium mt-4'>Category</h2>}
                                    {
                                        props.view === 'category' && props.categoryOptions && props.categoryOptions.length > 0 &&
                                        <MultipleSelect reports={true} data={props.categoryOptions} dataType='categories' selectItem={handleSelectCategories} selectedList={props.selectedCategories} placeholder="Select Categories" deselectAll={true} />
                                    }
                                    {props.view === 'subcategory' && <h2 className='mb-2 font-medium mt-4'>Sub-Category</h2>}
                                    {
                                        props.view === 'subcategory' && props.subCategoryOptions && props.subCategoryOptions.length > 0 &&
                                        <MultipleSelect reports={true} data={props.subCategoryOptions} dataType='subcategories' selectItem={handleSelectSubCategories} selectedList={props.selectedSubCategories} placeholder="Select Sub-Categories" deselectAll={true} />
                                    }
                                    {props.view !== 'generation' && <h2 className='mb-2 font-medium mt-4'>Distribution</h2>}
                                    {
                                        props.view !== 'generation' && props.distributionOptions && props.distributionOptions.length > 0 &&
                                        <MultipleSelect reports={true} data={props.distributionOptions} dataType='distribution' selectItem={handleSelectDistribution} selectedList={props.selectedDistributions} placeholder="Select Distribution" deselectAll={true} />
                                    }
                                    <br />
                                    {props.unit === 'kva' && props.view === 'circuit' && <SlideToggle setOptions={setVisible} options={visible} id={"powerfactor"} index={1} name={"Power Factor"} />}
                                    {props.view !== 'generation' && props.unit === 'kwh' && <SlideToggle setOptions={setVisible} options={visible} id={"co"} index={2} name={"Emissions (t CO₂e)"} />}
                                </div>
                            </div>
                            <div className='mt-4 flex flex-nowrap justify-start items-center cursor-pointer' onClick={() => setOpen(false)}>
                                <div className='border border-light-green rounded-full w-8 h-8 flex flex-nowrap items-center justify-center'>
                                    {GlobalSVG.leftArrow()}
                                </div>
                                <p className='ml-3 cursor-pointer'>
                                    Hide options
                                </p>
                            </div>
                        </div>}
                        {
                            !open &&
                            <div className={`${open ? 'w-2/12' : 'items-center'} flex flex-col justify-end h-full`}>
                                <div onClick={() => setOpen(true)} className='cursor-pointer border border-light-green rounded-full tablet:w-8 tablet:h-8 mobile:w-6 mobile:h-6  flex flex-nowrap items-center justify-center'>
                                    {GlobalSVG.rightArrow()}
                                </div>
                            </div>
                        }
                    </div>
                    <div className={`col-span-2 ${open ? 'w-9/12' : 'laptop:w-full mobile:w-11/12'}`}>
                        {unitDict[props.unit]}
                        {options && <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartRef}
                        />}
                        {loading && <Loader />}
                    </div>
                </div>
            </div >
        </div>
    )
};