import { React } from "react";
import { CircularGaugeComponent, AxesDirective, AxisDirective, Inject, PointersDirective, PointerDirective, Annotations, AnnotationDirective, AnnotationsDirective, RangesDirective, RangeDirective } from '@syncfusion/ej2-react-circulargauge';
import GlobalSVG from "../../utils/GlobalSVG";

export default function PublicArcGauge(props) {

    function buildArray() {
        const array = [];

        for (let i = 0; i <= 100; i += 1) {
            array.push(i, i + 1);
        }
        return array;
    }

    const colorMap = { e: "#FFD44D", w: "#2F7DBC", g: "#FDB23E" }

    function loadSix(args) {
    }

    return (
        <div className="relative drop-shadow-md" >
            <CircularGaugeComponent load={loadSix.bind(this)} background="transparent" width="425px" height="425px">
                <Inject services={[Annotations]} />
                <AxesDirective >
                    <AxisDirective startAngle={240} background="white" endAngle={120} radius="90%" minimum={0} maximum={100} lineStyle={{ width: 0 }} majorTicks={{ height: 0, }} minorTicks={{ height: 0, }} labelStyle={{ position: 'Outside', font: { size: '0px', color: '#1E7145' }, }} >
                        <PointersDirective>
                            {
                                buildArray().map((e, ix) => (
                                    <PointerDirective key={e + ix + 'linegauge' + props.content} radius="106%" color={"#E9E5E5"} type="Marker" value={e} markerShape="Rectangle" markerWidth={20} markerHeight={10} animation={{ enable: false, }} />
                                ))
                            }
                            {
                                buildArray().map((e, ix) => (
                                    <PointerDirective key={e + ix + 'linegauge' + props.content} radius="106%" color={props && e < ((props.amount / props.max) * 100) ? colorMap[props.type] : "#E9E5E5"} type="Marker" value={e} markerShape="Rectangle" markerWidth={20} markerHeight={10} animation={{ enable: true }} />
                                ))
                            }
                        </PointersDirective>
                        <RangesDirective>
                            <RangeDirective start={0} end={100} startWidth={30} endWidth={30} color="#FFF" radius="115%" />
                        </RangesDirective>
                        <AnnotationsDirective>
                            <AnnotationDirective content={props.content} angle={-10} zIndex="1" radius="-26%" />
                        </AnnotationsDirective>
                    </AxisDirective>
                </AxesDirective>
            </CircularGaugeComponent>
            <div className={`absolute top-[15%] w-full`}>
                {props.generation &&
                    <h5 className={`text-[24px] text-[#50575C] text-center h-[60px] mt-4`}>
                        Real-Time <br/> Renewable Generation
                    </h5>}
                <h5 className={`font-extrabold ${props.generation ?  "text-[120px] h-[170px] mt-[-10px]" : "text-[120px] h-[150px] mt-[10px]"} text-[${colorMap[props.type]}] text-center`}>
                    {props.amount && parseFloat(props.amount.toFixed(1)).toLocaleString()}
                </h5>
                <h5 className={`text-[32px] text-[#50575C] text-center`}>
                    {props.unit}
                </h5>
            </div>
            <div className={`absolute ${props.generation ?  "bottom-[-10%]" : "bottom-[-15%]"} w-full flex flex-nowrap items-center justify-center`}>
                <div className={`flex flex-nowrap items-center justify-center rounded-full ${props.generation ?  "w-[105px] h-[105px]" : "w-[170px] h-[170px]"} bg-[white] drop-shadow-md`}>
                    {props.type === 'e' && GlobalSVG.electricity("#FFD44D", props.generation ? 45 : 75, props.generation ? 81 : 135)}
                    {props.type === 'w' && GlobalSVG.water3("#2F7DBC", 89, 126)}
                    {props.type === 'g' && GlobalSVG.gas2("#FDB23E", 91, 111)}
                </div>
            </div>
        </div>
    );
}
