import React from 'react';
import './style.css'
import { useContext } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import Loader from "../../components/Loader";
import { Link } from 'react-router-dom';
import GlobalSVG from '../../utils/GlobalSVG';

const Profile = () => {

    const context = useContext(AppContext);

    return (
        <div className='tablet:p-8 mobile:p-3 h-[80vh]'>
            {
                context.getUserInfo ?
                    <div className='card tablet:p-8 mobile:p-3 h-full'>
                        <h1 className='text-3xl mb-8 font-bold'>Profile</h1>
                        <div className='flex flex-nowrap items-center justify-start pb-8 input-border'>
                            <img
                                className='navbar-image-limiter w-12 h-12 rounded-full block'
                                src={context.getUserInfo.logo ? context.getUserInfo.logo : require('../../assets/images/placeholder-image.png')} alt="" />
                            <p className='ml-4 text-2xl font-medium'>{context.getUserInfo.client_name}</p>
                        </div>
                        <div className='input-border w-full flex flex-wrap items-center pt-3 pb-3'>
                            <div className='w-full flex flex-wrap items-center justify-start'>
                                <p className='mobile:w-2/5 tablet:w-2/12 text-base input-label-color'>First Name</p>
                                <p className='mobile:w-3/5 tablet:w-2/12 mobile:text-base tabet:text-xl input-color'>{context.getUserInfo.firstname}</p>
                                <p className='mobile:w-2/5 tablet:w-2/12 text-base input-label-color'>Last Name</p>
                                <p className='mobile:w-3/5 tablet:w-2/12 mobile:text-base tabet:text-xl input-color'>{context.getUserInfo.lastname}</p>
                            </div>
                        </div>
                        <div className='input-border w-full flex flex-wrap items-center pt-3 pb-3'>
                            <div className='w-full flex flex-wrap items-center'>
                                <p className='mobile:w-2/5 tablet:w-2/12 text-base input-label-color'>Email</p>
                                <p className='mobile:w-3/5 tablet:w-2/12 mobile:text-base tabet:text-xl input-color'>{context.getUserInfo.email}</p>
                            </div>
                        </div>
                        <div className='input-border w-full flex flex-wrap items-center pt-3 pb-3'>
                            <div className='w-full flex flex-wrap items-center'>
                                <p className='mobile:w-2/5 tablet:w-2/12 text-base input-label-color'>Password</p>
                                <Link to={'/passchange-in'} className='mobile:w-3/5 tablet:w-1/2 flex flex-nowrap items-center'>
                                    <div className='w-6 h-6'>
                                        {
                                            GlobalSVG.key()
                                        }
                                    </div>
                                    <p className='text-base input-color ml-2'>Change Password</p>
                                </Link>
                            </div>
                        </div>
                    </div>
                    :
                    <Loader />
            }
        </div>
    );
};

export default Profile;