import React from 'react';
import MainGraph from '../../components/MainGraph';
import Carousel from '../../components/Carousel';
import { useState, useEffect, useContext } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import { useLocation, useNavigate } from 'react-router-dom';
import API from "../../utils/API";
import Swal from "sweetalert2";
import CarouselCard from '../../components/CarouselCard';
import PieChart from '../../components/PieChart';
import BarChart from '../../components/BarChart';
import GraphFuncs from '../../utils/GraphFuncs';

const Home = () => {

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const today_str = `${year}-${month}-${day}`;
    const context = useContext(AppContext);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [electricGague, setElectricGauge] = useState({ type: 'electricity', consumption: null, change: null, average: null, cost: null, invalid: false })
    const [waterGauge, setWaterGauge] = useState({ type: 'water', consumption: null, change: null, average: null, cost: null, unit: null, invalid: false })
    const [gasGauge, setGasGauge] = useState({ type: 'gas', consumption: null, change: null, average: null, cost: null, unit: null, invalid: false })
    const [coGauge, setCoGauge] = useState({ type: 'Emissions', consumption: null, change: null, average: null, total: null, invalid: false })
    const [currentE, setCurrentE] = useState(0)
    const [currentCo, setCurrentCo] = useState(0)
    const [currentW, setCurrentW] = useState(0)
    const [currentG, setCurrentG] = useState(0)
    const [mainCircuit, setMainCircuit] = useState(null)
    const [mainCircuitW, setMainCircuitW] = useState(null)
    const [mainCircuitG, setMainCircuitG] = useState(null)
    const [graphCircuits, setGraphCircuits] = useState(null)
    const [wUnit, setWUnit] = useState(null)
    const [gUnit, setGUnit] = useState(null)
    const [flicker, setFlicker] = useState(false)
    const navigate = useNavigate();



    useEffect(() => {
        if (!context.getBuildingInfo) return
        let end_circuits = GraphFuncs.findCirctuitInHierarchy('Main Distribution', context.getBuildingInfo.hierarchy)
        end_circuits.type = 'electricity'
        setMainCircuit(end_circuits)
        let waterMain = []
        if (context.getBuildingInfo.w_channels.length !== 0) {
            waterMain = GraphFuncs.findCirctuitInHierarchyD('Main Distribution', context.getBuildingInfo.w_hierarchy)
            waterMain.type = 'water'
        }
        setMainCircuitW(waterMain)

        let gasMain = []
        if (context.getBuildingInfo.g_channels.length !== 0) {
            gasMain = GraphFuncs.findCirctuitInHierarchyD('Main Distribution', context.getBuildingInfo.g_hierarchy)
            gasMain.type = 'gas'
        }
        setMainCircuitG(gasMain)

        let tempGraphcircuits = []
        if (end_circuits) tempGraphcircuits.push(end_circuits)
        if (waterMain) tempGraphcircuits.push(waterMain)
        if (gasMain) tempGraphcircuits.push(gasMain)
        setGraphCircuits(tempGraphcircuits)

        if (context.getBuildingInfo.w_channels.length !== 0) {
            setWUnit(context.getBuildingInfo.w_channels[0].input_unit)
        }
        if (context.getBuildingInfo.g_channels.length !== 0) {
            setGUnit(context.getBuildingInfo.g_channels[0].input_unit)
        }
        const getMinuteData = async () => {
            try {
                const cancelToken = API.cancelToken();
                const auth = context.getUser.token;
                API.getCurrentReading(cancelToken, auth, end_circuits.device_id, end_circuits.circuit_name).then(res => {
                    setCurrentE(res.data.toFixed(2))
                }).catch(e => {
                    if (e.message === 'cancelling') return
                    console.log(e);
                })
                if (waterMain && waterMain.length !== 0) {
                    API.getCurrentReadingD(cancelToken, auth, waterMain.device_id, waterMain.channel_name).then(res => {
                        setCurrentW(res.data.toFixed(2))
                    }).catch(e => {
                        if (e.message === 'cancelling') return
                        console.log(e);
                    })
                }
                let gasCoCurrent = 0
                if (gasMain && gasMain.length !== 0) {
                    let gData = await API.getCurrentReadingD(cancelToken, auth, gasMain.device_id, gasMain.channel_name)
                    setCurrentG(gData.data.toFixed(2))

                    let coGData = await API.getPriceCoGas(cancelToken, auth, today_str, today_str, gasMain.channel_name, gasMain.device_id, 1)
                    let highestKey = Object.keys(coGData.data.series).reduce((maxKey, currentKey) => {
                        return currentKey > maxKey ? currentKey : maxKey;
                    }, Object.keys(coGData.data.series)[0])
                    gasCoCurrent = coGData.data.series[highestKey]
                }
                let coEData = await API.getPriceCoElectric(cancelToken, auth, today_str, today_str, end_circuits.circuit_name, end_circuits.device_id, 60)
                let highestKey = Object.keys(coEData.data.series).reduce((maxKey, currentKey) => {
                    return currentKey > maxKey ? currentKey : maxKey;
                }, Object.keys(coEData.data.series)[0])
                let eCoCurrent = coEData.data.series[highestKey]

                setCurrentCo((gasCoCurrent + eCoCurrent).toFixed(2))
            } catch (e) {
                if (e.message === 'cancelling') return
                console.log(e)
            }

        };

        getMinuteData();
        const intervalId = setInterval(getMinuteData, 60000);

        return () => {
            clearInterval(intervalId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.getBuildingInfo]);


    useEffect(() => {
        const updateCounter = () => {
            let factor = flicker ? 0.05 : -0.05
            if (currentE !== null) {
                let num = factor * currentE
                setCurrentE(prevCounter => (parseFloat(prevCounter) + num).toFixed(2));
            }
            if (currentW !== null) {
                let num = factor * currentW
                setCurrentW(prevCounter => (parseFloat(prevCounter) + num).toFixed(2));
            }
            if (currentG !== null) {
                let num = factor * currentG
                setCurrentG(prevCounter => (parseFloat(prevCounter) + num).toFixed(2));
            }
            if (currentG !== null) {
                let num = factor * currentCo
                setCurrentCo(prevCounter => (parseFloat(prevCounter) + num).toFixed(2));
            }
            setFlicker(!flicker)
        };
        const intervalId = setInterval(updateCounter, 10000);
        return () => clearInterval(intervalId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentE, currentW, currentG])



    useEffect(() => {
        if (queryParams.size === 0 || queryParams.get('building') === "null") {
            navigate('/map')
        }
        if (context.getBuilding) return
        context.setBuilding(queryParams.get('building'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams])

    useEffect(() => {
        if (!mainCircuit || !context.getBuildingInfo) return
        const cancelToken = API.cancelToken();
        if (context.getBuildingInfo.e_channels.length === 0) {
            setElectricGauge(e => ({ ...e, invalid: true, loaded: true }))
        } else {
            get_e_card_data(cancelToken)
        }
        return () => {
            API.cancel(cancelToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainCircuit, context.getBuildingInfo])

    useEffect(() => {
        if (!mainCircuitW || !context.getBuildingInfo) return
        const cancelToken = API.cancelToken();
        if (mainCircuitW.length === 0 || context.getBuildingInfo.w_channels.length === 0) {
            setWaterGauge(e => ({ ...e, invalid: true, loaded: true }))
        } else {
            get_d_card_data(cancelToken, 'w', waterGauge, setWaterGauge)
        }
        return () => {
            API.cancel(cancelToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainCircuitW, context.getBuildingInfo])

    useEffect(() => {
        if (!mainCircuitG || !context.getBuildingInfo) return
        const cancelToken = API.cancelToken();
        if (mainCircuitG.length === 0 || context.getBuildingInfo.g_channels.length === 0) {
            setGasGauge(e => ({ ...e, invalid: true, loaded: true }))
        } else {
            get_d_card_data(cancelToken, 'g', gasGauge, setGasGauge)
        }
        return () => {
            API.cancel(cancelToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mainCircuitG, context.getBuildingInfo])

    useEffect(() => {
        if (!electricGague || !electricGague.loaded || !gasGauge || !gasGauge.loaded) return
        if (electricGague.invalid && gasGauge.invalid) setCoGauge(e => ({ ...e, invalid: true, loaded: true }))
        else {
            let consumption = (!electricGague.invalid && electricGague.coConsumption ? parseFloat(electricGague.coConsumption) : 0) + (!gasGauge.invalid && gasGauge.coConsumption ? parseFloat(gasGauge.coConsumption) : 0)
            let average = (!electricGague.invalid && electricGague.coAverage ? parseFloat(electricGague.coAverage) : 0) + (!gasGauge.invalid && gasGauge.coAverage ? parseFloat(gasGauge.coAverage) : 0)
            let change = (!electricGague.invalid && electricGague.coConsumption ?
                (parseFloat(electricGague.coConsumption) / consumption) * (parseFloat(electricGague.coConsumption) / parseFloat(electricGague.coAverage)) * 100
                : 0)
                + (!gasGauge.invalid && gasGauge.coConsumption ? (parseFloat(electricGague.coConsumption) / consumption) * average : 0)

            if (change === Infinity) {
                change = -100;
            }
            setCoGauge(e => ({
                ...coGauge,
                loaded: true,
                consumption: consumption,
                change: change,
                average: average
            }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [electricGague, gasGauge, setCoGauge])

    const get_e_card_data = (cancelToken) => {
        if (context.getFixed30DayData === null || context.getFixed30DayData.e_gauge === null) {
            const today = new Date();
            const today2 = new Date();
            today2.setDate(today2.getDate() - 30);
            const year = today.getFullYear();
            const year2 = today2.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const month2 = String(today2.getMonth() + 1).padStart(2, '0');
            const day = String(today.getDate()).padStart(2, '0');
            const dayMinus30 = String(today2.getDate()).padStart(2, '0');
            const today_str = `${year}-${month}-${day}`;
            const last_month_str = `${year2}-${month2}-${dayMinus30}`;

            const auth = context.getUser.token;
            API.getElectricity(cancelToken, auth, last_month_str, today_str, mainCircuit.device_id, 60, 'kw', mainCircuit.circuit_name).then(async res => {
                let series = res.data.series
                let consumption = 0
                let total = 0
                let count = 0

                const latest = Object.keys(res.data.series).reduce((max, timestamp) => {
                    const maxTimstamp = new Date(max).getTime();
                    const currentTimestamp = new Date(timestamp).getTime();
                    return currentTimestamp > maxTimstamp ? timestamp : max;
                }, Object.keys(res.data.series)[0]);

                for (let timestamp in series) {
                    if (timestamp === latest) consumption = series[timestamp]
                    if (timestamp.split(" ")[1] === latest.split(" ")[1] && new Date(timestamp).getDay() === new Date(latest).getDay()) {
                        total += series[timestamp]
                        count++
                    }
                }

                const average = count ? total / count : 0
                const delta = average ? (consumption / average) * 100 : 0

                const auth = context.getUser.token;
                let price = 0
                price = await API.getPriceE(cancelToken, auth, last_month_str, today_str, mainCircuit.channels[0].channel_name, mainCircuit.device_id)
                price = price.data.total
                let co = await API.getPriceCoElectric(cancelToken, auth, last_month_str, today_str, mainCircuit.channels[0].channel_name, mainCircuit.device_id, 60)
                let coConsumption = 0
                if (latest in co.data.series) coConsumption = co.data.series[latest]

                let tempEgauge = { ...electricGague, loaded: true, consumption: consumption.toFixed(2), change: delta.toFixed(2), average: average.toFixed(2), cost: (price).toFixed(2), coConsumption: coConsumption.toFixed(3), coKwh: co.data.kwh_consumption.toFixed(2), coAverage: (co.data.co_consumption / (30 * 24)).toFixed(2) }
                context.setFixed30DayData(e => ({ ...e, e_gauge: tempEgauge }))
                setElectricGauge(tempEgauge)
            }).catch(e => {
                console.log(e)
                if (e.message === 'cancelling') return
                Swal.fire({
                    title: 'Oops!',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            })
        } else {
            setElectricGauge(context.getFixed30DayData.e_gauge)
        }
    }

    const get_d_card_data = (cancelToken, type, gauge, setGauge) => {
        if (context.getFixed30DayData === null || context.getFixed30DayData[type + "_gauge"] === null) {
            const today = new Date();
            const today2 = new Date();
            today.setDate(today2.getDate() - 30);
            today2.setDate(today2.getDate());
            const year = today.getFullYear();
            const yearMinus30 = today2.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, '0');
            const monthMinus30 = String(today2.getMonth() + 1).padStart(2, '0');
            const day = String(today.getDate()).padStart(2, '0');
            const dayMinus30 = String(today2.getDate()).padStart(2, '0');
            const today_str = `${year}-${month}-${day}`;
            const last_month_str = `${yearMinus30}-${monthMinus30}-${dayMinus30}`;
            const auth = context.getUser.token;
            let device = ""
            let circuit = ""
            let apiType = ""
            if (type === 'w') {
                device = mainCircuitW.device_id
                circuit = mainCircuitW.channel_name
                apiType = mainCircuitW.channel.utility_type
            } else {
                device = mainCircuitG.device_id
                circuit = mainCircuitG.channel_name
                apiType = mainCircuitG.channel.utility_type
            }
            API.getDigital(cancelToken, auth, today_str, last_month_str, device, 60, circuit, apiType).then(async res => {
                let series = res.data.series
                let consumption = 0
                let total = 0
                let count = 0

                const latest = Object.keys(res.data.series).reduce((max, timestamp) => {
                    const maxTimstamp = new Date(max).getTime();
                    const currentTimestamp = new Date(timestamp).getTime();
                    return currentTimestamp > maxTimstamp ? timestamp : max;
                }, Object.keys(res.data.series)[0]);

                for (let timestamp in series) {
                    if (timestamp === latest) consumption = series[timestamp]
                    if (timestamp.split(" ")[1] === latest.split(" ")[1] && new Date(timestamp).getDay() === new Date(latest).getDay()) {
                        total += series[timestamp]
                        count++
                    }
                }

                const average = count ? total / count : 0
                const delta = average ? ((consumption - average) / average) * 100 : 0

                const auth = context.getUser.token;
                let price = 0
                let coConsumption = 0
                let coAverage = 0
                if (type === 'w') {
                    price = await API.getPriceW(cancelToken, auth, today_str, last_month_str, mainCircuitW.channel.channel_name, mainCircuitW.device_id)
                } else {
                    price = await API.getPriceG(cancelToken, auth, today_str, last_month_str, mainCircuitG.channel.channel_name, mainCircuitG.device_id)
                    let co = await API.getPriceCoGas(cancelToken, auth, today_str, last_month_str, mainCircuitG.channel.channel_name, mainCircuitG.device_id, 60)
                    if (latest in co.data.series) coConsumption = co.data.series[latest]
                    coAverage = co.data.co_consumption ? (co.data.co_consumption / (30 * 24)).toFixed(2) : 0
                }
                let tempDgauge = { ...gauge, loaded: true, consumption: consumption.toFixed(2), change: delta.toFixed(2), average: average.toFixed(2), cost: (price.data.total).toFixed(2), coConsumption: coConsumption.toFixed(3), coAverage: coAverage }
                context.setFixed30DayData(e => ({ ...e, [type + "_gauge"]: tempDgauge }))
                setGauge(tempDgauge)
            }).catch(e => {
                console.log(e)
                if (e.message === 'cancelling') return
            })
        } else {
            setGauge(context.getFixed30DayData[type + "_gauge"])
        }
    }


    return (
        <div className='tablet:p-12 mobile:p-3'>
            <div className='mx-auto '>
                <h2 className="tablet:text-4xl mobile:text-xl font-bold text-[#332D41]">Real-Time Metrics</h2>
                <div className="mt-6 lg:mt-16 w-full">
                    <Carousel tracker={false} buffer={true}>
                        <CarouselCard id="egauge" data={electricGague} type='electricity' current={currentE} />
                        <CarouselCard id="ggauge" data={gasGauge} type='natural gas' current={currentG} unit={gUnit} />
                        <CarouselCard id="wgauge" data={waterGauge} type='water' current={currentW} unit={wUnit} />
                        <CarouselCard id="cogauge" data={coGauge} type='emissions' current={currentCo} />
                    </Carousel>
                </div>
            </div>
            <div className='flex laptop:flex-row mobile:flex-col mt-8 justify-left gap-x-6'>
                <div className='card laptop:w-1/2 tablet:w-full min-w-[300px] tablet:p-8 mobile:p-2 items-center rounded-lg p-6'>
                    <PieChart mainCircuitE={mainCircuit} mainCircuitW={mainCircuitW} mainCircuitG={mainCircuitG} />
                </div>
                <div className='card laptop:w-1/2 tablet:w-full laptop:mt-0 tablet:mt-8 mobile:mt-8 min-w-[300px] tablet:p-8 tablet:p-8 mobile:p-2 items-center rounded-lg p-6'>
                    <BarChart mainCircuitE={mainCircuit} mainCircuitW={mainCircuitW} mainCircuitG={mainCircuitG} />
                </div>

            </div>
            <div className='mt-8'>
                <MainGraph circuits={graphCircuits ? graphCircuits : null} title="Overall Building Utilities" />
            </div>
        </div>
    );
};

export default Home;