import { useState, useEffect } from 'react';
import './style.css';
import Loader from '../../components/Loader';

function ImageSelector(props) {

    const [image, setImage] = useState(0);
    const [images, setImages] = useState(null);

    useEffect(()=>{
        if(props.images.length === 0){
            setImages([require('../../assets/images/placeholder-image.png')])
        }else{
            setImages(props.images)
        }
    },[props.images])

    return (
        images ?
            <div className="w-full">
                <div onClick={() => props.magnifyImage(images[image] ? images[image] : require('../../assets/images/placeholder-image.png'))}  className="main-display bg-no-repeat bg-contain bg-center rounded border border-blue-500" style={{ backgroundImage: `url(${images[image]})` }}>
                </div>
                <div className="flex flex-row flex-nowrap overflow-auto">
                    {
                        images.map((e, index)=>(
                            <div onClick={()=>setImage(index)} key={`selector-image-${index}`} className='image-selector cursor-pointer bg-no-repeat bg-contain bg-center rounded border border-blue-500 my-2 mr-2 md:my-5 md:mr-5' style={{ backgroundImage: `url(${e})` }}>

                            </div>
                        ))
                    }
                </div>
            </div> :
            <div className="img-selector-wrapper" style={{ 'position': 'relative' }}>
                <Loader light={true} />
            </div>
    )
}

export default ImageSelector;