import ArcGauge from "../ArcGauge";
import Loader from "../Loader";
import { useEffect, useState } from 'react'
import './style.css'

function CarouselCard(props) {

    const [unit, setUnit] = useState(null)
    const [currentUnit, setCurrentUnit] = useState(null)
    const dUnits = { 'l.': "L", "ft^3": "ft³" }

    useEffect(() => {
        switch (props.type) {
            case "electricity":
                setUnit("kWh")
                setCurrentUnit("kW")
                break
            case "emissions":
                setUnit("t CO₂e")
                setCurrentUnit("t CO₂e")
                break
            case "water": case "natural gas":
                setUnit(dUnits[props.unit])
                setCurrentUnit(dUnits[props.unit])
                break
            default:
                setUnit("")
                setCurrentUnit("")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    return (
        <div className={`${props.data && props.data.loaded && props.data.invalid ? "inactive-carousel-card" : ""} carousel-category p-5 flex mobile:flex-col tablet:flex-row flex-nowrap w-full rounded-lg`}>
            {
                props.data && props.data.loaded === true && props.current !== null ?
                    (props.data.invalid ?
                        <div className="flex flex-nowrap w-7/12">
                            <div className="flex flex-col flex-nowrap justify-between">
                                <div>
                                    <p className="capitalize tablet:text-xl mobile:text-sm font-sans font-bold text-[#332D41]">{props.data.type}</p>
                                    <p>Want to see {props.data.type}<br></br>consumption data?</p>
                                    <p className="mt-4 text-2xl dark-green-text">Contact us</p>
                                </div>
                                <div className="">
                                    <p className=" text-[11px] font-sans font-bold text-base text-[#332D41] whitespace-nowrap"> Previous: 30 days</p>
                                    <p className=" text-[11px] font-sans font-semibold text-[#332D41] whitespace-nowrap"> Avg Usage Current Hour: {props.data.average ? parseFloat(parseFloat(props.data.average.toFixed(2))).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0} {unit}</p>
                                    {props.type !== "emissions" && <p className=" text-[11px] font-sans font-semibold text-[#332D41] whitespace-nowrap">Approximate Cost: ${props.data.cost ? props.data.cost.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0}/month </p>}
                                </div>
                            </div>
                        </div>
                        :
                        <div className="flex flex-nowrap w-7/12">
                            <div className="flex flex-col flex-nowrap justify-between">
                                <p className="capitalize tablet:text-xl mobile:text-sm font-sans font-bold text-[#332D41]">{props.data.type}</p>
                                <div className="flex flex-nowrap justify-between items-center">
                                    <div>
                                        <p className="tablet:text-xl mobile:text-sm font-sans font-semibold text-[#332D41] whitespace-nowrap">{parseFloat(parseFloat(props.data.consumption).toFixed(2)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {unit}</p>
                                        <p className="text-xs">Past Hour</p>
                                    </div>
                                    {/* <div className={`py-1.5 px-2 ml-2 rounded font-bold ${props.data.change > 0 ? 'bg-[#ffe6e6]' : 'bg-[#28C76F14] '}`}>
                                        <p className={`text-xs font-sans text-center text-xs leading-4 ${props.data.change > 0 ? 'text-[#FF8080]' : 'text-[#619E7B]'}`}>{!isNaN(props.data.change) ? parseFloat(parseFloat(props.data.change).toFixed(0)).toLocaleString() : 0}%</p>
                                    </div> */}

                                </div>
                                <div className="">
                                    <p className=" text-[11px] font-sans font-bold text-base text-[#332D41] whitespace-nowrap"> Previous: 30 days</p>
                                    <p className=" text-[11px] font-sans font-semibold text-[#332D41] whitespace-nowrap"> Avg Usage Current Hour: {!isNaN(props.data.average) ? parseFloat(parseFloat(props.data.average).toFixed(2)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0} {unit}</p>
                                    {props.type !== "emissions" && <p className=" text-[11px] font-sans font-semibold text-[#332D41] whitespace-nowrap">Approximate Cost: ${parseInt(props.data.cost).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}/month </p>}
                                </div>
                            </div>
                        </div>)
                    :
                    <div className="w-full">
                        <Loader />
                    </div>
            }
            {
                props.data && props.data.loaded && props.current !== null ?
                    (!props.data.invalid ?
                        <div className="w-5/12 text-right">
                            <ArcGauge inactive={false} percentage={props.current && props.data.consumption ? (['electricity', 'emissions'].includes(props.type)  ? (props.current / (props.data.average * 2) * 100) : (props.current !== '0.00' ? (props.data.consumption / (props.data.average * 2))* 100 : 0)) : 0} amount={props.current} content={`<span class='font-bold text-center' style='font-size:9px'>Real Time</span></br><p class='font-bold text-center'>${props.current}</p><p class='font-bold text-center'>${currentUnit}</p>`} />
                        </div>
                        :
                        <div className="w-5/12 text-right">
                            <ArcGauge inactive={true} percentage={props.current && props.data.consumption ? (props.current / (props.data.average * 2) * 100) : 0} amount={props.current} content={`<p class='font-bold text-center'>00.0</p><p class='font-bold text-center'>${props.data.type === 'water' ? "L" : "ft³"}</p>`} />
                        </div>)
                    :
                    ""
            }
        </div >
    )
}

export default CarouselCard