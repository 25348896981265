import './style.css'
import React from "react";

export default function SlideToggle(props) {

    const handleChecked = () => {
        if (props.disabled) return
        if (props.options) {

            if (props.id === 'gas' && !props.options.gas && props.options.water) {
                props.setOptions(e => ({ ...e, gas: !props.options[props.id], water: false }))
            } else if (props.id === 'water' && !props.options.water && props.options.gas) {
                props.setOptions(e => ({ ...e, water: !props.options[props.id], gas: false }))
            } else {
                props.setOptions(e => ({ ...e, [props.id]: !props.options[props.id], }))
            }
        }
    }


    return (
        <div className="flex flex-nowrap items-between justify-between mt-2 mr-4" >
            <div className='flex justify-end mr-2'>
                <label
                    className={`inline-block pl-[0.15rem] hover:cursor-pointer`}
                    htmlFor={props.id}>
                    <p className={`${props.titleClass ? props.titleClass : 'text-[#332D41] font-semibold'}`}>{props.name}</p>
                </label>
            </div>
            <div className='flex justify-start ml-2'>
                <label className="switch" htmlFor={props.id}>
                    <input
                        type="checkbox"
                        id={props.id}
                        checked={props.options && props.options[props.id]}
                        onClick={handleChecked}
                    />
                    <span className={`slider round ${props.id} `}></span>
                </label>
            </div>
        </div>
    );
};