import React from 'react';
import { useState, useEffect, useContext, useMemo } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import { Link, useLocation } from 'react-router-dom';
import API from "../../utils/API";
import GraphFuncs from '../../utils/GraphFuncs';
import GlobalSVG from '../../utils/GlobalSVG';
import './style.css'
import GlobalFuncs from '../../utils/GlobalFuncs';
import DateRangePicker from '../../components/DateRangePicker';
import PortfolioGraph1 from '../../components/PortfolioGraph1';
import MultipleSelect from '../../components/MultipleSelect';
import PortfolioGraph2 from '../../components/PortfolioGraph2';
import Loader from '../../components/Loader';
import moment from 'moment';

const Report = () => {

    const location = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

    const context = useContext(AppContext);
    const [unit, setUnit] = useState('price')

    const [openFilters, setOpenFilters] = useState(false)
    const [buildingOptions, setBuildingOptions] = useState(false)
    const [buildingsInfo, setBuildingsInfo] = useState(false)
    const [compareBuildingInfo, setCompareBuildingInfo] = useState(null)
    const [filteredBuildings, setFilteredBuildings] = useState([]);
    const [finalInfo, setFinalInfo] = useState([])
    const [startEnd, setStartEnd] = useState([moment().subtract(1, 'months').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
    const [compareStartEnd, setCompareStartEnd] = useState(null)
    const [compareOn, setCompareOn] = useState(false)

    useEffect(() => {
        if (!context.getUserInfo || !startEnd) return

        const cancelToken = API.cancelToken();
        const auth = context.getUser.token;
        let building = queryParams.get('building')
        let client = null
        if (building) {
            client = context.getUserInfo.buildings.find(e => e.building_id === building)
            if (client) client = client.client_id
        }

        const getBuildings = async () => {
            let buildingsDict = {}
            setBuildingOptions(context.getUserInfo.buildings.map(e => e.building))
            setFilteredBuildings(context.getUserInfo.buildings.map(e => e.building))
            for (let building of context.getUserInfo.buildings) {
                if (client && (building.client_id !== client)) continue
                try {
                    let buildingInfo = await API.getBuilding(cancelToken, auth, building.building_id)
                    buildingInfo = buildingInfo.data
                    buildingInfo.mainCircuitE = GraphFuncs.findCirctuitInHierarchy('Main Distribution', buildingInfo.hierarchy)

                    buildingInfo.price_e = 0
                    let price = await API.getPriceE(cancelToken, auth, startEnd[0], startEnd[1], buildingInfo.mainCircuitE.circuit_name, buildingInfo.mainCircuitE.device_id)
                    buildingInfo.price_e = price.data.total.toFixed(2)

                    let consumption = await API.getPriceCoElectric(cancelToken, auth, startEnd[0], startEnd[1], buildingInfo.mainCircuitE.circuit_name, buildingInfo.mainCircuitE.device_id, 60)
                    consumption = consumption.data

                    buildingInfo.co_e = consumption.co_consumption.toFixed(2)
                    buildingInfo.kwh = consumption.kwh_consumption.toFixed(2)

                    if (buildingInfo.w_channels.length > 0) {
                        buildingInfo.mainCircuitW = GraphFuncs.findCirctuitInHierarchyD('Main Distribution', buildingInfo.w_hierarchy)
                        let total = await API.getDigital(cancelToken, auth, startEnd[0], startEnd[1], buildingInfo.mainCircuitW.device_id, 60, buildingInfo.mainCircuitW.channel_name, 'Water')
                        buildingInfo.water = Object.values(total.data.series).reduce((sum, value) => sum + value, 0)
                        if (buildingInfo.mainCircuitW.channel.input_unit === 'ft^3') {
                            buildingInfo.water = (buildingInfo.water / 35.3147).toFixed(2)
                        } else if (buildingInfo.mainCircuitW.channel.input_unit === 'l.') {
                            buildingInfo.water = (buildingInfo.water / 1000).toFixed(2)
                        }
                    }
                    if (buildingInfo.g_channels.length > 0) {
                        buildingInfo.mainCircuitG = GraphFuncs.findCirctuitInHierarchyD('Main Distribution', buildingInfo.g_hierarchy)
                        let consumptionG = await API.getPriceCoGas(cancelToken, auth, startEnd[0], startEnd[1], buildingInfo.mainCircuitG.channel_name, buildingInfo.mainCircuitG.device_id, 60)
                        consumptionG = consumptionG.data

                        buildingInfo.co_g = consumptionG.co_consumption.toFixed(2)
                        buildingInfo.gas = consumptionG.consumption

                        let priceG = await API.getPriceG(cancelToken, auth, startEnd[0], startEnd[1], buildingInfo.mainCircuitG.channel_name, buildingInfo.mainCircuitG.device_id)
                        priceG = priceG.data
                        buildingInfo.price_g = priceG.total.toFixed(2)
                    }

                    buildingsDict[building.building_id] = buildingInfo
                } catch (e) {
                    console.log("Failed to get building data")
                    console.log(e)
                }
            }
            setBuildingsInfo(buildingsDict)
        }
        getBuildings()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.getUserInfo, startEnd, queryParams]);


    useEffect(() => {
        if (!context.getUserInfo || !compareStartEnd) return

        const cancelToken = API.cancelToken();
        const auth = context.getUser.token;
        let building = queryParams.get('building')
        let client = null
        if (building) {
            client = context.getUserInfo.buildings.find(e => e.building_id === building)
            if (client) client = client.client_id
        }

        const getBuildings = async () => {
            let buildingsDict = {}
            for (let building of context.getUserInfo.buildings) {
                if (client && (building.client_id !== client)) continue
                try {
                    let buildingInfo = await API.getBuilding(cancelToken, auth, building.building_id)
                    buildingInfo = buildingInfo.data
                    buildingInfo.mainCircuitE = GraphFuncs.findCirctuitInHierarchy('Main Distribution', buildingInfo.hierarchy)

                    buildingInfo.price_e = 0
                    let price = await API.getPriceE(cancelToken, auth, compareStartEnd[0], compareStartEnd[1], buildingInfo.mainCircuitE.circuit_name, buildingInfo.mainCircuitE.device_id)
                    buildingInfo.price_e = parseFloat(buildingsInfo[building.building_id].price_e) - price.data.total

                    let consumption = await API.getPriceCoElectric(cancelToken, auth, compareStartEnd[0], compareStartEnd[1], buildingInfo.mainCircuitE.circuit_name, buildingInfo.mainCircuitE.device_id, 60)
                    consumption = consumption.data

                    buildingInfo.co_e = (parseFloat(buildingsInfo[building.building_id].co_e) - consumption.co_consumption).toFixed(2)
                    buildingInfo.kwh = (parseFloat(buildingsInfo[building.building_id].kwh) - consumption.kwh_consumption).toFixed(2)
                    if (buildingInfo.w_channels.length > 0) {
                        buildingInfo.mainCircuitW = GraphFuncs.findCirctuitInHierarchyD('Main Distribution', buildingInfo.w_hierarchy)
                        let total = await API.getDigital(cancelToken, auth, compareStartEnd[0], compareStartEnd[1], buildingInfo.mainCircuitW.device_id, 60, buildingInfo.mainCircuitW.channel_name, 'Water')
                        buildingInfo.water = Object.values(total.data.series).reduce((sum, value) => sum + value, 0)
                        if (buildingInfo.mainCircuitW.channel.input_unit === 'ft^3') {
                            buildingInfo.water = (parseFloat(buildingsInfo[building.building_id].water) - (buildingInfo.water / 35.3147)).toFixed(2)
                        } else if (buildingInfo.mainCircuitW.channel.input_unit === 'l.') {
                            buildingInfo.water = (parseFloat(buildingsInfo[building.building_id].water) - (buildingInfo.water / 1000)).toFixed(2)
                        }
                    }
                    if (buildingInfo.g_channels.length > 0) {
                        buildingInfo.mainCircuitG = GraphFuncs.findCirctuitInHierarchyD('Main Distribution', buildingInfo.g_hierarchy)
                        let consumptionG = await API.getPriceCoGas(cancelToken, auth, compareStartEnd[0], compareStartEnd[1], buildingInfo.mainCircuitG.channel_name, buildingInfo.mainCircuitG.device_id, 60)
                        consumptionG = consumptionG.data
                        buildingInfo.co_g = (buildingsInfo[building.building_id].co_g - (consumptionG.co_consumption)).toFixed(2)
                        buildingInfo.gas =  (buildingsInfo[building.building_id].gas - (consumptionG.consumption)).toFixed(2)
                        let priceG = await API.getPriceG(cancelToken, auth, compareStartEnd[0], compareStartEnd[1], buildingInfo.mainCircuitG.channel_name, buildingInfo.mainCircuitG.device_id)
                        priceG = priceG.data
                        buildingInfo.price_g = (parseFloat(buildingsInfo[building.building_id].price_g) - priceG.total).toFixed(2)
                    }

                    buildingsDict[building.building_id] = buildingInfo
                } catch (e) {
                    console.log("Failed to get building data")
                    console.log(e)
                }
            }
            setCompareBuildingInfo(buildingsDict)
        }
        getBuildings()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.getUserInfo, compareStartEnd]);

    useEffect(() => {
        if (!buildingsInfo) return
        let tempFinal = {}
        let list = JSON.parse(JSON.stringify(buildingsInfo))
        if (compareOn && compareBuildingInfo) {
            list = JSON.parse(JSON.stringify(compareBuildingInfo))
        }
        for (let building in list) {
            if (!filteredBuildings.includes(list[building].building)) continue
            else tempFinal[building] = list[building]
        }
        setFinalInfo(tempFinal)

    }, [filteredBuildings, buildingsInfo, compareOn, compareBuildingInfo])


    const handleDateChange = (data) => {
        if (!data.value) return
        let startDate = data.value[0]
        let endDate = data.value[1]
        setStartEnd([GlobalFuncs.formatDate(startDate, 'YYYY-MM-DD'), GlobalFuncs.formatDate(endDate, 'YYYY-MM-DD')])
        setBuildingsInfo(null)
    }

    const handleDateCompare = (data) => {
        if (!data.value) return
        let diff = GraphFuncs.getDifferenceInDays(startEnd[0], startEnd[1]) - 1
        let startDate = data.value
        let endDate = moment(startDate).add(diff, 'days');
        setCompareStartEnd([moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD")])
    }

    const handleChangeUnit = (e) => {
        setUnit(e.currentTarget.value)
    }

    const handleFilterBuilding = (e) => {
        let val = e.target.value
        if (val === 'all') {
            setFilteredBuildings([...buildingOptions])
            return
        }
        if (filteredBuildings.includes(val)) {
            setFilteredBuildings(prev => prev.filter(v => v !== val))
        } else {
            setFilteredBuildings(prev => [...prev, val])
        }
    }

    return (
        <div>
            <div className="laptop:px-8 tablet:px-4 mobile:px-4 py-2 flex flex-nowrap justify-between items-center rounded-md bg-[#FFF] shadow-md" >
                <div className='flex flex-nowrap gap-6 text-base font-bold'>
                    <Link to={`/reports?building=all`}>Buildings</Link>
                    <Link to={`/report`} className='active-reports-tab'>Portfolio</Link>
                </div>
                <div className='flex flex-nowrap justify-end items-center w-9/12'>
                    {!context.getLaptop &&
                        <div className="flex flex-norap justify-end items-center">
                            <div className='flex justify-end items-center m-2'>
                                <DateRangePicker onChange={handleDateChange}
                                    start={startEnd[0]}
                                    end={startEnd[1]}
                                    compare={compareOn}
                                    onChangeCompare={handleDateCompare}
                                    compareStart={compareStartEnd} />
                            </div>
                            {!compareOn && <div className='flex flex-nowrap justify-center items-center m-2 cursor-pointer' onClick={() => setCompareOn(true)}>
                                {GlobalSVG.pentagon()}
                                <p className='ml-2 font-medium text-[#587464]'>Compare Periods</p>
                            </div>}
                            {compareOn && <div className='flex flex-nowrap justify-center items-center m-2 cursor-pointer' onClick={() => setCompareOn(false)}>
                                {GlobalSVG.closeBig()}
                                <p className='ml-2 font-medium text-[#587464]'>Stop Comparison</p>
                            </div>}
                        </div>
                    }
                    {!context.getLaptop && buildingOptions && buildingOptions.length > 0 && buildingsInfo &&
                        <MultipleSelect reports={true} data={buildingOptions} dataType='categories' selectItem={handleFilterBuilding} selectedList={filteredBuildings} placeholder="Select Buildings" />

                    }
                </div>
                {
                    context.getLaptop &&
                    <div className="input-group-text flex items-center whitespace-nowrap cursor-pointer" onClick={() => setOpenFilters(!openFilters)}>
                        {GlobalSVG.menuExport()}
                        <p className="text-sm text-normal leading-4">Filter</p>
                    </div>
                }
            </div >

            {
                context.getLaptop && openFilters &&
                <div className={`${openFilters ? 'tablet:w-[300px] mobile:w-full p-3' : "w-0"} card alert-sidebar transition-width duration-300 ease-in-out h-full fixed right-0 tablet:top-[10] mobile:top-[10] z-40 background-white`}>
                    <div className="mt-8 relative flex flex-nowrap justify-center items-center w-full gap-3">
                        {buildingOptions && buildingOptions.length > 0 && buildingsInfo &&
                            <MultipleSelect reports={true} data={buildingOptions} dataType='categories' selectItem={handleFilterBuilding} selectedList={filteredBuildings} placeholder="Select Buildings" />

                        }
                    </div>
                    <div className='w-full mt-8'>
                        <DateRangePicker onChange={handleDateChange}
                            start={startEnd[0]}
                            end={startEnd[1]}
                            compare={compareOn}
                            onChangeCompare={handleDateCompare}
                            compareStart={compareStartEnd}
                            wrap={true} />
                    </div>
                    {!compareOn && <div className='flex flex-nowrap justify-center items-center m-2 mb-8 cursor-pointer' onClick={() => setCompareOn(true)}>
                        {GlobalSVG.pentagon()}
                        <p className='ml-2 font-medium text-[#587464]'>Compare Periods</p>
                    </div>}
                    {compareOn && <div className='flex flex-nowrap justify-center items-center m-2 mt-4 mb-8 cursor-pointer' onClick={() => setCompareOn(false)}>
                        {GlobalSVG.closeBig()}
                        <p className='ml-2 font-medium text-[#587464]'>Stop Comparison</p>
                    </div>}
                </div>
            }

            {buildingsInfo && ((compareOn && compareBuildingInfo) || !compareOn) &&
                <div className='tablet:px-12 mobile:p-3'>
                    <PortfolioGraph1 circuits={finalInfo}
                        unit={unit}
                        startEnd={startEnd}
                        compare={compareOn}
                        compareStartEnd={compareStartEnd}
                        setUnit={handleChangeUnit} />
                </div>}
            {buildingsInfo && ((compareOn && compareBuildingInfo) || !compareOn) &&
                <div className='tablet:px-12 mobile:p-3 mb-8'>
                    <PortfolioGraph2 circuits={finalInfo}
                        startEnd={startEnd}
                        compare={compareOn}
                        compareStartEnd={compareStartEnd}
                        setUnit={handleChangeUnit} />
                </div>}
            {(!buildingsInfo || ((compareOn && compareStartEnd && !compareBuildingInfo))) &&
                <div className='h-[70vh]'>
                    <p className='pt-8 text-center text-[#C1C1C1]'>Generating your report. This might take a few minutes...</p>
                    <Loader />
                </div>
            }
        </div>
    );
};

export default Report;