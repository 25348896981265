import { useEffect, useState } from "react";

const ThermoGraph = (props) => {

    const [style1, setStyle1] = useState(null)
    const [style2, setStyle2] = useState(null)

    useEffect(() => {
        if (!props) return
        setStyle1({ backgroundColor: `${props.color}`, width: `${props.percent}%` })
        setStyle2({ width: `${100 - props.percent}%` })
    }, [props])

    return (
        <div className='w-full relative h-[100px] flex flex-col items-center justify-center mb-[10px]'>
            <div className="absolute left-0 w-full z-[100]">
                <div className="flex flex-nowrap items-center justify-center rounded-full w-[100px] h-[100px] bg-[white] drop-shadow-md">
                    {props.icon}
                </div>
            </div>
            {style1 && style2 &&
                <div className='w-full flex flex-nowrap items-center justify-center z-[50]'>
                    <div className='w-[90px] bg-[white]'></div>
                    <div className="bg-[white] p-2 w-full rounded-tr-[35px] rounded-br-[35px] drop-shadow-md flex flex-nowrap items-center justify-center w-full">
                        <div style={style1} className={`h-[50px] ${props.percent === 100 ? "rounded-tr-[35px] rounded-br-[35px]" : ""}`}></div>
                        <div style={style2} className={`h-[50px] bg-[#50575C] rounded-tr-[35px] rounded-br-[35px]`}></div>
                    </div>
                </div>}
                {
                    <div className="absolute top-0 left-0 w-full h-full flex flex-nowrap items-center justify-center z-[100]">
                        <p className="absolute top-50 text-[32px] text-[white] font-extrabold">{props.percent}% {props.name}</p>
                    </div>
                }

        </div>
    )
};

export default ThermoGraph;