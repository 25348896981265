import {React} from "react";
import { CircularGaugeComponent, AxesDirective, AxisDirective, Inject, PointersDirective, PointerDirective, Annotations, AnnotationDirective, AnnotationsDirective, RangesDirective, RangeDirective } from '@syncfusion/ej2-react-circulargauge';

export default function ArcGauge(props) {
    
    function buildArrayWithTwoOutOfThree() {
        const array = [];
      
        for (let i = 0; i <= 100; i += 4) {
          array.push(i, i + 1);
        }
      
        return array;
      }

    const colorMap = {1: "#70F05B", 2: "#FFF96C", 3: "#F2DB61", 4: "#F35454"}

    function loadSix(args) {
    }
    return (
        <CircularGaugeComponent load={loadSix.bind(this)} background="transparent" width="180px" height="200px">
            <Inject services={[Annotations]} />
            <AxesDirective>
                <AxisDirective startAngle={220} endAngle={140} radius="80%" minimum={0} maximum={100} lineStyle={{ width: 0 }} majorTicks={{ height: 0, }} minorTicks={{ height: 0, }} labelStyle={{ position: 'Outside', font: { size: '0px', color: '#1E7145' }, }}>
                    <PointersDirective>
                        {props && !props.inactive ?
                            buildArrayWithTwoOutOfThree().map((e,ix)=>(
                                <PointerDirective key={e + ix + 'linegauge' + props.content} color={e + 1 < 20 ? colorMap[4] : e + 1 < 40 ? colorMap[3] : e + 1 < 60 ? colorMap[1] : e + 1 < 80 ? colorMap[3] : colorMap[4]} type="Marker" value={e} markerShape="Rectangle" markerWidth={22} markerHeight={4} animation={{ enable: true, }} />
                            ))
                            :
                            buildArrayWithTwoOutOfThree().map((e,ix)=>(
                                <PointerDirective key={e + ix + 'linegauge' + props.content} color="#B9B9B9" type="Marker" value={e} markerShape="Rectangle" markerWidth={28} markerHeight={4} animation={{ enable: true, }} />
                            ))
                        }
                        {
                            props&& !props.inactive &&
                            <PointerDirective color="#000000" type="Marker" value={props.percentage} markerShape="Rectangle" markerWidth={34} markerHeight={7} animation={{ enable: true, }} />
                        }
                    </PointersDirective>
                    <RangesDirective>
                        <RangeDirective start={0} end={100} startWidth={30} endWidth={30} color="#FFF" radius="115%" />
                    </RangesDirective>
                    <AnnotationsDirective>
                        <AnnotationDirective content={props.content} angle={-10} zIndex="1" radius="-26%" />
                    </AnnotationsDirective>
                </AxisDirective>
            </AxesDirective>
        </CircularGaugeComponent>
    );
}
