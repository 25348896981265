import React, { useState } from 'react';
import './style.css';
import GlobalSVG from '../../utils/GlobalSVG';


const AccordionItem = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  
  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={`w-[95%] rounded-xl p-5 accordion-item-shadow my-3`}>
      <div className={`flex flex-row flex-nowrap items-center justify-start cursor-pointer w-full`} onClick={handleToggle}>
        <button className="accordion-title text-left tablet:text-sm mobile:text-xs flex items-center w-full">
          <span className='text-3xl font-medium mr-5'>{props.title}</span>
          <span className='text-lg font-light mr-5'>{props.subtitle}</span>
        </button>
        {props.new &&
          <div className='py-2 px-3 text-[white] text-sm font-semibold rounded-3xl bg-[#385B46]'>
            NEW
          </div>}
        <svg className={`ml-2`} transform={`${isExpanded ? 'rotate(90)' : 'rotate(-90)'}`} width="16" height="16" viewBox="0 0 13 22" fill="#385B46" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.9683 1.30177C11.7987 1.13182 11.5972 0.996979 11.3754 0.90498C11.1536 0.81298 10.9159 0.765625 10.6758 0.765625C10.4357 0.765625 10.1979 0.81298 9.97613 0.90498C9.75435 0.996979 9.55288 1.13182 9.38328 1.30177L0.968277 9.71677C0.79832 9.88638 0.663483 10.0878 0.571484 10.3096C0.479484 10.5314 0.432129 10.7692 0.432129 11.0093C0.432129 11.2494 0.479484 11.4871 0.571484 11.7089C0.663483 11.9307 0.79832 12.1322 0.968277 12.3018L9.38328 20.7168C9.55301 20.8865 9.75451 21.0211 9.97628 21.113C10.198 21.2049 10.4357 21.2521 10.6758 21.2521C10.9158 21.2521 11.1535 21.2049 11.3753 21.113C11.597 21.0211 11.7985 20.8865 11.9683 20.7168C12.138 20.547 12.2726 20.3455 12.3645 20.1238C12.4564 19.902 12.5036 19.6643 12.5036 19.4243C12.5036 19.1842 12.4564 18.9465 12.3645 18.7248C12.2726 18.503 12.138 18.3015 11.9683 18.1318L4.85494 11.0001L11.9683 3.88677C12.6833 3.17177 12.6649 1.99844 11.9683 1.30177Z" fill="#385B46" />
        </svg>
      </div>
      {
        isExpanded && <div className='py-4' dangerouslySetInnerHTML={{ __html: props.content }}>

        </div>
      }
    </div>
  );
};

const Accordion2 = (props) => {

  return (
    <div className="bg-[white] h-[80vh] w-[80vw] flex flex-col rounded-3xl p-12">
      <div className="flex w-full justify-between">
        <h2 className='text-bold desktop:text-3xl tablet:text-xl mb-6'>{props.title}</h2>
        <div onClick={props.onClick} className='cursor-pointer'>
          {GlobalSVG.closeBig()}
        </div>
      </div>
      <div className='overflow-y-auto w-full h-full '>
        {props.content && props.content.map((item, index) => (
          <AccordionItem key={index} title={item.title} subtitle={item.subtitle} content={item.content} index={index} new={index === 0} />
        ))}
      </div>
    </div>
  );
};

export default Accordion2;
