import React from "react";
import './style.css'

function UnderMaintenance() {


    return (
        <div className="fixed z-50 top-0 left-0 w-full h-screen bg-white flex flex-col">
            <div className="login-background flex flex-col flex-grow items-center justify-between h-full">
                <img className='greenwave-logo mx-auto mt-8' src={require('../../assets/images/logo_full2.png')} alt="" />
                <div className="flex flex-col flex-nowrap justify-center items-center">
                    <img className='mx-auto mt-2 w-[600px]' src={require('../../assets/images/under_maintenance.png')} alt="" />
                    <p className="open-sans-bold text-[30px]">We are improving our application, please visit us again later.</p>
                </div>
                <div className="flex flex-col tablet:flex-row justify-center items-center w-full">
                    <img className='w-80 p-4 tablet:mr-5' src={require('../../assets/images/logo_full_green.png')} alt="" />
                    <p className="text-green text-xs mt-5 tablet:ml-5 text-center tablet:text-left">@ 2023 Greenwave Innovations. All Rights Reserved.</p>
                </div>
            </div>
        </div>
    );
}

export default UnderMaintenance;