
/**
 * 
 * @param {Object} obj 
 * @returns a deep copy of object
 */
export const deepCopy = (obj) => {
    let outObject, value, key
    if (typeof obj !== "object" || obj === null) {
        return obj // Return the value if obj is not an object
    }
    // Create an array or object to hold the values
    outObject = Array.isArray(obj) ? [] : {}
    for (key in obj) {
        value = obj[key]
        // Recursively (deep) copy for nested objects, including arrays
        outObject[key] = deepCopy(value)
    }
    return outObject
}

/**
 * 
 * @param {Object} obj 
 * @returns true if the object/array is empty or null, else false
 */
export const isEmpty = (obj) => {
    if (obj == null) return true;
    return Object.keys(obj).length === 0;
}
