import './style.css'
import GlobalSVG from '../../utils/GlobalSVG';

export default function UnitToggle(props) {

    return (
        <div className="flex flex-nowrap justify-start items-center m-2 mt-3 w-full" >
            <div className='flex flex-nowrap justify-start w-2/3'>
                <div className={`toggle-left cursor-pointer w-1/2 ${props.options === props.option1 ? 'active' : ''}`} onClick={() => props.setOptions(props.option1)}>
                    {
                        props.options === props.option1 &&
                        GlobalSVG.checkSmall()
                    }
                    <span className='ml-2'>{props.button1}</span>
                </div>
                <div className={`toggle-right cursor-pointer w-1/2 ${props.options === props.option2 ? 'active' : ''}`} onClick={() => props.setOptions(props.option2)}>
                    <span className='mr-2'>{props.button2}</span>
                    {
                        props.options === props.option2 &&
                        GlobalSVG.checkSmall()
                    }
                </div>
            </div>
            {/* <div className='flex justify-center mr-2'>
                <label
                    className="inline-block pl-[0.15rem] hover:cursor-pointer"
                    htmlFor={props.id}>
                    <p className="font-body font-semibold line-height line-height text-sans text-[#332D41] md:decoration-dashed">{props.name}</p>
                </label>
            </div> */}
        </div>
    );
};