import React, { useMemo } from 'react';
import { useState, useEffect, useContext } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from "../Loader";
import './style.css'
import API from '../../utils/API';
import Swal from 'sweetalert2';
import SelectOrType from '../SelectOrType';
import SlideToggle from '../SlideToggle';
import GlobalFuncs from '../../utils/GlobalFuncs';

const ChannelTab = (props) => {

    const context = useContext(AppContext)
    const location = useLocation();
    const [distributionOptions, setDistributionOptions] = useState([])
    const [parentOptions, setParentOptions] = useState([])
    const [coOptions, setCoOptions] = useState([])
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const [eOptions, setEOptions] = useState([])
    const [gOptions, setGOptions] = useState([])
    const [wOptions, setWOptions] = useState([])
    const [availableChannels, setAvailableChannels] = useState()
    const navigate = useNavigate();


    useEffect(() => {
        if (props.getPutPost === 'put' && props.selectedChannel.channel_name.trim() === "") props.setGetPutPost('post')
        else if (props.getPutPost === 'put') props.setSelectedChannel(prev => ({ ...prev, old_name: prev.channel_name }))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.getPutPost])

    useEffect(() => {
        let taken = { electric: [], digital: [] }
        for (let item of props.channelOptions) {
            if ("phases" in item) {
                for (let phase of Object.values(item.phases)) {
                    try {
                        let chan = parseInt(phase)
                        taken[item.type].push(chan)
                    } catch (e) {
                        continue
                    }
                }
            } else {
                taken[item.type].push(item.channel)
            }
        }

        let initial = { electric: [], digital: [] }
        if (!props.selectedChannel || !props.selectedChannel.type) return
        let range = props.virtual ? 200 : (props.selectedChannel && props.selectedChannel.type === 'digital' ? 3 : 18)
        for (let i = 1; i <= range; i++) {
            if (props.virtual) {
                if ([].concat(...Object.values(taken)).includes(i)) continue
            } else {
                if (taken[props.selectedChannel.type].includes(i)) continue
            }
            initial[props.selectedChannel.type].push(i);
        }
        setAvailableChannels(initial)

    }, [props.channelOptions, props.virtual, props.selectedChannel])

    useEffect(() => {

        if (!context.getUser || !props.deviceData || !props.deviceData.building_id) return
        const cancelToken = API.cancelToken();
        const auth = context.getUser.token;
        API.getBuilding(cancelToken, auth, props.deviceData.building_id).then(res => {
            let buildingData = res.data
            let tempDistOptions = []
            let parentOption = []
            if (props.selectedChannel && props.selectedChannel.type === 'electric') {
                tempDistOptions = buildingData.e_channels.map(e => e.building_distribution)
                parentOption = buildingData.e_channels.map(e => e.channel_name)
            } else if (props.selectedChannel && props.selectedChannel.type === 'digital') {
                let tempChans = buildingData.g_channels.concat(buildingData.w_channels)
                tempDistOptions = tempChans.filter(e => e.utility_type === props.selectedChannel.utility_type).map(e => e.building_distribution)
                parentOption = tempChans.filter(e => e.utility_type === props.selectedChannel.utility_type).map(e => e.channel_name)
            }
            tempDistOptions = tempDistOptions.filter(e => e && e.trim() !== '')
            setDistributionOptions(Array.from(new Set(tempDistOptions)))
            setParentOptions(Array.from(new Set(parentOption)))
        }).catch(err => {
            console.log(err)
            if (err.message === 'cancelling') return
            if (err.response && err.response.data) {
                try {
                    if (err.response.data.includes('error')) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data.error,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    }
                } catch (e) {
                    Swal.fire({
                        title: 'Error.',
                        text: err.response.data,
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            } else {
                Swal.fire({
                    title: 'Error.',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }

        })
    }, [props.deviceData, context.getUser, props.selectedChannel])

    useEffect(() => {
        const cancelToken = API.cancelToken();
        const auth = context.getUser.token;
        API.getModelsE(cancelToken, auth).then(res => {
            setEOptions(res.data)
        }).catch(err => {
            console.log(err)
            if (err.message === 'cancelling') return
            if (err.response && err.response.data) {
                try {
                    if (err.response.data.includes('error')) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data.error,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    }
                } catch (e) {
                    Swal.fire({
                        title: 'Error.',
                        text: err.response.data,
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            } else {
                Swal.fire({
                    title: 'Error.',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }

        })
        API.getModelsG(cancelToken, auth).then(res => {
            setGOptions(res.data)
        }).catch(err => {
            console.log(err)
            if (err.message === 'cancelling') return
            if (err.response && err.response.data) {
                try {
                    if (err.response.data.includes('error')) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data.error,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    }
                } catch (e) {
                    Swal.fire({
                        title: 'Error.',
                        text: err.response.data,
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            } else {
                Swal.fire({
                    title: 'Error.',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }
        })
        API.getModelsW(cancelToken, auth).then(res => {
            setWOptions(res.data)
        }).catch(err => {
            console.log(err)
            if (err.message === 'cancelling') return
            if (err.response && err.response.data) {
                try {
                    if (err.response.data.includes('error')) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data.error,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    }
                } catch (e) {
                    Swal.fire({
                        title: 'Error.',
                        text: err.response.data,
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            } else {
                Swal.fire({
                    title: 'Error.',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }
        })
        API.getModelsCo(cancelToken, auth).then(res => {
            setCoOptions(res.data)
        }).catch(err => {
            console.log(err)
            if (err.message === 'cancelling') return
            if (err.response && err.response.data) {
                try {
                    if (err.response.data.includes('error')) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data.error,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    }
                } catch (e) {
                    Swal.fire({
                        title: 'Error.',
                        text: err.response.data,
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            } else {
                Swal.fire({
                    title: 'Error.',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }
        })
    }, [context.getUser])

    const handleSelectedChannel = (e) => {
        let link = queryParams.get('building') && queryParams.get('building') !== null && queryParams.get('building') !== "" ?
            `/device?device=${props.deviceData.device_id}&tab=channels&channel=${GlobalFuncs.removeSpecialCharacters(e.currentTarget.value)}&building=${queryParams.get('building')}` :
            `/device?device=${props.deviceData.device_id}&tab=channels&channel=${GlobalFuncs.removeSpecialCharacters(e.currentTarget.value)}`
        navigate(link)
    }

    const updateChannel = (e) => {
        const { name, value } = e.target;
        if (name && name.includes('phase')) {
            let phase = name.split("_")[1]
            props.setSelectedChannel(prevValue => {
                return {
                    ...prevValue,
                    phases: { ...prevValue.phases, [phase]: value }
                }
            })
        } else if (name === 'image' || name === 'rep_image') {
            const selectedImages = e.target.files;
            if (selectedImages) {
                const reader = new FileReader();
                reader.onload = function (e) {
                    const base64ImageData = e.target.result.split(',')[1];
                    props.setSelectedChannel(prevValue => {
                        return {
                            ...prevValue,
                            [name]: base64ImageData
                        }
                    })
                };

                reader.readAsDataURL(selectedImages);
            }
        } else if (e.target.type === 'checkbox') {
            const { name, checked } = e.target;
            props.setSelectedChannel(prevValue => {
                return {
                    ...prevValue,
                    [name]: checked
                }
            })
        } else {
            props.setSelectedChannel(prevValue => {
                return {
                    ...prevValue,
                    [name]: value
                }
            })
        }
    }

    return (
        <div className='device'>
            {props.selectedChannel &&
                <div className='min-h-[80vh]'>
                    <div className='card tablet:p-8 mobile:p-3 min-h-[80vh] mb-6'>
                        <div className='input-border pb-8 items-center mb-6'>
                            <div className='flex flex-wrap w-full items-center justify-between'>
                                {props.getPutPost !== "get" &&
                                    <input
                                        placeholder='Channel Name'
                                        className='p-1 text-3xl p-2 outline-none hide-input-background laptop:w-3/12 tablet:w-full mobile:w-full'
                                        type="text"
                                        name='channel_name'
                                        disabled={props.getPutPost === "get"}
                                        onChange={updateChannel}
                                        value={props.selectedChannel && props.selectedChannel.channel_name ? props.selectedChannel.channel_name : ""} />
                                }
                                {props.getPutPost === "get" &&
                                    <select
                                        className='p-1 laptop:w-4/12 tablet:w-1/2 mobile:w-full mr-2 text-base input-color p-2 outline-none hide-input-background'
                                        name="channel_name"
                                        value={props.selectedChannel && props.selectedChannel.channel_name ? props.selectedChannel.channel_name.trim() : ""}
                                        onChange={handleSelectedChannel}>
                                        <option value="null">No Selection</option>
                                        {props.channelOptions && props.channelOptions.map((e, idx) => (
                                            <option key={idx + 'channeldata'} value={e.channel_name.trim()}>{e.channel_name.trim()}</option>
                                        ))}
                                    </select>
                                }
                                {
                                    props.error && props.error.channel_name &&
                                    <p className='text-red text-xs p-2'>{props.error.channel_name}</p>
                                }
                                {props.getPutPost === 'post' &&
                                    <div className='flex flex-wrap items-center w-1/3'>
                                        <label className='laptop:w-1/5 tablet:w-full mobile:w-full text-base input-label-color' htmlFor="breaker_label">Type</label>
                                        <select
                                            className='p-1 laptop:w-6/12 tablet:w-1/2 mobile:w-full mr-2 text-base input-color p-2 outline-none hide-input-background'
                                            name="type"
                                            value={props.selectedChannel.type ? props.selectedChannel.type.trim() : ""}
                                            onChange={updateChannel}>
                                            <option value="null">No Selection</option>
                                            <option value="electric">Electric</option>
                                            <option value="digital">Digital</option>
                                        </select>
                                    </div>
                                }
                                <div className='laptop:w-1/12 tablet:w-1/2 mobile:w-full m-2'>
                                    <label className='text-base input-label-color pr-2' htmlFor="virtual">Virtual</label>
                                    <input
                                        className='w-2/12'
                                        disabled={true}
                                        name="virtual"
                                        type="checkbox"
                                        checked={props.selectedChannel.virtual || props.deviceData.device_type === 'virtual'}
                                        onChange={updateChannel} />
                                </div>
                            </div>
                        </div>
                        {
                            props.loading &&
                            <Loader />
                        }
                        {props.selectedChannel && props.selectedChannel.type === 'electric' &&
                            <div className='flex flex-wrap'>
                                <div className='input-border flex flex-wrap w-full items-center pt-3 pb-3 mb-6'>
                                    <div className='laptop:w-1/2 tablet:w-1/2 mobile:w-full flex flex-wrap items-center'>
                                        <label className='laptop:w-2/5 tablet:w-full mobile:w-full text-base input-label-color' htmlFor="breaker_label">Breaker or Disconnected Label</label>
                                        <input
                                            placeholder='Breaker Label'
                                            className='p-1 laptop:w-7/12 tablet:w-full mobile:w-full text-base input-color outline-none hide-input-background'
                                            type="text"
                                            name="breaker_label"
                                            value={props.selectedChannel.breaker_label ? props.selectedChannel.breaker_label : ""}
                                            disabled={props.getPutPost === 'get'}
                                            onChange={updateChannel} />
                                        {
                                            props.error && props.error.breaker_label &&
                                            <p className='text-red text-xs'>{props.error.breaker_label}</p>

                                        }
                                    </div>
                                    <div className='laptop:w-1/2 tablet:w-1/2 mobile:w-full flex flex-wrap items-center'>
                                        <label className='laptop:w-2/5 tablet:w-full mobile:w-full text-base input-label-color' htmlFor="breaker_nr">Breaker # or Disconnected</label>
                                        <input
                                            placeholder='Breaker Number'
                                            className='p-1 laptop:w-7/12 tablet:w-full mobile:w-full text-base input-color outline-none hide-input-background'
                                            type="number"
                                            max="99"
                                            name="breaker_nr"
                                            value={props.selectedChannel.breaker_nr ? props.selectedChannel.breaker_nr : ""}
                                            disabled={props.getPutPost === 'get'}
                                            onChange={updateChannel} />
                                        {
                                            props.error && props.error.breaker_nr &&
                                            <p className='text-red text-xs'>{props.error.breaker_nr}</p>

                                        }
                                    </div>
                                </div>
                            </div>}
                        {props.selectedChannel && props.selectedChannel.type === 'electric' &&
                            <div className='flex flex-wrap'>
                                <div className='input-border flex flex-wrap w-full items-center pt-3 pb-3 mb-6'>
                                    <div className='laptop:w-1/3 tablet:w-1/3 mobile:w-full flex flex-wrap items-center'>
                                        <label className='w-2/5 text-base input-label-color' htmlFor="phase_A">Phase A</label>
                                        <select
                                            className='p-1 w-5/12 mr-2 text-base input-color outline-none hide-input-background'
                                            name="phase_A"
                                            value={props.selectedChannel.phases && props.selectedChannel.phases.A ? props.selectedChannel.phases.A : ""}
                                            disabled={props.getPutPost !== 'post'}
                                            onChange={updateChannel}>
                                            <option value="">No Selection</option>
                                            {
                                                ['put', 'get'].includes(props.getPutPost) && props.selectedChannel && props.selectedChannel.phases && props.selectedChannel.phases.A &&
                                                <option value={props.selectedChannel.phases.A}>{props.selectedChannel.phases.A}</option>
                                            }
                                            {
                                                availableChannels && availableChannels[props.selectedChannel.type].map((e, idx) => (
                                                    <option key={idx + 'availchan'} value={e} >{e}</option>
                                                ))
                                            }
                                        </select>
                                        {
                                            props.error && props.error.phase_A &&
                                            <p className='text-red text-xs'>{props.error.phase_A}</p>

                                        }
                                    </div>
                                    <div className='laptop:w-1/3 tablet:w-1/3 mobile:w-full flex flex-wrap items-center'>
                                        <label className='w-2/5 text-base input-label-color' htmlFor="phase_B">Phase B</label>
                                        <select
                                            className='p-1 w-5/12 mr-2 text-base input-color outline-none hide-input-background'
                                            name="phase_B"
                                            value={props.selectedChannel.phases && props.selectedChannel.phases.B ? props.selectedChannel.phases.B : ""}
                                            disabled={props.getPutPost !== 'post'}
                                            onChange={updateChannel}>
                                            <option value="">No Selection</option>
                                            {['put', 'get'].includes(props.getPutPost) && props.selectedChannel && props.selectedChannel.phases && props.selectedChannel.phases.B &&
                                                <option value={props.selectedChannel.phases.B}>{props.selectedChannel.phases.B}</option>}
                                            {availableChannels && availableChannels[props.selectedChannel.type].map((e, idx) => (
                                                <option key={idx + 'availchan'} value={e} >{e}</option>
                                            ))}
                                        </select>
                                        {
                                            props.error && props.error.phase_B &&
                                            <p className='text-red text-xs'>{props.error.phase_B}</p>

                                        }
                                    </div>
                                    <div className='laptop:w-1/3 tablet:w-1/3 mobile:w-full flex flex-wrap items-center'>
                                        <label className='w-2/5 text-base input-label-color' htmlFor="phase_C">Phase C</label>
                                        <select
                                            className='p-1 w-5/12 mr-2 text-base input-color outline-none hide-input-background'
                                            name="phase_C"
                                            value={props.selectedChannel.phases && props.selectedChannel.phases.C ? props.selectedChannel.phases.C : ""}
                                            disabled={props.getPutPost !== 'post'}
                                            onChange={updateChannel}>
                                            <option value="">No Selection</option>
                                            {['put', 'get'].includes(props.getPutPost) && props.selectedChannel && props.selectedChannel.phases && props.selectedChannel.phases.C &&
                                                <option value={props.selectedChannel.phases.C}>{props.selectedChannel.phases.C}</option>}
                                            {availableChannels && availableChannels[props.selectedChannel.type].map((e, idx) => (
                                                <option key={idx + 'availchan'} value={e} >{e}</option>
                                            ))}
                                        </select>
                                        {
                                            props.error && props.error.phase_C &&
                                            <p className='text-red text-xs'>{props.error.phase_C}</p>

                                        }
                                    </div>
                                </div>
                            </div>}
                        {props.selectedChannel && props.selectedChannel.type === 'electric' &&
                            <div className='flex flex-wrap'>
                                <div className='input-border flex flex-wrap w-full items-center pt-3 pb-3 mb-6'>
                                    <div className='laptop:w-1/3 tablet:w-1/3 mobile:w-full flex flex-wrap items-center'>
                                        <label className='w-2/5 text-base input-label-color' htmlFor="breaker_amps">Breaker Size</label>
                                        <input
                                            placeholder='Breaker Size'
                                            className='p-1 laptop:w-5/12 tablet:w-full mobile:w-full text-base input-color outline-none hide-input-background'
                                            type="number"
                                            name="breaker_amps"
                                            value={props.selectedChannel.breaker_amps ? props.selectedChannel.breaker_amps : ""}
                                            disabled={props.getPutPost === 'get'}
                                            onChange={updateChannel} />
                                        {
                                            props.error && props.error.breaker_amps &&
                                            <p className='text-red text-xs'>{props.error.breaker_amps}</p>

                                        }
                                    </div>
                                    <div className='laptop:w-1/3 tablet:w-1/3 mobile:w-full flex flex-wrap items-center'>
                                        <label className='w-2/5 text-base input-label-color' htmlFor="ct_size">CT Size</label>
                                        <input
                                            placeholder='CT Size'
                                            className='p-1 laptop:w-5/12 tablet:w-full mobile:w-full text-base input-color outline-none hide-input-background'
                                            type="number"
                                            name="ct_size"
                                            value={props.selectedChannel.ct_size ? props.selectedChannel.ct_size : ""}
                                            disabled={props.getPutPost === 'get'}
                                            onChange={updateChannel} />
                                        {
                                            props.error && props.error.ct_size &&
                                            <p className='text-red text-xs'>{props.error.ct_size}</p>

                                        }
                                    </div>
                                    <div className='laptop:w-1/3 tablet:w-1/3 mobile:w-full flex flex-wrap items-center'>
                                        <label className='w-2/5 text-base input-label-color' htmlFor="phase_c">Submonitored</label>
                                        {
                                            <select
                                                className='p-1 w-2/5 mr-2 text-base input-color outline-none hide-input-background'
                                                name="sub_monitored"
                                                value={props.selectedChannel.sub_monitored ? props.selectedChannel.sub_monitored : ""}
                                                disabled={props.getPutPost === 'get'}
                                                onChange={updateChannel}>
                                                <option value="">No Selection</option>
                                                <option value="Completely">Completely</option>
                                                <option value="No">No</option>
                                                <option value="Partially">Partially</option>
                                            </select>
                                        }
                                        {
                                            props.error && props.error.sub_monitored &&
                                            <p className='text-red text-xs'>{props.error.sub_monitored}</p>

                                        }
                                    </div>
                                </div>
                            </div>}
                        <div className='flex flex-wrap'>
                            {distributionOptions &&
                                <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                                    <div className='w-full flex flex-wrap items-center'>
                                        <label className='laptop:w-4/12 mobile:w-1/2 tablet:w-2/5 text-base input-label-color' >Building Distribution</label>
                                        <SelectOrType
                                            options={distributionOptions}
                                            disabled={props.getPutPost === 'get'}
                                            placeholder={props.selectedChannel.building_distribution ? props.selectedChannel.building_distribution : "Building Distribution"}
                                            name='building_distribution'
                                            onChange={updateChannel} />
                                        {
                                            props.error && props.error.building_distribution &&
                                            <p className='text-red text-xs'>{props.error.building_distribution}</p>

                                        }
                                    </div>
                                </div>}
                            <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                                <div className='w-full flex flex-wrap items-center'>
                                    <label className='laptop:w-4/12 mobile:w-1/2 tablet:w-2/5 text-base input-label-color' >Parent Distribution</label>
                                    <select
                                        className='p-1 mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color outline-none hide-input-background'
                                        name={props.selectedChannel.type === 'electric' ? "channel_subpanel" : "channel_parent"}
                                        value={props.selectedChannel.type === 'electric' ?
                                            props.selectedChannel.channel_subpanel :
                                            props.selectedChannel.channel_parent}
                                        disabled={props.getPutPost === 'get'}
                                        onChange={updateChannel}>
                                        <option value="">No Selection</option>
                                        {parentOptions && parentOptions.filter(e => props.selectedChannel.channel_name !== e).map((e, idx) => (
                                            <option key={idx + 'parentoptions'} value={e}>{e}</option>
                                        ))}
                                    </select>
                                    {
                                        props.error && (props.error.channel_subpanel || props.error.channel_parent) &&
                                        <p className='text-red text-xs'>{props.error.channel_subpanel || props.error.channel_parent}</p>

                                    }
                                </div>
                            </div>
                        </div>
                        {props.selectedChannel.type === 'digital' &&
                            <div className='flex flex-wrap'>
                                <div className='input-border flex flex-wrap w-full items-center pt-3 pb-3 mb-6'>

                                    <div className='laptop:w-1/2 tablet:w-1/2 mobile:w-full flex flex-wrap items-center'>
                                        <label className='laptop:w-4/12 mobile:w-1/2 tablet:w-2/5 text-base input-label-color' >Utility Type</label>
                                        <select
                                            className='p-1 mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color outline-none hide-input-background'
                                            name="utility_type"
                                            value={props.selectedChannel.utility_type ? props.selectedChannel.utility_type : ""}
                                            disabled={props.getPutPost === 'get'}
                                            onChange={updateChannel}>
                                            <option value="">No Selection</option>
                                            <option value="Natural Gas">Natural Gas</option>
                                            <option value="Water">Water</option>
                                        </select>
                                        {
                                            props.error && props.error.utility_type &&
                                            <p className='text-red text-xs'>{props.error.utility_type}</p>

                                        }
                                    </div>
                                    <div className='laptop:w-1/2 tablet:w-1/2 mobile:w-full flex flex-wrap items-center'>
                                        <label className='laptop:w-4/12 mobile:w-1/2 tablet:w-2/5 text-base input-label-color' >Input Unit</label>
                                        <select
                                            className='p-1 mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color outline-none hide-input-background'
                                            name="input_unit"
                                            value={props.selectedChannel.input_unit ? props.selectedChannel.input_unit : ""}
                                            disabled={props.getPutPost === 'get'}
                                            onChange={updateChannel}>
                                            <option value="">No Selection</option>
                                            <option value="l.">L</option>
                                            <option value="ft^3">ft³</option>
                                        </select>
                                        {
                                            props.error && props.error.input_unit &&
                                            <p className='text-red text-xs'>{props.error.input_unit}</p>

                                        }
                                    </div>
                                </div>
                            </div>
                        }
                        <div className='flex flex-wrap'>
                            <div className='input-border flex flex-wrap w-full items-center pt-3 pb-3 mb-6'>
                                {props.selectedChannel && props.selectedChannel.type === 'electric' &&
                                    <div className='laptop:w-1/3 tablet:w-1/3 mobile:w-full flex flex-wrap items-center'>
                                        <label className='w-2/5 text-base input-label-color' htmlFor="factor">Factor</label>
                                        <input
                                            placeholder='Factor'
                                            className='p-1 laptop:w-5/12 tablet:w-full mobile:w-full text-base input-color outline-none hide-input-background'
                                            type="number"
                                            name="factor"
                                            value={props.selectedChannel.factor ? props.selectedChannel.factor : ""}
                                            disabled={props.getPutPost === 'get'}
                                            onChange={updateChannel} />
                                        {
                                            props.error && props.error.factor &&
                                            <p className='text-red text-xs'>{props.error.factor}</p>
                                        }
                                    </div>}
                                {props.selectedChannel.utility_type &&
                                    <div className='laptop:w-1/3 tablet:w-1/4 mobile:w-full flex flex-wrap items-center'>
                                        <label className='laptop:w-4/12 mobile:w-1/2 tablet:w-2/5 text-base input-label-color' >Channel</label>
                                        <select
                                            className='p-1 w-2/5 mr-2 text-base input-color outline-none hide-input-background'
                                            name="channel"
                                            value={props.selectedChannel.channel ? props.selectedChannel.channel : ""}
                                            disabled={props.getPutPost !== 'post'}
                                            onChange={updateChannel}>
                                            <option value="">No Selection</option>
                                            {
                                                props.selectedChannel && props.selectedChannel.channel &&
                                                <option value={props.selectedChannel.channel}>{props.selectedChannel.channel}</option>
                                            }
                                            {
                                                availableChannels && availableChannels[props.selectedChannel.type].map((e, idx) => (
                                                    <option key={idx + 'availchan'} value={e} >{e}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                }
                                {props.selectedChannel.utility_type &&
                                    <div className='laptop:w-1/3 tablet:w-1/3 mobile:w-full flex flex-wrap items-center'>
                                        <label className='w-2/5 text-base input-label-color' htmlFor="scale">Scale</label>
                                        <input
                                            placeholder='Scale'
                                            className='p-1 laptop:w-5/12 tablet:w-full mobile:w-full text-base input-color outline-none hide-input-background'
                                            type="number"
                                            name="scale"
                                            value={props.selectedChannel.scale ? props.selectedChannel.scale : ""}
                                            disabled={props.getPutPost === 'get'}
                                            onChange={updateChannel} />
                                        {
                                            props.error && props.error.scale &&
                                            <p className='text-red text-xs'>{props.error.scale}</p>
                                        }
                                    </div>
                                }
                                {props.selectedChannel && props.selectedChannel.type === 'electric' &&
                                    <div className='laptop:w-1/3 tablet:w-1/3 mobile:w-full flex flex-wrap items-center'>
                                        <SlideToggle titleClass="input-label-color" disabled={props.getPutPost === 'get'} setOptions={props.setSelectedChannel} options={props.selectedChannel} id="phasing_verified" name="Phasing Verified" />
                                    </div>}
                                <div className='laptop:w-1/3 tablet:w-1/3 mobile:w-full flex flex-wrap items-center'>
                                    <SlideToggle titleClass="input-label-color" disabled={props.getPutPost === 'get'} setOptions={props.setSelectedChannel} options={props.selectedChannel} id="source" name="Source" />
                                </div>
                            </div>
                        </div>
                        {props.selectedChannel && ["1", 1, true].includes(props.selectedChannel.source) &&
                            <div className='flex flex-wrap'>
                                {((props.selectedChannel.type && props.selectedChannel.type === 'electric') ||
                                    (props.selectedChannel.utility_type === 'Natural Gas')) &&
                                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                                        <div className='w-full flex flex-wrap items-center'>
                                            <label className='laptop:w-4/12 mobile:w-1/2 tablet:w-2/5 text-base input-label-color' >CO Model</label>
                                            <select
                                                className='p-1 mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color outline-none hide-input-background'
                                                name="co_model"
                                                value={props.selectedChannel.co_model ? props.selectedChannel.co_model : ""}
                                                disabled={props.getPutPost === 'get'}
                                                onChange={updateChannel}>
                                                <option value="">No Selection</option>
                                                {coOptions && coOptions.map((e, idx) => (
                                                    <option key={idx + 'cooptions'} value={e.model_id}>{e.model_name}</option>
                                                ))}
                                            </select>
                                            {
                                                props.error && props.error.co_model &&
                                                <p className='text-red text-xs'>{props.error.co_model}</p>

                                            }
                                        </div>
                                    </div>}
                                {props.selectedChannel && props.selectedChannel.type === 'electric' &&
                                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                                        <div className='w-full flex flex-wrap items-center'>
                                            <label className='laptop:w-4/12 mobile:w-1/2 tablet:w-2/5 text-base input-label-color' >Cost Model</label>
                                            <select
                                                className='p-1 mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color outline-none hide-input-background'
                                                name="cost_model"
                                                value={props.selectedChannel.cost_model ? props.selectedChannel.cost_model : ""}
                                                disabled={props.getPutPost === 'get'}
                                                onChange={updateChannel}>
                                                <option value="">No Selection</option>
                                                {eOptions && eOptions.map((e, idx) => (
                                                    <option key={idx + 'cooptions'} value={e.model_id}>{e.model_name}</option>
                                                ))}
                                            </select>
                                            {
                                                props.error && props.error.cost_model &&
                                                <p className='text-red text-xs'>{props.error.cost_model}</p>

                                            }
                                        </div>
                                    </div>}
                                {props.selectedChannel.utility_type &&
                                    <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                                        <div className='w-full flex flex-wrap items-center'>
                                            <label className='laptop:w-4/12 mobile:w-1/2 tablet:w-2/5 text-base input-label-color' >Cost Model</label>
                                            <select
                                                className='p-1 mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color outline-none hide-input-background'
                                                name="cost_model"
                                                value={props.selectedChannel.cost_model ? props.selectedChannel.cost_model : ""}
                                                disabled={props.getPutPost === 'get'}
                                                onChange={updateChannel}>
                                                <option value="">No Selection</option>
                                                {props.selectedChannel.utility_type === 'Water' && wOptions && wOptions.map((e, idx) => (
                                                    <option key={idx + 'cooptions'} value={e.model_id}>{e.model_name}</option>
                                                ))}
                                                {props.selectedChannel.utility_type === 'Natural Gas' && gOptions && gOptions.map((e, idx) => (
                                                    <option key={idx + 'cooptions'} value={e.model_id}>{e.model_name}</option>
                                                ))}
                                            </select>
                                            {
                                                props.error && props.error.cost_model &&
                                                <p className='text-red text-xs'>{props.error.cost_model}</p>

                                            }
                                        </div>
                                    </div>}
                            </div>
                        }
                    </div>
                </div>}
        </div >
    );
};

export default ChannelTab;