import './style.css'
import { useState, useEffect, useRef, useContext } from 'react';
import Loader from "../Loader";
import GlobalSVG from '../../utils/GlobalSVG';
import { HighchartsReact } from 'highcharts-react-official';
import Highcharts from "highcharts/highstock";
import GraphFuncs from '../../utils/GraphFuncs';
import { AppContext } from '../../utils/ContextProvider';
import API from '../../utils/API';
import Swal from 'sweetalert2';
import UnitToggle from '../UnitToggle';
import GlobalFuncs from '../../utils/GlobalFuncs';
import moment from 'moment';

export default function ReportGraph2(props) {

    const [stacked, setStacked] = useState(true)
    const [options, setOptions] = useState()
    const [currentData, setCurrentData] = useState(null)
    const [loading, setLoading] = useState(true)
    const [percent, setPercent] = useState(false)
    const [open, setOpen] = useState(true)
    const chartRef = useRef(null);
    const context = useContext(AppContext);

    const [unit, setUnit] = useState('price')
    const unitDict = { kwh: "kWh", kva: "kVA", price: "$", generation: "kWh", co: "t" }

    // const [openFilters, setOpenFilters] = useState(false)
    const [finalCircuit, setFinalCircuit] = useState([])
    const [finalCircuitCompare, setFinalCircuitCompare] = useState([])

    const [categories, setCategories] = useState(null)
    // const [opHours, setOpHours] = useState([])
    const [baseCircuits, setBaseCircuits] = useState(null)

    const getMonthRanges = (startDate, endDate) => {
        const start = moment(startDate)
        const end = moment(endDate)

        const monthRanges = [];

        while (start.isSameOrBefore(end, 'day')) {
            const currentMonth = start.month();
            const currentYear = start.year();

            const monthStart = moment([currentYear, currentMonth]);
            const monthEnd = monthStart.clone().endOf('month');

            const rangeStart = start.isBefore(monthStart) ? start : monthStart;
            const rangeEnd = end.isBefore(monthEnd) ? end : monthEnd;

            monthRanges.push([rangeStart.format('YYYY-MM-DD'), rangeEnd.format('YYYY-MM-DD')]);

            start.add(1, 'month').startOf('month');
        }
        return monthRanges;
    }


    useEffect(() => {
        if (!context.getBuildingInfo || !props.startEnd || props.compare) return
        let monthsDict = {}
        let ranges = getMonthRanges(props.startEnd[0], props.startEnd[1])
        ranges.forEach(e => {
            monthsDict[e[0]] = { range: e }
        })

        let mainCircuit = GraphFuncs.findCirctuitInHierarchy('Main Distribution', context.getBuildingInfo.hierarchy)

        let tempCats = context.getBuildingInfo.e_channels.map(e => e.category)
        tempCats = Array.from(new Set(tempCats))
        tempCats = tempCats.filter(item => !['Source', 'Building Distribution', 'Capacitor Bank', null].includes(item));
        const newArray = [...tempCats]
        setCategories(newArray)

        const auth = context.getUser.token;
        const cancelToken = API.cancelToken();

        const getPeakBatchAsync = async () => {

            for (let month in monthsDict) {
                try {
                    let start = monthsDict[month].range[0]
                    let end = monthsDict[month].range[1]


                    // Get Sources
                    monthsDict[month].generation = 0
                    let sources = context.getBuildingInfo.e_sources
                    sources.push(mainCircuit)
                    const cancelToken = API.cancelToken();
                    for (let circuit of sources) {
                        if (!circuit || (circuit.channels[0].category !== 'Building Distribution' && circuit.channels[0].sub_category === 'Utility')) continue
                        let res = await API.getElectricity(cancelToken, auth, start, end, circuit.device_id, 60, 'kw', circuit.circuit_name)
                        monthsDict[month].generation += Object.values(res.data.series).reduce((sum, value) => sum + value, 0)
                    }

                    let co = await API.getPriceCoElectric(cancelToken, auth, start, end, mainCircuit.circuit_name, mainCircuit.device_id, 60)
                    co = co.data
                    let kwhCat = await API.getCatConsumption(cancelToken, start, end, context.getBuilding)
                    kwhCat = kwhCat.data
                    let totalKwh = kwhCat.total
                    delete kwhCat.total
                    delete kwhCat["Capacitor Bank"]

                    monthsDict[month].kwh = kwhCat

                    let peak = await GraphFuncs.getPeakKva(cancelToken, auth, mainCircuit, start, end)
                    let tempPeak = {}
                    let tempCo = {}
                    let tempPrice = {}
                    if (Object.keys(peak)[0] !== 'null') {
                        let peakBatch = await API.getPeakBatch(cancelToken, auth, context.getBuilding, Object.keys(peak)[0])
                        peakBatch = peakBatch.data
                        let priceTotal = await API.getPriceE(cancelToken, auth, start, end, mainCircuit.circuit_name, mainCircuit.device_id)
                        priceTotal = priceTotal.data

                        for (let circuit in peakBatch) {
                            let channelInfo = context.getBuildingInfo.e_channels.filter(e => e.channel_name.trim() === circuit)
                            let category = "Undefined"
                            if (channelInfo.length !== 0) {
                                category = channelInfo[0].category
                            }
                            tempPeak[category] = tempPeak[category] || 0
                            tempPeak[category] += peakBatch[circuit]
                        }

                        monthsDict[month].kva = tempPeak

                        for (let cat of tempCats) {
                            let tempOb = {}
                            // CO
                            tempCo[cat] = (kwhCat[cat] / co.kwh_consumption) * co.co_consumption

                            // Price start
                            tempOb.kwh_consumption_dollar = (kwhCat[cat] / totalKwh) * priceTotal.kwh_consumption_dollar
                            tempOb.carbon_charge_dollar = (kwhCat[cat] / totalKwh) * priceTotal.carbon_charge_dollar
                            tempOb.kva_consumption_dollar = (tempPeak[cat] / Object.values(peak)[0]) * priceTotal.kva_consumption_dollar

                            // Get ratio for taxes
                            let ratioOn = ((tempOb.kwh_consumption_dollar + tempOb.carbon_charge_dollar + tempOb.kva_consumption_dollar) /
                                (priceTotal.kwh_consumption_dollar + priceTotal.carbon_charge_dollar + priceTotal.kva_consumption_dollar))

                            // Add taxes and calculate total
                            tempOb.municipal_tax_dollar = ratioOn * priceTotal.municipal_tax_dollar
                            tempOb.pst_dollar = ratioOn * priceTotal.pst_dollar
                            tempOb.gst_dollar = ratioOn * priceTotal.gst_dollar
                            tempOb.total = tempOb.kwh_consumption_dollar + tempOb.carbon_charge_dollar + tempOb.kva_consumption_dollar + tempOb.municipal_tax_dollar + tempOb.pst_dollar + tempOb.gst_dollar
                            tempPrice[cat] = tempOb.total
                        }

                        monthsDict[month].co = tempCo
                        monthsDict[month].price = tempPrice
                        monthsDict[month].peak = Object.keys(peak)[0]
                    }
                } catch (e) {
                    console.log(e)
                    if (e.message === 'cancelling') return
                    Swal.fire({
                        title: 'Error.',
                        text: 'Please try again later.',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            }
            setBaseCircuits(monthsDict)
            setFinalCircuit(monthsDict)
        }

        getPeakBatchAsync()

        return () => {
            API.cancel(cancelToken);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.getBuildingInfo, props.startEnd])


    useEffect(() => {
        if (!props.compareStartEnd || !baseCircuits) return

        if (!props.compare) {
            setFinalCircuit(baseCircuits)
            return
        }

        let monthsDict = {}
        let ranges = getMonthRanges(props.compareStartEnd[0], props.compareStartEnd[1])
        ranges.forEach(e => {
            monthsDict[e[0]] = { range: e }
        })

        let mainCircuit = GraphFuncs.findCirctuitInHierarchy('Main Distribution', context.getBuildingInfo.hierarchy)

        const auth = context.getUser.token;
        const cancelToken = API.cancelToken();

        const getPeakBatchAsync = async () => {
            for (let month in monthsDict) {
                try {
                    let start = monthsDict[month].range[0]
                    let end = monthsDict[month].range[1]

                    // Get Sources
                    monthsDict[month].generation = 0
                    let sources = context.getBuildingInfo.e_sources
                    sources.push(mainCircuit)
                    const cancelToken = API.cancelToken();
                    for (let circuit of sources) {
                        let tempCircuit = JSON.parse(JSON.stringify(circuit));
                        if (!circuit || (circuit.channels[0].category !== 'Building Distribution' && circuit.channels[0].sub_category === 'Utility')) continue
                        let res = await API.getElectricity(cancelToken, auth, start, end, tempCircuit.device_id, 60, 'kw', tempCircuit.circuit_name)
                        res.data.circuit_name = tempCircuit.circuit_name
                        monthsDict[month].generation += Object.values(res.data.series).reduce((sum, value) => sum + value, 0)
                    }

                    let co = await API.getPriceCoElectric(cancelToken, auth, start, end, mainCircuit.circuit_name, mainCircuit.device_id, 60)
                    co = co.data
                    let kwhCat = await API.getCatConsumption(cancelToken, start, end, context.getBuilding)
                    kwhCat = kwhCat.data
                    let totalKwh = kwhCat.total
                    delete kwhCat.total
                    delete kwhCat["Capacitor Bank"]

                    monthsDict[month].kwh = kwhCat
                    let tempPeak = {}
                    let tempCo = {}
                    let tempPrice = {}

                    let peak = await GraphFuncs.getPeakKva(cancelToken, auth, mainCircuit, start, end)
                    if (Object.keys(peak)[0] !== 'null') {
                        let peakBatch = await API.getPeakBatch(cancelToken, auth, context.getBuilding, Object.keys(peak)[0])
                        peakBatch = peakBatch.data

                        let priceTotal = await API.getPriceE(cancelToken, auth, start, end, mainCircuit.circuit_name, mainCircuit.device_id)
                        priceTotal = priceTotal.data

                        for (let circuit in peakBatch) {
                            let channelInfo = context.getBuildingInfo.e_channels.filter(e => e.channel_name.trim() === circuit)
                            let category = "Undefined"
                            if (channelInfo.length !== 0) {
                                category = channelInfo[0].category
                            }
                            tempPeak[category] = tempPeak[category] || 0
                            tempPeak[category] += peakBatch[circuit]
                        }

                        monthsDict[month].kva = tempPeak

                        for (let cat of categories) {
                            let tempOb = {}
                            // CO
                            tempCo[cat] = (kwhCat[cat] / co.kwh_consumption) * co.co_consumption

                            // Price start
                            tempOb.kwh_consumption_dollar = (kwhCat[cat] / totalKwh) * priceTotal.kwh_consumption_dollar
                            tempOb.carbon_charge_dollar = (kwhCat[cat] / totalKwh) * priceTotal.carbon_charge_dollar
                            tempOb.kva_consumption_dollar = (tempPeak[cat] / Object.values(peak)[0]) * priceTotal.kva_consumption_dollar

                            // Get ratio for taxes
                            let ratioOn = ((tempOb.kwh_consumption_dollar + tempOb.carbon_charge_dollar + tempOb.kva_consumption_dollar) /
                                (priceTotal.kwh_consumption_dollar + priceTotal.carbon_charge_dollar + priceTotal.kva_consumption_dollar))

                            // Add taxes and calculate total
                            tempOb.municipal_tax_dollar = ratioOn * priceTotal.municipal_tax_dollar
                            tempOb.pst_dollar = ratioOn * priceTotal.pst_dollar
                            tempOb.gst_dollar = ratioOn * priceTotal.gst_dollar
                            tempOb.total = tempOb.kwh_consumption_dollar + tempOb.carbon_charge_dollar + tempOb.kva_consumption_dollar + tempOb.municipal_tax_dollar + tempOb.pst_dollar + tempOb.gst_dollar
                            tempPrice[cat] = tempOb.total
                        }
                        monthsDict[month].co = tempCo
                        monthsDict[month].price = tempPrice
                        monthsDict[month].peak = Object.keys(peak)[0]
                    }
                } catch (e) {
                    console.log(e)
                    if (e.message === 'cancelling') return
                    Swal.fire({
                        title: 'Error.',
                        text: 'Please try again later.',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            }
            setFinalCircuitCompare(monthsDict)
        }

        getPeakBatchAsync()

        return () => {
            API.cancel(cancelToken);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [baseCircuits, props.compareStartEnd, props.compare])


    useEffect(() => {
        let colorDict = ['#588A6D', '#DD6B20', '#FFD233', '#92C4A7', '#A6ABAD', '#17AEEE', '#A22', '#B2D8C3']
        if (!finalCircuit || finalCircuit.length === 0 || (props.compare && (!finalCircuitCompare || finalCircuitCompare.length === 0)) || !categories) return
        setLoading(true)
        let series = []
        let index = 0
        if (!stacked) {
            for (let cat of categories) {
                let catSeries = []
                let monthIndex = 0
                for (let month in finalCircuit) {
                    switch (unit) {
                        case "price": default:
                            catSeries.push({ name: formatDate(month), y: finalCircuit[month].price && cat in finalCircuit[month].price ? parseInt(finalCircuit[month].price[cat]) : 0, generation: parseInt(finalCircuit[month].generation) })
                            if (props.compare) catSeries.push({ name: formatDate(Object.keys(finalCircuitCompare)[monthIndex]), y: Object.values(finalCircuitCompare)[monthIndex].price && cat in Object.values(finalCircuitCompare)[monthIndex].price ? parseInt(Object.values(finalCircuitCompare)[monthIndex].price[cat]) : 0, generation: parseInt(Object.values(finalCircuitCompare)[monthIndex].generation) })
                            if (props.compare) catSeries.push({ name: Object.keys(finalCircuitCompare)[monthIndex] + '!@#$DFWEG%T', y: null })
                            break
                        case "kva":
                            catSeries.push({ name: GlobalFuncs.get15MinRange(finalCircuit[month].peak) + " - " + finalCircuit[month].peak.split(" ")[1], y: finalCircuit[month].kva && cat in finalCircuit[month].kva ? parseInt(finalCircuit[month].kva[cat]) : 0, generation: parseInt(finalCircuit[month].generation) })
                            if (props.compare) catSeries.push({ name: GlobalFuncs.get15MinRange(finalCircuitCompare[Object.keys(finalCircuitCompare)[monthIndex]].peak) + " - " + finalCircuitCompare[Object.keys(finalCircuitCompare)[monthIndex]].peak.split(" ")[1], y: Object.values(finalCircuitCompare)[monthIndex].kva && cat in Object.values(finalCircuitCompare)[monthIndex].kva ? parseInt(Object.values(finalCircuitCompare)[monthIndex].kva[cat]) : 0, generation: parseInt(Object.values(finalCircuitCompare)[monthIndex].generation) })
                            if (props.compare) catSeries.push({ name: Object.keys(finalCircuitCompare)[monthIndex] + '!@#$DFWEG%T', y: null })
                            break
                        case "kwh":
                            catSeries.push({ name: formatDate(month), y: finalCircuit[month].kwh && cat in finalCircuit[month].kwh ? parseInt(finalCircuit[month].kwh[cat]) : 0, generation: parseInt(finalCircuit[month].generation) })
                            if (props.compare) catSeries.push({ name: formatDate(Object.keys(finalCircuitCompare)[monthIndex]), y: Object.values(finalCircuitCompare)[monthIndex].kwh && cat in Object.values(finalCircuitCompare)[monthIndex].kwh ? parseInt(Object.values(finalCircuitCompare)[monthIndex].kwh[cat]) : 0, generation: parseInt(Object.values(finalCircuitCompare)[monthIndex].generation) })
                            if (props.compare) catSeries.push({ name: Object.keys(finalCircuitCompare)[monthIndex] + '!@#$DFWEG%T', y: null })
                            break
                        case "co":
                            catSeries.push({ name: formatDate(month), y: finalCircuit[month].co && cat in finalCircuit[month].co ? parseInt(finalCircuit[month].co[cat]) : 0, generation: parseInt(finalCircuit[month].generation) })
                            if (props.compare) catSeries.push({ name: formatDate(Object.keys(finalCircuitCompare)[monthIndex]), y: Object.values(finalCircuitCompare)[monthIndex].co && cat in Object.values(finalCircuitCompare)[monthIndex].co ? parseInt(Object.values(finalCircuitCompare)[monthIndex].co[cat]) : 0, generation: parseInt(Object.values(finalCircuitCompare)[monthIndex].generation) })
                            if (props.compare) catSeries.push({ name: Object.keys(finalCircuitCompare)[monthIndex] + '!@#$DFWEG%T', y: null })
                            break
                        case "generation":
                            catSeries.push({ name: formatDate(month), y: parseInt(finalCircuit[month].generation) })
                            if (props.compare) catSeries.push({ name: formatDate(Object.keys(finalCircuitCompare)[monthIndex]), y: parseInt(Object.values(finalCircuitCompare)[monthIndex].generation) })
                            if (props.compare) catSeries.push({ name: Object.keys(finalCircuitCompare)[monthIndex] + '!@#$DFWEG%T', y: null })
                            break
                    }
                    monthIndex++
                }
                let dl = {
                    enabled: true,
                    formatter: function () {
                        return percent ? Highcharts.numberFormat(this.percentage, 0) + "%" : Highcharts.numberFormat(this.y, 0);
                    },
                    verticalAlign: 'center',
                    // y: -300,
                    style: {
                        fontSize: '12px',
                        color: 'black',
                        textOutline: 'none'
                    }
                }
                if (catSeries.some(e => e.y)) {
                    series.push(
                        {
                            name: cat,
                            data: catSeries,
                            color: colorDict[index],
                            dataLabels: dl
                        }
                    )
                }
                index++
                if (unit === 'generation') break
            }
        } else {
            let catSeries = []
            let monthIndex = 0
            for (let month in finalCircuit) {
                if (unit === 'generation') {
                    catSeries.push({ name: formatDate(month), y: parseInt(finalCircuit[month].generation) })
                } else {
                    let total = 0
                    for (let cat of categories) {
                        switch (unit) {
                            case "price": default:
                                finalCircuit[month].price && cat in finalCircuit[month].price && finalCircuit[month].price[cat] ? total += parseFloat(finalCircuit[month].price[cat].toFixed(2)) : total += 0
                                break
                            case "kva":
                                finalCircuit[month].kva && cat in finalCircuit[month].kva && finalCircuit[month].kva[cat] ? total += parseFloat(finalCircuit[month].kva[cat].toFixed(2)) : total += 0
                                break
                            case "kwh":
                                finalCircuit[month].kwh && cat in finalCircuit[month].kwh && finalCircuit[month].kwh[cat] ? total += parseFloat(finalCircuit[month].kwh[cat].toFixed(2)) : total += 0
                                break
                            case "co":
                                finalCircuit[month].co && cat in finalCircuit[month].co && finalCircuit[month].co[cat] ? total += parseFloat(finalCircuit[month].co[cat].toFixed(2)) : total += 0
                                break
                        }
                    }
                    catSeries.push({ name: unit === 'kva' ? GlobalFuncs.get15MinRange(finalCircuit[month].peak) + " - " + finalCircuit[month].peak.split(" ")[1] : formatDate(month), y: parseInt(total) })
                }
                if (props.compare) {
                    if (unit === 'generation') {
                        catSeries.push({ name: formatDate(Object.keys(finalCircuitCompare)[monthIndex]) + "(C)", y: parseInt(Object.values(finalCircuitCompare)[monthIndex].generation) })
                        catSeries.push({ name: '', y: null })
                    } else {
                        let total = 0
                        for (let cat of categories) {
                            switch (unit) {
                                case "price": default:
                                    Object.values(finalCircuitCompare)[monthIndex] && Object.values(finalCircuitCompare)[monthIndex].price && cat in Object.values(finalCircuitCompare)[monthIndex].price && Object.values(finalCircuitCompare)[monthIndex].price[cat] ? total += parseFloat(Object.values(finalCircuitCompare)[monthIndex].price[cat].toFixed(2)) : total += 0
                                    break
                                case "kva":
                                    Object.values(finalCircuitCompare)[monthIndex] && Object.values(finalCircuitCompare)[monthIndex].kva && cat in Object.values(finalCircuitCompare)[monthIndex].kva && Object.values(finalCircuitCompare)[monthIndex].kva[cat] ? total += parseFloat(Object.values(finalCircuitCompare)[monthIndex].kva[cat].toFixed(2)) : total += 0
                                    break
                                case "kwh":
                                    Object.values(finalCircuitCompare)[monthIndex] && Object.values(finalCircuitCompare)[monthIndex].kwh && cat in Object.values(finalCircuitCompare)[monthIndex].kwh && Object.values(finalCircuitCompare)[monthIndex].kwh[cat] ? total += parseFloat(Object.values(finalCircuitCompare)[monthIndex].kwh[cat].toFixed(2)) : total += 0
                                    break
                                case "co":
                                    Object.values(finalCircuitCompare)[monthIndex] && Object.values(finalCircuitCompare)[monthIndex].co && cat in Object.values(finalCircuitCompare)[monthIndex].co && Object.values(finalCircuitCompare)[monthIndex].co[cat] ? total += parseFloat(Object.values(finalCircuitCompare)[monthIndex].co[cat].toFixed(2)) : total += 0
                                    break
                            }
                        }
                        catSeries.push({ name: unit === 'kva' ? GlobalFuncs.get15MinRange(finalCircuitCompare[Object.keys(finalCircuitCompare)[monthIndex]].peak) + " - " + finalCircuitCompare[Object.keys(finalCircuitCompare)[monthIndex]].peak.split(" ")[1] : formatDate(Object.keys(finalCircuitCompare)[monthIndex]) + "(C)", y: total })
                        catSeries.push({ name: Object.keys(finalCircuitCompare)[monthIndex] + '!@#$DFWEG%T', y: null })
                    }
                }
                monthIndex++
            }
            let dl = {
                enabled: true,
                formatter: function () {
                    return percent ? Highcharts.numberFormat(this.percentage, 0) + "%" : unit === 'price' ? this.y.toLocaleString('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 }) : this.y.toLocaleString('en-US');
                },
                verticalAlign: 'center',
                style: {
                    fontSize: '12px',
                    color: 'black',
                    textOutline: 'none'
                }
            }

            series.push(
                {
                    name: "Total",
                    data: catSeries,
                    color: colorDict[index],
                    dataLabels: dl
                }
            )
        }


        setCurrentData(series)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [finalCircuit, unit, categories, stacked, finalCircuitCompare, percent])

    const formatDate = (inputDate) => {
        if (!inputDate) return inputDate
        const dateParts = inputDate.split('-');
        const year = dateParts[0];
        const month = dateParts[1];

        const monthNames = [
            'January', 'February', 'March', 'April',
            'May', 'June', 'July', 'August',
            'September', 'October', 'November', 'December'
        ];
        const formattedMonth = monthNames[parseInt(month, 10) - 1];

        const formattedDate = `${formattedMonth} ${year}`;

        return formattedDate;
    }

    useEffect(() => {
        if (!currentData || currentData.length === 0) return
        setOptions({
            chart: {
                type: 'column'
            },
            credits: {
                enabled: false,
            },
            legend: {
                enabled: true,
                align: 'left',
                layout: 'horizontal',
                verticalAlign: 'bottom',
                itemMarginBottom: 0,
                itemMarginTop: 0,
            },
            title: {
                text: '',
                align: 'left'
            },
            subtitle: {
                text: '',
                align: 'left'
            },
            xAxis: {
                type: 'category',
                min: 0,
                max: !props.compare ? currentData[0].data.length < 12 ? currentData[0].data.length - 1 : 11 : currentData[0].data.length < 24 ? currentData[0].data.length - 2 : 23,
                accessibility: {
                    description: 'Circuits'
                },
                scrollbar: {
                    enabled: true
                },
                events: {
                    setExtremes: function (e) {
                        if (e.max >= this.dataMax || !e.max) {
                            return false
                        }
                        if (e.min < 1 && e.max < 1) return false
                    },
                    afterSetExtremes: function (e) {
                        if (e.min < 0 && e.max < 5) this.setExtremes(0, 5)
                        else if (e.min < 0) this.setExtremes(0, e.max)
                    }
                },
                labels: {
                    enabled: true, // Enable labels for other points
                    formatter: function (e) {
                        return `${e.value}`.includes("!@#$DFWEG%T") ? "" : e.value
                    },
                },
            },
            yAxis: {
                title: {
                    align: 'high',
                    offset: 0,
                    text: '',
                    rotation: 0,
                    y: 10,
                },
                labels: {
                    formatter: function () {
                        return this.value;
                    }
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                },
            },
            series: currentData,
            exporting: {
                enabled: true,
                buttons: {
                    contextButton: {
                        menuItems: [
                            'viewFullscreen', 'separator', 'downloadCSV', 'downloadPNG', 'downloadPDF'
                        ]
                    },
                },
            }
        })
        setLoading(false)
        props.setComplete(e => e + "2")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentData])

    const handleChangeUnit = (e) => {
        setUnit(e.currentTarget.value)
    }


    return (
        <div>
            <div className={`${"card tablet:p-8"} rounded-lg relative mt-8`}>
                <h2 className="capitalize w-full laptop:text-3xl tablet:text-xl font-bold text-[#332D41] md:decoration-dashed laptop:p-0 tablet:p-8 mobile:p-8">Monthly Consumption</h2>
                {props.startEnd && <p className='mb-4 font-light text-sm'>Date Range: <span className='font-normal'>{moment(props.startEnd[0]).format("MMM DD, YYYY")} - {moment(props.startEnd[1]).format("MMM DD, YYYY")} ({GraphFuncs.getDifferenceInDays(props.startEnd[0], props.startEnd[1])} days)</span></p>}
                {props.compare && props.compareStartEnd.length === 2 && <p className='mb-4 font-light text-sm'>Comparative Range: <span className='font-normal'>{moment(props.compareStartEnd[0]).format("MMM DD, YYYY")} - {moment(props.compareStartEnd[1]).format("MMM DD, YYYY")} ({GraphFuncs.getDifferenceInDays(props.compareStartEnd[0], props.compareStartEnd[1])} days)</span></p>}
                <div className="flex flex-nowrap tablet:justify-end laptop:justify-start mobile:justify-end items-center rounded-lg min-h-[400px]">
                    <div className={`${open ? 'laptop:w-3/12 tablet:w-1/2 mobile:w-full' : 'w-0'} side-card tablet:p-5 mobile:p-4 flex-col z-10 laptop:static tablet:absolute mobile:absolute tablet:left-0 tablet:top-0 mobile:left-0 mobile:top-0 laptop:min-h-[400px] laptop:h-auto tablet:h-full mobile:h-full backround-white tablet:[card] transition-width duration-300 ease-in-out`}>
                        {open && <div className='flex flex-col justify-between laptop:min-h-[400px] laptop:h-auto tablet:h-full mobile:h-full mr-3'>
                            <div className='flex flex-col justify-start'>
                                {(unit === 'kwh' || unit === 'co') && <h2 className='mb-2 font-medium mt-4'>Building Total</h2>}
                                {unit === 'kwh' && <p>{props.compare && props.realPeakCompare ? parseInt(props.virtualTotalCompare).toLocaleString() : parseInt(props.virtualTotal).toLocaleString()} kWh</p>}
                                {unit === 'kva' && <h2 className='mb-2 font-medium mt-4'>Building Peak</h2>}
                                {unit === 'kva' && props.realPeak && <p>{props.compare && props.realPeakCompare ? parseInt(Object.values(props.realPeak)[0] - Object.values(props.realPeakCompare)[0]).toLocaleString() : parseInt(Object.values(props.realPeak)[0]).toLocaleString()} kVA</p>}
                                {unit === 'kva' && <h2 className='mb-2 font-medium mt-4'>Reached At</h2>}
                                {unit === 'kva' && <p className='text-sm'>{GlobalFuncs.get15MinRange(Object.keys(props.realPeak)[0])} - {Object.keys(props.realPeak)[0].split(" ")[1]}</p>}
                                {unit === 'kva' && props.compare && props.realPeakCompare && <h2 className='mb-2 font-medium mt-4'>And</h2>}
                                {unit === 'kva' && props.compare && props.realPeakCompare && <p className='text-sm'>{GlobalFuncs.get15MinRange(Object.keys(props.realPeakCompare)[0])} - {Object.keys(props.realPeakCompare)[0].split(" ")[1]}</p>}
                                {unit === 'co' && <p>{props.compare && props.realPeakCompare ? parseInt(props.totalCoCompare - props.totalCo).toLocaleString() : parseInt(props.totalCo).toLocaleString()} t</p>}
                                <div>
                                    <div className=" grid grid-col justify-self-center">
                                        <h2 className='mb-2 font-medium mt-4'>Type</h2>
                                        <select className='w-1/2 p-2 border border-1 rounded  border-[#C1C1C1] text-[#377745] w-[220px]' value={unit} onChange={handleChangeUnit}>
                                            <option value="price">Utility Costs</option>
                                            <option value="generation">Source</option>
                                            <option value="kva">Electrical Demand</option>
                                            <option value="kwh">Electrical Consumption</option>
                                            <option value="co">Emissions</option>
                                        </select>
                                    </div>
                                </div>
                                <br />
                                <UnitToggle setOptions={setStacked} options={stacked} option1={true} option2={false} button1="Total" button2="Categories" />
                                <UnitToggle setOptions={setPercent} options={percent} option1={true} option2={false} button1="Percentage" button2="Amount" />
                            </div>
                            <div className='mt-4 flex flex-nowrap justify-start items-center cursor-pointer' onClick={() => setOpen(false)}>
                                <div className='border border-light-green rounded-full w-8 h-8 flex flex-nowrap items-center justify-center'>
                                    {GlobalSVG.leftArrow()}
                                </div>
                                <p className='ml-3 cursor-pointer'>
                                    Hide options
                                </p>
                            </div>
                        </div>}
                        {
                            !open &&
                            <div className={`${open ? 'w-2/12' : 'items-center'} flex flex-col justify-end h-full`}>
                                <div onClick={() => setOpen(true)} className='cursor-pointer border border-light-green rounded-full tablet:w-8 tablet:h-8 mobile:w-6 mobile:h-6  flex flex-nowrap items-center justify-center'>
                                    {GlobalSVG.rightArrow()}
                                </div>
                            </div>
                        }
                    </div>
                    <div className={`col-span-2 ${open ? 'w-9/12' : 'laptop:w-full mobile:w-11/12'}`}>
                        {unitDict[unit]}
                        {options && <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartRef}
                        />}
                        {loading && <Loader />}
                    </div>
                </div>
            </div >
        </div>
    )
};