import React from "react";
import "./style.css";

export default function ContextMenu(props) {

  const handleCollapsed = () => {
    props.node.data.collapsed = !props.node.data.collapsed;
    if (props.node.data.collapsed) props.collapseNode(props.node);
    else props.expandNode(props.node);
    props.setClickedNode(null);
    closeMenu()
  }

  const handleCircuit = (url) => {
    const newTab = window.open(url, '_blank');
    newTab.focus();
  }

  const closeMenu = () => {
    props.setShow(false);
    return;
  };

  return (
    <div className="fixed w-full h-full z-[999] overflow-hidden" onClick={closeMenu}>
      <div
        id="context-menu"
        className={`fixed z-[1000] w-[150px] bg-[#FFF] border rounded`}
        style={{ top: props.position.y, left: props.position.x }}
      >
        <div className="item" onClick={handleCollapsed}>
          {props.node && props.node.data && props.node.data.collapsed ? "Expand" : "Collapse"} Node
        </div>
        {props.node && props.node.data &&  ['circuit', 'gas', 'water'].includes(props.node.data.dataType) &&
          <div className="item" onClick={() => handleCircuit(`/circuit?device=${props.node.data.device}&circuit=${encodeURIComponent(props.node.data.name)}&tab=1`)}>
            View Circuit
          </div>}
      </div>
    </div>
  );
}
