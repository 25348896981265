import React from 'react';
import { useState, useEffect, useContext, useMemo } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import Loader from "../../components/Loader";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import './style.css'
import API from '../../utils/API';
import Swal from 'sweetalert2';
import GlobalSVG from '../../utils/GlobalSVG';
import DeviceTab from '../../components/DeviceTab';
import ChannelTab from '../../components/ChannelTab';
import EquipmentTab from '../../components/EquipmentTab';
import GlobalFuncs from '../../utils/GlobalFuncs';

const Device = () => {

    const location = useLocation();
    const [device, setDevice] = useState(null)
    const [getPutPost, setGetPutPost] = useState()
    const [currentTab, setCurrentTab] = useState()
    const [virtual, setVirtual] = useState()
    const [deviceData, setDeviceData] = useState({
        device_id: "",
        device_name: "",
        building_id: "",
        device_type: "",
        ip_address: "",
        internal_ip: "",
        username: "",
        password: "",
        remote_access_URL: "",
        location: "",
        connection_type: "",
        meter_pwrd_by_breaker_no: "",
        installed_by: "",
        monitored_distribution_panel: "",
        phase_voltage: "",
        device_model: "",
        mac_address: "",
        network_dhcp: false,
        firmware_version: "",
        firmware_date: "",
        installed_date: "",
        created_at: "",
        updated_at: "",
        commissioned_by: "",
        commissioned_date: "",
        e_channels: [],
        d_channels: []
    })
    const channelTemplate = {
        device_id: "",
        device_name: "",
        device_type: "",
        active: true,
        poles: "",
        utility_type: "",
        input_unit: "",
        scale: "",
        ct_size: "",
        phases: { A: "", B: "", C: "" },
        breaker_nr: "",
        breaker_amps: "",
        circuit_type: "",
        channel_name: "",
        category: "",
        sub_category: "",
        channel_subpanel: "",
        channel_parent: "",
        sub_monitored: "",
        source: false,
        natural_gas_user: false,
        equipment_group: "",
        equipment_model: "",
        end_use_location: "",
        location: "",
        operating_load_threshold: "",
        average_operating_consumption: "",
        virtual: "",
        factor: "",
        building_distribution: "",
        breaker_label: "",
        phasing_verified: false,
        notes: "",
        created_at: "",
        updated_at: "",
        cost_model: null,
        co_model: null,
        id: null
    }
    const [selectedChannel, setSelectedChannel] = useState(channelTemplate)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const context = useContext(AppContext)
    const navigate = useNavigate();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const tabs = ['device', 'channels', 'equipment']
    const [channelOptions, setChannelOptions] = useState([])

    useEffect(() => {
        if (!context.getUserInfo) return
        let access = ["access_buildings_and_operators_list", "create_buildings_and_operators", "update_buildings_and_operators"]
        if (!access.some(item => context.getUserInfo[item] === true)) {
            navigate('/settings/buildings')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.getUserInfo])

    useEffect(() => {
        let tempOptions = []
        if (!virtual && deviceData.e_channels.length > 0) {
            tempOptions = tempOptions.concat(deviceData.e_channels.map(e => ({ ...e, type: 'electric' })))
        }
        if (!virtual && deviceData.d_channels.length > 0) {
            tempOptions = tempOptions.concat(deviceData.d_channels.map(e => ({ ...e, type: 'digital' })))
        }
        if (virtual) {
            tempOptions = deviceData.d_channels.map(e => ({ ...e, type: 'digital' })).concat(deviceData.e_channels.map(e => ({ ...e, type: 'electric' })))
        }
        if (tempOptions.length > 0) {
            setChannelOptions(tempOptions)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceData, virtual])

    useEffect(() => {
        if (['put', 'post'].includes(getPutPost)) return
        if (location.pathname === '/device/create') {
            setGetPutPost('post')
            setCurrentTab('device')
        } else if (queryParams.size !== 0) {
            let tempDevice = queryParams.get('device')
            let tempTab = queryParams.get('tab')
            if (!tempDevice) navigate('/settings/devices')
            if (!tempTab) navigate(`/device?device=${tempDevice}&tab=device`)
            setDevice(queryParams.get('device'))
            setCurrentTab(queryParams.get('tab'))
            setGetPutPost('get')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams, location])

    useEffect(() => {
        if (channelOptions.length === 0) return
        if (queryParams.get('channel') === 'null') {
            setSelectedChannel(channelTemplate)
        } else if (queryParams.get('channel') !== '') {
            setSelectedChannel(channelOptions.find(c => GlobalFuncs.removeSpecialCharacters(c.channel_name) === GlobalFuncs.removeSpecialCharacters(queryParams.get('channel'))))
        } else {
            setSelectedChannel(channelOptions[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [channelOptions, queryParams])

    useEffect(() => {
        if (!context.getUser) return
        const cancelToken = API.cancelToken();
        const auth = context.getUser.token;
        if (getPutPost === 'get' && device !== null) {
            setLoading(true)
            API.getDevice(cancelToken, auth, device).then(res => {
                let tempDeviceData = res.data

                if (tempDeviceData.e_channels.length > 0) {
                    let tempDict = {}
                    for (let channel of tempDeviceData.e_channels) {
                        if (!(channel.channel_name.trim() in tempDict)) {
                            tempDict[channel.channel_name.trim()] = channel
                            tempDict[channel.channel_name.trim()].phases = {}
                        }
                        tempDict[channel.channel_name.trim()].phases[channel.phase] = String(channel.channel)
                    }
                    tempDeviceData.e_channels = Object.values(tempDict)
                }
                setLoading(false)
                setDeviceData(tempDeviceData)
                if (tempDeviceData.device_type.toLowerCase() === 'virtual') setVirtual(true)
                return
            }).catch(err => {
                setLoading(false)
                console.log(err)
                if (err.message === 'cancelling') return
                let message = 'Please try again later.'
                if (err.response && err.response.data && err.response.data.includes('error')) {
                    message = err.response.data.error
                }
                Swal.fire({
                    title: 'Error.',
                    text: message,
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A'
                });

            })
        }

        return () => {
            API.cancel(cancelToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPutPost, device, context.getUser])

    const updateDevice = (e) => {
        if (e.target.type === 'checkbox') {
            const { name, checked } = e.target;
            setDeviceData(prevValue => {
                return {
                    ...prevValue,
                    [name]: checked
                }
            })
        } else {
            const { name, value } = e.target;
            setDeviceData(prevValue => {
                return {
                    ...prevValue,
                    [name]: value
                }
            })
        }
    }

    const handlePost = async (e) => {
        if (currentTab === 'device') postDevice()
        else postChannel()
    }

    const postDevice = async (e) => {
        let mandatory = ['building_id', 'device_id', 'device_type']
        let tempErrors = {}
        for (let item of mandatory) {
            if (!(item in deviceData) || deviceData[item].trim() === '') {
                tempErrors[item] = 'Missing value'
            }
        }
        if (Object.values(tempErrors).length > 0) {
            setError(tempErrors)
            return
        }

        setLoading(true)

        let payload = JSON.parse(JSON.stringify(deviceData))
        const toDelPost = ['d_channels', 'e_channels', 'created_at', 'updated_at']
        const toDelPut = ['d_channels', 'e_channels', 'created_at', 'updated_at', 'device_id']
        let toDel = toDelPut
        if (getPutPost === 'post') {
            toDel = toDelPost
        }
        for (let item of toDel) {
            delete payload[item];
        }
        payload.commissioned_date = payload.commissioned_date ? payload.commissioned_date.split("T")[0] : ""
        payload.installed_date = payload.installed_date ? payload.installed_date.split("T")[0] : ""
        payload.firmware_date = payload.firmware_date ? payload.firmware_date.split("T")[0] : ""

        if (getPutPost === 'put') {
            delete payload['device_id'];
        }
        for (let key in payload) {
            if (payload[key] === null) {
                delete payload[key];
            } else if (typeof payload[key] === "string") {
                payload[key] = String(payload[key]).trim()
            }
        }

        const cancelToken = API.cancelToken();
        const auth = context.getUser.token;

        try {
            if (getPutPost === 'post') {
                await API.postDevice(cancelToken, auth, payload)
                navigate(`/device?device=${payload.device_id}&tab=device&channel=${GlobalFuncs.removeSpecialCharacters(payload.channel_name)}`)
                setGetPutPost('get')
            } else {
                await API.putDevice(cancelToken, auth, device, payload)
                setGetPutPost('get')
                setError(null)
            }
            setLoading(false)
        } catch (err) {
            console.log(err)
            if (err.message === 'cancelling') return
            if (err.response && err.response.data) {
                try {
                    if (err.response.data.includes('error')) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data.error,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    } else {
                        setError(err.response.data)
                    }
                } catch (e) {
                    Swal.fire({
                        title: 'Error.',
                        text: err.response.data,
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            } else {
                Swal.fire({
                    title: 'Oops!',
                    text: "An error occurred, please contact us.",
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }
            setLoading(false)
        }
    }

    const postChannel = async (e) => {
        let tempErrors = {}
        if (getPutPost === 'post' && selectedChannel.type === 'electric') {
            if (selectedChannel.phases.A.trim() === "" && selectedChannel.phases.B.trim() === "" && selectedChannel.phases.C.trim() === "") {
                tempErrors.phase_A = "Invalid value"
                tempErrors.phase_B = "Invalid value"
                tempErrors.phase_C = "Invalid value"
            }
            if (selectedChannel.phases.A.trim() === selectedChannel.phases.B.trim() && selectedChannel.phases.A.trim() !== "") {
                tempErrors.phase_A = "Invalid value"
                tempErrors.phase_B = "Invalid value"
            }
            if (selectedChannel.phases.A.trim() === selectedChannel.phases.C.trim() && selectedChannel.phases.A.trim() !== "") {
                tempErrors.phase_A = "Invalid value"
                tempErrors.phase_C = "Invalid value"
            }
            if (selectedChannel.phases.C.trim() === selectedChannel.phases.B.trim() && selectedChannel.phases.B.trim() !== "") {
                tempErrors.phase_C = "Invalid value"
                tempErrors.phase_B = "Invalid value"
            }
        }

        if (Object.values(tempErrors).length > 0) {
            setError(tempErrors)
            return
        }

        setLoading(true)
        let payload
        let tempType = 'electric'
        if (selectedChannel.type !== 'electric') tempType = selectedChannel['utility_type']
        let toDel = ['d_channels', 'e_channels', 'created_at', 'updated_at', 'building_id', 'device_type']
        if (tempType === 'electric') {
            toDel = [...toDel, 'utility_type', 'input_unit', 'scale', 'device_type', 'channel_parent', 'alert_6_on', 'alert_6_on_amount', 'alert_6_on_interval', 'alert_6_off_amount', 'alert_6_off_interval', 'alert_7_on', 'alert_7_on_amount', 'alert_7_on_interval', 'alert_7_off_amount', 'alert_7_off_interval', 'alert_8_on', 'alert_8_on_amount', 'alert_8_on_interval', 'alert_8_off_amount', 'alert_8_off_interval', 'alert_9_on', 'alert_9_on_amount', 'alert_9_on_interval', 'alert_9_off_amount', 'alert_9_off_interval']
        } else {
            toDel = [...toDel, 'poles', 'ct_size', 'phase', 'breaker_nr', 'breaker_amps', 'circuit_type', 'sub_category', 'channel_subpanel', 'sub_monitored', 'natural_gas_user', 'equipment_group', 'equipment_model', 'operating_load_threshold', 'average_operating_consumption', 'factor', 'breaker_label', 'alert_1_on', 'alert_1_percentage', 'alert_1_amount', 'alert_2_on', 'alert_2_time', 'alert_3_on', 'alert_3_time', 'alert_4_on', 'alert_4_consumption', 'alert_5_on', 'alert_5_hours', 'alert_5_last_maintenance', 'phasing_verified']
        }
        if (getPutPost === 'post') {
            if (selectedChannel.type === 'electric') {
                payload = []
                for (let phase of Object.keys(selectedChannel.phases)) {
                    if (selectedChannel.phases[phase] && selectedChannel.phases[phase].trim() !== '') {
                        let tempChan = { ...selectedChannel }
                        delete tempChan.phases
                        if (getPutPost === 'post') tempChan.device_id = device
                        tempChan.phase = phase
                        tempChan.virtual = virtual
                        tempChan.channel = selectedChannel.phases[phase]
                        payload.push(tempChan)
                    }
                }
                payload = payload.map(e => ({ ...e, poles: payload.length }))
            } else {
                payload = [JSON.parse(JSON.stringify(selectedChannel))]
                payload[0].device_id = device
                delete payload[0].phases
                payload[0].virtual = virtual
            }

            let intVals = ["cost_model", "co_model", "factor", "ct_size",
                "breaker_nr", "breaker_amps", "channel", "poles", "operating_load_threshold", "average_operating_consumption"]

            for (let intVal of intVals) {
                for (let channel of payload) {
                    if (intVal in channel && channel[intVal]) channel[intVal] = parseFloat(channel[intVal])
                }
            }
            for (let item of payload) {
                for (let key in item) {
                    if (item[key] === null) {
                        delete item[key];
                    }
                }
                for (let del of toDel) {
                    delete item[del];
                }
            }
        } else {
            payload = JSON.parse(JSON.stringify(selectedChannel))

            if (payload['type'] === 'digital') {
                payload['type'] = payload['utility_type']
            }

            let intVals = ["cost_model", "co_model", "factor", "ct_size",
                "breaker_nr", "breaker_amps", "channel", "poles"]
            for (let intVal of intVals) {
                if (intVal in payload && payload[intVal]) payload[intVal] = parseFloat(payload[intVal])
            }

            let nullable = ['channel_subpanel']
            for (let key in payload) {
                if (!nullable.includes(key) && (payload[key] === null)) {
                    delete payload[key];
                } else if (typeof payload[key] === "string") {
                    payload[key] = String(payload[key]).trim()
                }
            }
            toDel = [...toDel, 'device_id', 'id', 'phases', 'phase', 'channel']
            for (let item of toDel) {
                delete payload[item];
            }
        }

        const cancelToken = API.cancelToken();
        const auth = context.getUser.token;

        if (getPutPost === 'post') {
            let postPayload = { channels: payload, type: tempType }
            API.postChannel(cancelToken, auth, postPayload).then(res => {
                let link = queryParams.get('building') && queryParams.get('building') !== null && queryParams.get('building') !== "" ?
                    `/device?device=${payload[0].device_id}&tab=channels&channel=${GlobalFuncs.removeSpecialCharacters(payload[0].channel_name)}&building=${queryParams.get('building')}` :
                    `/device?device=${payload[0].device_id}&tab=channels&channel=${GlobalFuncs.removeSpecialCharacters(payload[0].channel_name)}`
                setGetPutPost('get')
                navigate(link)
            }).catch(err => {
                console.log(err)
                if (err.message === 'cancelling') return
                if (err.response && err.response.data) {
                    try {
                        if (err.response.data.includes('error')) {
                            Swal.fire({
                                title: 'Error.',
                                text: err.response.data.error,
                                icon: 'warning',
                                confirmButtonText: 'Ok',
                                confirmButtonColor: '#46775A',
                            });
                        } else {
                            setError(err.response.data)
                        }
                    } catch (e) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    }
                } else {
                    Swal.fire({
                        title: 'Oops!',
                        text: "An error occurred, please contact us.",
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            }).finally(e => {
                setLoading(false)
            })
        } else {
            delete payload['device_id'];
            API.putChannel(cancelToken, auth, selectedChannel.id, payload).then(res => {
                let link = queryParams.get('building') && queryParams.get('building') !== null && queryParams.get('building') !== "" ?
                    `/device?device=${device}&tab=${currentTab}&channel=${GlobalFuncs.removeSpecialCharacters(payload.channel_name)}&building=${queryParams.get('building')}` :
                    `/device?device=${device}&tab=${currentTab}&channel=${GlobalFuncs.removeSpecialCharacters(payload.channel_name)}`
                setGetPutPost('get')
                navigate(link)
            }).catch(err => {
                console.log(err)
                if (err.message === 'cancelling') return
                if (err.response && err.response.data) {
                    try {
                        if (err.response.data.includes('error')) {
                            Swal.fire({
                                title: 'Error.',
                                text: err.response.data.error,
                                icon: 'warning',
                                confirmButtonText: 'Ok',
                                confirmButtonColor: '#46775A',
                            });
                        } else {
                            setError(err.response.data)
                        }
                    } catch (e) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    }
                } else {
                    Swal.fire({
                        title: 'Oops!',
                        text: "An error occurred, please contact us.",
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            }).finally(e => {
                setLoading(false)
            })
        }
    }

    const handleTabClick = (e) => {
        if (getPutPost !== 'get') {
            Swal.fire({
                title: 'Unsaved Changes!',
                text: "Please save your changes before changing tabs.",
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            })
        } else {
            navigate(`/device?device=${device}&tab=${e}&channel=${selectedChannel && selectedChannel.channel_name ? GlobalFuncs.removeSpecialCharacters(selectedChannel.channel_name) : ""}&building=${queryParams.get('building')}`)
        }
    }


    return (
        <div className='device'>
            <div className='flex flex-nowrap gap-8 px-8 py-6 bg-[white]'>
                {
                    tabs.map((e, idx) => (
                        <p key={idx + 'tabnames'} onClick={() => handleTabClick(e)} className={`cursor-pointer font-normal text-base leading-5 pb-1 font-bold capitalize ${currentTab === e ? 'active-factor' : 'inactive-factor'}`}>
                            {e}
                        </p>
                    ))
                }
            </div>
            <div className='tablet:p-8 mobile:p-3 min-h-[80vh]'>
                <div className='flex flex-nowrap items-center gap-4 tablet:mb-8 mobile:mb-3 text-base'>
                    <Link to={queryParams.get('building') && queryParams.get('building') !== null && queryParams.get('building') !== "" ?
                        `/settings/devices?building=${queryParams.get('building')}` : `/settings/devices`}>
                        Devices
                    </Link>
                    {
                        GlobalSVG.rightArrow()
                    }
                    <p className='font-medium'>
                        {
                            ['get', 'put'].includes(getPutPost) ? deviceData.device_id : "Add Device"

                        }
                    </p>
                    {loading &&
                        <Loader />}
                </div>
                {
                    currentTab === 'device' &&
                    <DeviceTab
                        error={error}
                        device={device}
                        deviceData={deviceData}
                        virtual={virtual}
                        getPutPost={getPutPost}
                        updateDevice={updateDevice} />
                }
                {
                    currentTab === 'channels' &&
                    <ChannelTab
                        device={device}
                        deviceData={deviceData}
                        virtual={virtual}
                        error={error}
                        channelOptions={channelOptions}
                        getPutPost={getPutPost}
                        selectedChannel={selectedChannel}
                        channelTemplate={channelTemplate}
                        setSelectedChannel={setSelectedChannel}
                        setGetPutPost={setGetPutPost} />
                }
                {
                    currentTab === 'equipment' &&
                    <EquipmentTab
                        device={device}
                        deviceData={deviceData}
                        virtual={virtual}
                        error={error}
                        channelOptions={channelOptions}
                        setDeviceData={setDeviceData}
                        selectedChannel={selectedChannel}
                        channelTemplate={channelTemplate}
                        setSelectedChannel={setSelectedChannel}
                        getPutPost={getPutPost} />
                }
                {
                    ['get'].includes(getPutPost) && (currentTab !== 'equipment' || (selectedChannel && selectedChannel.channel_name.trim() !== '')) &&
                    <div className='w-full flex flex-nowrap justify-end text-green4 p-2.5'>
                        <p className='cursor-pointer capitalize' onClick={() => setGetPutPost('put')}>
                            Edit {currentTab}
                        </p>
                    </div>
                }
                <div className="flex flex-nowrap items-center justify-end w-full">
                    {
                        ['put', 'post'].includes(getPutPost) && !loading &&
                        <div className='mr-8 text-green3 cursor-pointer' onClick={() => setGetPutPost('get')}>
                            Cancel
                        </div>
                    }
                    {
                        getPutPost !== 'get' && deviceData.building !== "" && !loading && <button className='submit-button' onClick={handlePost}>
                            Save
                        </button>
                    }
                </div>
            </div>
        </div>
    );
};

export default Device;