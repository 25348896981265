import React from 'react';
import { useState, useEffect, useContext, useRef } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import { useNavigate } from 'react-router-dom';
import Loader from "../../components/Loader";
import Accordion from "../../components/Accordion";
import { GoogleMap, useLoadScript, Marker, InfoWindowF } from '@react-google-maps/api';
import './style.css'

const Map = () => {

  const alertDict = { 1: '#d7aaaa', 2: '#fde7a8' }
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY
  })
  const [clients, setClients] = useState(null)
  const [selectedClient, setSelectedClients] = useState(null)
  const context = useContext(AppContext);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const navigate = useNavigate();
  const mapRef = useRef(null);

  useEffect(() => {
    context.resetBuilding()
    if (context.getUserInfo === null) return
    let buildings = context.getUserInfo.buildings
    let tempClients = {}
    for (let item of buildings) {
      tempClients[item.client_name] = tempClients[item.client_name] || []
      tempClients[item.client_name].push(item)
    }
    tempClients = Object.keys(tempClients).map(e => ({ title: e, content: tempClients[e] }))
    tempClients.sort(function (a, b) {
      var titleA = a.title.toUpperCase();
      var titleB = b.title.toUpperCase();
      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }
      return 0;
    });

    setClients(tempClients)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.getUserInfo])

  const handleMarkerHover = (marker) => {
    setSelectedMarker(marker);
  };

  // Function to handle marker mouse out event
  const handleMarkerMouseOut = () => {
    setSelectedMarker(null);
  };

  const handlePinClick = (id) => {
    navigate(`/home?building=${id}`)
  }

  const fitBounds = () => {
    if (mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds();
  
      clients[selectedClient].content.forEach(e => {
        bounds.extend(new window.google.maps.LatLng(parseFloat(e.latitude), parseFloat(e.longitude)));
      });
  
      mapRef.current.state.map.fitBounds(bounds);
  
      // Calculate the new zoom level with a maximum of 10% zoom
      const maxZoom = 10; // Adjust this value as needed
      const currentZoom = mapRef.current.state.map.getZoom();
      const newZoom = Math.min(currentZoom, maxZoom);
  
      mapRef.current.state.map.setZoom(newZoom);
    }
  };
  
  

  useEffect(() => {
    if (!selectedClient) return
    fitBounds()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient])


  return (
    <div className='border-solid m-auto w-full h-full tablet:p-6 relative'>
      {
        isLoaded ?
          <GoogleMap
            ref={mapRef}
            zoom={selectedClient ? 10 : 5}
            center={clients && selectedClient !== null ? { lat: parseFloat(clients[selectedClient].content[0].latitude), lng: parseFloat(clients[selectedClient].content[0].longitude) } : { lat: 56.7681, lng: -98.8661 }}
            mapContainerClassName="map-container">
            {clients &&
              selectedClient !== null &&
              clients[selectedClient].content.map((e, index) => (
                <Marker
                  key={index + 'mappin'}
                  position={{ lat: parseFloat(e.latitude), lng: parseFloat(e.longitude) }}
                  onMouseOver={() => handleMarkerHover(e)}
                  onMouseOut={handleMarkerMouseOut}
                  onClick={() => handlePinClick(e.building_id)}
                  icon={{
                    path: 'M50 10 A40 40 0 0 1 50 90 A40 40 0 0 1 50 10 Z',
                    fillColor: `${e.alerts.find(a => a.priority === 1 && !a.read_at) ? alertDict[1] : e.alerts.find(a => a.priority === 2 && !a.read_at) ? alertDict[2] : '#46775A'}`,
                    fillOpacity: 1,
                    scale: 0.3,
                  }}
                />
              ))}
            {
              selectedMarker && (
                <InfoWindowF
                  position={{ lat: parseFloat(selectedMarker.latitude), lng: parseFloat(selectedMarker.longitude) }}
                  onCloseClick={handleMarkerMouseOut}
                  options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
                >
                  <div>
                    <p>{selectedMarker.building}</p>
                  </div>
                </InfoWindowF>
              )}
          </GoogleMap>
          :
          <Loader />
      }
      {clients ?
        <div className='absolute laptop:top-[15%] tablet:top-[60%] mobile:top-[60%] laptop:bottom-[15%] tablet:bottom-[6%] mobile:bottom-[3%] laptop:right-[7%] tablet:right-[10%] mobile:right-[20%] laptop:w-[20%] tablet:w-10/12 mobile:w-9/12  flex items-start justify-center '>
          <div className='card buildings-card w-full laptop:h-full tablet:h-full mobile:h-full p-4 rounded right-0'>
            <Accordion items={clients} onSelect={setSelectedClients} />
          </div>
        </div>
        :
        <Loader />
      }
    </div>
  );
};

export default Map;