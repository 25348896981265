import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import API from '../../utils/API';
import Swal from 'sweetalert2';
import './style.css'

const PublicCongratulations = () => {

    const location = useLocation();
    const [images, setImages] = useState([])

    useEffect(() => {
        const cancelToken = API.cancelToken();
        const getData = async () => {
            try {
                let images = await API.getPublicImages(cancelToken)
                setImages(images.data.logos)

            } catch (err) {
                console.log(err)
                if (err.message === 'cancelling') return
                if (err.response && err.response.data) {
                    try {
                        if (err.response.data.includes('error')) {
                            Swal.fire({
                                title: 'Error.',
                                text: err.response.data.error,
                                icon: 'warning',
                                confirmButtonText: 'Ok',
                                confirmButtonColor: '#46775A',
                            });
                        }
                    } catch (e) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    }
                } else {
                    Swal.fire({
                        title: 'Error.',
                        text: 'An error occurred, please try again later.',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            }
        }
        getData()

        return () => {
            API.cancel(cancelToken);
        }
        
    }, [location.pathname])

    return (
        <div className='bg-[white]'>
            <div className='gradient-congrats w-full desktop:p-[90px] mobile:p-[40px]'>
                <div className='flex flex-nowrap justify-center items-center w-full'>
                    <img className='w-[400px] ' src={require('../../assets/images/logo_full.png')} alt="" />
                    <div>
                        <p className='italic text-[16px] text-[#FFFFFF] text-center'>
                            Making our world more responsible...
                        </p>
                        <p className='italic text-[16px] text-[#FFFFFF] text-center'>
                            one building at a time!
                        </p>
                    </div>
                </div>
                <h1 className='text-extrabold text-center text-[128px] text-[#FFFFFF] open-sans-bold'>Congratulations!</h1>
                <p className='text-center text-[64px] text-[#FFFFFF]'>to our clients ... our partners</p>
                <p className='text-center text-[64px] text-[#FFFFFF]'>in energy conservation!</p>
            </div>
            <div className='flex flex-wrap items-center justify-center desktop:p-[150px] mobile:py-[50px]'>
                {
                    images.length > 0 && images.map((e, idx) => (
                        <img key={idx + 'clientcongratsimages'} className='h-[150px] px-[80px]' src={"https://client-congratulation-logo.s3.amazonaws.com/" + e} alt="" />
                    ))
                }
            </div>
        </div>
    )
};

export default PublicCongratulations;