import { useEffect, useState } from 'react';
import { DateRangePickerComponent, DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import './style.css';
import moment from 'moment-timezone';

export default function DateRangePicker(props) {
    const minDate = new Date('2021-01-01');
    const maxDate = new Date();
    const [start, setStart] = useState(null)
    const [end, setEnd] = useState(null)
    useEffect(()=>{
        setStart(props.start)
        setEnd(props.end)
    },[props])

    return (
        <div className={`${props.wrap ? 'tablet:w-[280px] mobile:w-full' : ''}`}>
            <div className={`mr-0 flex ${props.wrap ? 'flex-col w-full justify-start' : 'justify-start'}`}>
                <div className={`flex ${props.wrap ? 'justify-center mb-2' : 'justify-end leading-5 ml-5'} items-center`}>                    
                    <p className="font-sans font-normal text-sans text-[#332D41] md:decoration-dashed">Select dates:</p>
                </div>
                <div className={`flex ${props.wrap ? 'justify-center' : 'justify-start'}`}>
                    <div className={`control-pane ${props.wrap ? '' : 'mx-2'} p-1 font-sans text-[#377745] border-2 border-solid border-neutral-200 rounded-lg text-sm leading-4 font-bold min-w-[200px]`}>
                        <DateRangePickerComponent
                            startDate={start && moment(start).toDate()}
                            endDate={end && moment(end).toDate()}
                            min={minDate}
                            max={maxDate}
                            onChange={props.onChange}/>
                    </div>
                </div>
            </div>
            {
                props.compare &&
                    <div className={`mr-0 flex ${props.wrap ? 'flex-col w-full justify-start' : 'justify-center'} mt-2`}>
                    <div className='flex justify-end items-center'>
                        <p className="font-sans font-normal leading-5 text-sans text-[#332D41] md:decoration-dashed"> <b></b></p>
                        <p className={`font-sans ${props.wrap ? 'text-center mb-2 w-full' : 'leading-5 ml-5'} text-[#332D41] md:decoration-dashed`}>Select start: </p>
                    </div>
                    <div className={`flex ${props.wrap ? 'justify-center' : 'justify-start'}`}>
                        <div className='p-1 control-pane mx-2 font-sans text-[#377745] border-2 border-solid border-neutral-200 rounded-lg text-sm leading-4 font-bold'>
                            <DatePickerComponent 
                            min={minDate}
                            max={maxDate}
                            onChange={props.onChangeCompare}/>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};
