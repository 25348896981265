import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import './style.css'
// import GlobalSVG from '../../utils/GlobalSVG';
// import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
// import moment from 'moment';
import MaintenanceActivitiesLines from '../MaintenanceActivitiesLines';
import MaintenanceLogsLines from '../MaintenanceLogsLines';

const AssetMaintenance = (props) => {
    // const [filters, setFilters] = useState({})
    const [showFilters, setShowFilters] = useState(false)
    // const [filterOptions, setFilterOptions] = useState({})
    // const [sorting, setSorting] = useState({})
    // const statusColor = { 'Upcoming': '#107048', 'Due': '#F9941E', 'Set': '#B8BBB9', 'Overdue': '#F75D5F' }


    // useEffect(() => {

    //     if (!props.groups) return

    //     let tempGroups = JSON.parse(JSON.stringify(props.groups))
    //     let tempPerformed = []
    //     let tempThreshold = []
    //     let tempTolerance = []
    //     let tempDueIn = []
    //     let tempPercent = []

    //     let tempPerf = props.groups.map(group => group.performedBy)
    //     tempPerformed = new Set([...tempPerf])

    //     props.groups.forEach(group => {
    //         let tempThresh = group.activities.map(group => parseFloat(group.threshold))
    //         tempThreshold = new Set([...tempThreshold, ...tempThresh])
    //     })

    //     props.groups.forEach(group => {
    //         let tempTol = group.activities.map(group => parseFloat(group.tolerance))
    //         tempTolerance = new Set([...tempTolerance, ...tempTol])
    //     })

    //     props.groups.forEach(group => {
    //         let tempDue = group.activities.map(group => parseFloat(group.dueIn))
    //         tempDueIn = new Set([...tempDueIn, ...tempDue])
    //     })

    //     props.groups.forEach(group => {
    //         let tempPerc = group.activities.map(group => parseFloat(group.percent))
    //         tempPercent = new Set([...tempPercent, ...tempPerc])
    //     })

    //     setFilterOptions({
    //         performedBy: Array.from(tempPerformed).sort(),
    //         threshold: Array.from(tempThreshold).sort(),
    //         tolerance: Array.from(tempTolerance).sort(),
    //         dueIn: Array.from(tempDueIn).sort(),
    //         percent: Array.from(tempPercent).sort(),
    //     })


    //     for (let field in filters) {
    //         if (filters[field] === null) continue
    //         if (field === 'lastActionDate') {
    //             tempGroups = tempGroups.filter(group => moment(group.lastActionDate) >= moment(filters[field]))
    //         } else if (['threshold', 'tolerance', 'dueIn', 'percent', 'currentStatus'].includes(field)) {
    //             let digits = ['threshold', 'tolerance', 'dueIn', 'percent']
    //             tempGroups = tempGroups.filter(group => digits.includes(field) ? group.activities.map(activity => parseFloat(activity[field])).includes(parseFloat(filters[field])) : group.activities.map(activity => activity[field]).includes(filters[field]))
    //         } else {
    //             tempGroups = tempGroups.filter(group => group[field].trim().toLowerCase() === filters[field].trim().toLowerCase())
    //         }
    //     }
    //     for (let field in sorting) {
    //         tempGroups.sort((a, b) => {
    //             if (a[field] === null && b[field] === null) return 0;
    //             if (a[field] === null) return sorting[field] ? 1 : -1;
    //             if (b[field] === null) return sorting[field] ? -1 : 1;

    //             switch (field) {
    //                 case 'lastActionDate':
    //                 case 'updated_date':
    //                     return sorting[field]
    //                         ? moment(a[field]).diff(moment(b[field]))
    //                         : moment(b[field]).diff(moment(a[field]));
    //                 case 'type':
    //                 case 'performedBy':
    //                 case 'description':
    //                     return sorting[field]
    //                         ? a[field].localeCompare(b[field])
    //                         : b[field].localeCompare(a[field]);
    //                 default:
    //                     return sorting[field]
    //                         ? a[field] - b[field]
    //                         : b[field] - a[field];
    //             }
    //         });
    //     }
    //     setFilteredGroups(tempGroups)
    // }, [props.groups, filters, sorting])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (showFilters && !event.target.closest('.multi-select')) {
                setShowFilters(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [showFilters]);

    return (
        <div>
            <div className='device tablet:p-8 mobile:p-3'>
                <div className='tablet:p-8 mobile:p-3 min-h-[80vh] mb-6'>
                    <MaintenanceActivitiesLines byCircuit={true} groups={props.groups} setGroups={props.setGroups} updateNumbers={props.updateNumbers} />
                    <div className='mb-[40px]'></div>
                    <MaintenanceLogsLines byCircuit={true} groups={props.groups} setGroups={props.setGroups} updateNumbers={props.updateNumbers} channelName={props.channelName}/>
                </div>
            </div >
        </div>
    )
}


export default AssetMaintenance;