import React, { useState, useEffect, useRef } from 'react';
import './style.css'
import GlobalSVG from '../../utils/GlobalSVG';

function Carousel(props) {
    const [offset, setOffset] = useState();
    const [clicks, setClicks] = useState(0);
    const [start, setStart] = useState(0)
    const [activeImage, setActiveImage] = useState(0)
    const el = useRef();


    useEffect(() => {
        if (!el.current) return
        let marg = el.current.children[0].offsetWidth
        setClicks(props.buffer ? Math.ceil(el.current.scrollWidth / (marg)) : Math.floor(el.current.scrollWidth / (marg)))
        setOffset(marg + 10)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    window.addEventListener('resize', function () {
        if (!el.current) return
        let marg = el.current.children[0].offsetWidth
        handleScroll()
        el.current && setClicks(props.buffer ? Math.ceil(el.current.scrollWidth / (marg)) : Math.floor(el.current.scrollWidth / (marg)))
        setOffset(marg + 10);
    });

    const scrollRight = () => {
        setActiveImage(curr => curr >= clicks - 1 ? curr : curr + 1)
    }

    const scrollLeft = () => {
        if (activeImage === 0) return
        setActiveImage(curr => curr - 1)
    }

    useEffect(() => {
        handleScroll()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeImage])

    const handleScroll = () => {
        el.current && el.current.scrollTo({
            top: 0,
            left: activeImage * offset,
            behavior: 'smooth'
        })
    }

    return (
        <div className='hero '>
            <div className={`scroller ${props.buffer ? 'buffer' : ''}`} ref={el} onTouchStart={e => setStart(e.changedTouches[0].clientX)} onTouchEnd={e => e.changedTouches[0].clientX > start ? scrollLeft() : scrollRight()}>
                {props && props.children.map(element => (
                    element
                ))}
            </div>
            {activeImage !== 0 && <div className='left-arrow z-10' onClick={scrollLeft}>{GlobalSVG.leftArrow()}</div>}
            {activeImage <= clicks - 3 && <div className='right-arrow z-10' onClick={scrollRight}>{GlobalSVG.rightArrow()}</div>}
            {props.tracker && <div className='tracker-wrapper px-9 gap-12'>
                <div className='tracker'>
                    {
                        props && props.children.map((e, index) => (
                            <div className={`tracker-bar ${activeImage === index && 'active'}`} key={`hero_tracker_${index}`} style={{ width: `${370 / props.children.length}px` }}></div>
                        ))
                    }
                </div>
            </div>}
        </div>
    )
}

export default Carousel;