import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import GraphFuncs from './GraphFuncs';
import moment from 'moment';
import * as XLSX from 'xlsx';

const GlobalFuncs = {

  cleanString: (value) => {
    let temp = value.replaceAll(/[^A-Za-z0-9-]/g, '').replaceAll('/', '');
    return temp;
  },

  ScrollToTop: () => {
    const location = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);

    return null;
  },
  formatDate: (date, format, compensate = false) => {
    if (compensate) date.setDate(date.getDate() + 1)
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    let monthDict = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    format = format.replace('YYYY', year);
    format = format.replace('YY', year - 2000);
    format = format.replace('MM', month);
    format = format.replace('Mm', monthDict[month - 1]);
    format = format.replace('DD', day);
    format = format.replace('HH', hours);
    format = format.replace('mm', minutes);
    format = format.replace('ss', seconds);
    return format;
  },
  getDateFromStringUTC: (date) => {
    return Date.UTC(date.split("-")[0] - 1, date.split("-")[1] - 1, date.split("-")[2])
  },
  removeSpecialCharacters: (inputString) => {
    if (typeof inputString !== 'string') {
      return false
    }
    const regex = /[^a-zA-Z0-9\s]/g;
    const cleanedString = inputString.replace(regex, '')
    return cleanedString.trim();
  },
  get15MinRange: (dateString) => {
    const [year, month, day, hours, minutes, seconds] = dateString.split(/[- :]/);
    const currentDate = new Date(year, month - 1, day, hours, minutes, seconds);
    currentDate.setMinutes(currentDate.getMinutes() - 15);
    return `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getDate().toString().padStart(2, '0')} ${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}:${currentDate.getSeconds().toString().padStart(2, '0')}`;

  },
  getApplicableDates: (start, end, months, days, frequency, toDate, source, impact) => {
    if (!frequency || (frequency === 'Monthly' && !months) || (frequency === 'Daily' && (!months || !days))) return 0
    let cost = 0
    let co = 0
    let count = 0
    months = months.split(',').map(e => parseInt(e))
    var lastDay = moment();
    if (end && (!toDate || moment() > moment(end))) lastDay = end
    if (frequency === 'Daily') {
      days = days.split(',').map(e => parseInt(e))
      let numDays = GraphFuncs.getDifferenceInDays(start, lastDay)
      for (let day = 0; day < numDays; day++) {
        let tempDay = moment(start).add(day, 'days')
        if (days.includes(tempDay.day()) && months.includes(tempDay.month())) {
          count += impact.reduction
          for (let value of source.cost_model) {
            let typeDict = {
              kwh: (value.subsequent_kwh_price + value.carbon_charge),
              kva: value.subsequent_kva_price,
              water: ((value.consumption_rate / 1000) + (((value.consumption_rate / 1000) * value.sewage_ratio / 100) * (value.sewage_rate / 1000))),
              gas: ((value.consumption_rate + value.carbon_charge) / 35.314)
            }
            let valueDate = moment(value.start_date, "YYYY-MM-DD HH:mm:ss");
            if (tempDay.isSameOrAfter(valueDate)) {
              let tempCost = impact.reduction * typeDict[impact.type]
              tempCost = tempCost + (tempCost * value.gs_tax / 100) + (tempCost * value.ps_tax / 100)
              cost += tempCost
              break
            }
          }
          if (source.co_model) {
            for (let value of source.co_model) {
              let typeDict = {
                kwh: value.factor / 1000000,
                gas: value.factor / 35.314
              }
              let valueDate = moment(value.start_date, "YYYY-MM-DD HH:mm:ss");
              if (tempDay.isSameOrAfter(valueDate)) {
                let tempCost = impact.reduction * typeDict[impact.type]
                co += tempCost
                break
              }
            }
          }
        }
      }
    } else {
      let numMonths = GraphFuncs.getDifferenceInMonths(start, lastDay)
      for (let month = 0; month < numMonths; month++) {
        let tempMonth = moment(start)
        tempMonth.add(month, 'months')
        if (months.includes(tempMonth.month())) {
          count += impact.reduction
          for (let value of source.cost_model) {
            let typeDict = {
              kwh: (value.subsequent_kwh_price + value.carbon_charge),
              kva: value.subsequent_kva_price,
              water: ((value.consumption_rate / 1000) + (((value.consumption_rate / 1000) * value.sewage_ratio / 100) * (value.sewage_rate / 1000))),
              gas: ((value.consumption_rate + value.carbon_charge) / 35.314)
            }
            if (tempMonth >= moment(value.start_date)) {
              let tempCost = impact.reduction * typeDict[impact.type]
              tempCost = (tempCost + (tempCost * value.gs_tax / 100) + (tempCost * value.ps_tax / 100))
              cost += tempCost
              break
            }
          }
          if (source.co_model) {
            for (let value of source.co_model) {
              let typeDict = {
                kwh: value.factor / 1000000,
                gas: value.factor / 35.314
              }
              if (tempMonth >= moment(value.start_date)) {
                let tempCost = impact.reduction * typeDict[impact.type]
                co += tempCost
                break
              }
            }
          }
        }
      }
    }
    return { cost: cost, co: co, reduction: count };
  },
  getCalculatedMaintenanceValues: (data, equipmentRuntime) => {
    data = JSON.parse(JSON.stringify(data))
    for (let group of data) {
      group.lastActionDate = null
      group.equipment = []
      group.threshold = null
      group.tolerance = null
      group.dueIn = null
      group.performedBy = null
      group.percent = null

      group.logs.sort((a, b) => {
        return moment(a.completed_date).diff(moment(b.completed_date));
      });

      group.activities.forEach(e => {
        e.lastActionDate = null
        e.performedBy = null
      })

      let currentDate = {}
      for (let log of group.logs) {
        log.runtime = 0
        log.daysOverdue = 0
        log.onTime = true
        log.group_id = group.id
        let activity = group.activities.find(e => e.id === log.activity_id)
        if (!activity) continue
        currentDate[activity.id] = currentDate[activity.id] || moment("2022-01-01")
        log.channel_name = activity.channel_name
        log.channel_id = activity.channel_id
        log.type = group.type
        activity.type = group.type
        activity.lastActionDate = log.completed_date ? log.completed_date.split("T")[0] : null
        activity.performedBy = log.performed_by

        if (!group.lastActionDate || moment(group.lastActionDate) < moment(activity.lastActionDate)) {
          group.lastActionDate = activity.lastActionDate
          group.performedBy = activity.performedBy
        }
        if (activity.timebase === 'runtime') {
          const endDate = moment(log.completed_date);
          while (currentDate[activity.id].isSameOrBefore(endDate)) {
            if (activity.channel_name in equipmentRuntime && currentDate[activity.id].format('YYYY-MM-DD') in equipmentRuntime[activity.channel_name]) log.runtime += equipmentRuntime[activity.channel_name][currentDate[activity.id].format('YYYY-MM-DD')]
            currentDate[activity.id].add(1, 'days');
          }
          log.percent = log.runtime / log.threshold
        } else {
          const endDate = moment(log.completed_date);
          log.runtime = endDate.diff(currentDate[activity.id], 'days');
          currentDate[activity.id] = endDate
        }
        if (log.runtime > (log.threshold + log.tolerance)) {
          log.onTime = false
          log.daysOverdue = log.runtime - (log.threshold + log.tolerance)
        }
      }
      for (let activity of group.activities) {
        group.equipment.push(activity.channel_name)
        activity.dueIn = activity.threshold
        activity.runtime = 0
        let currentDate = activity.lastActionDate ? moment(activity.lastActionDate) : moment('2022-01-01')
        if (activity.timebase === 'runtime') {
          while (currentDate.isSameOrBefore(moment())) {
            if (activity.channel_name in equipmentRuntime && currentDate.format('YYYY-MM-DD') in equipmentRuntime[activity.channel_name]) {
              activity.dueIn -= equipmentRuntime[activity.channel_name][currentDate.format('YYYY-MM-DD')]
              activity.runtime += equipmentRuntime[activity.channel_name][currentDate.format('YYYY-MM-DD')]
            }
            currentDate.add(1, 'days');
          }
        } else {
          const endDate = moment();
          activity.dueIn -= endDate.diff(currentDate, 'days');
          activity.runtime = endDate.diff(currentDate, 'days');
        }
        activity.percent = activity.runtime / activity.threshold
        activity.currentStatus = activity.dueIn > (activity.tolerance) ? 'Set' :
          activity.dueIn <= (activity.tolerance) && activity.dueIn >= 0 ? 'Upcoming' :
            activity.dueIn < 0 && activity.dueIn >= (- activity.tolerance) ? 'Due' :
              'Overdue'
        if (group.dueIn === null || activity.dueIn < group.dueIn) {
          group.threshold = activity.threshold
          group.tolerance = activity.tolerance
          group.dueIn = activity.dueIn
          group.timebase = activity.timebase
          group.percent = activity.percent
          group.currentStatus = activity.dueIn > (activity.tolerance) ? 'Set' :
            activity.dueIn <= (activity.tolerance) && activity.dueIn >= 0 ? 'Upcoming' :
              activity.dueIn < 0 && activity.dueIn >= (- activity.tolerance) ? 'Due' :
                'Overdue'
        }
      }
    }
    return data
  },
  getCalculatedValues: async (getBuildingInfo, data, range = null) => {
    let lastYear = moment().subtract(1, 'year');

    const typeDict = { kwh: "e_sources", kva: "e_sources", gas: "g_sources", water: "w_sources" }
    for (let activity of data) {
      activity.startDate = null
      activity.endDate = null
      activity.kwhToDate = 0
      activity.kwhYear = 0
      activity.kwhTotal = 0
      activity.kwhPriceToDate = 0
      activity.kwhPriceYear = 0
      activity.kvaToDate = 0
      activity.kvaYear = 0
      activity.kvaTotal = 0
      activity.kvaPriceToDate = 0
      activity.kvaPriceYear = 0
      activity.gasToDate = 0
      activity.gasYear = 0
      activity.gasTotal = 0
      activity.waterToDate = 0
      activity.waterTotal = 0
      activity.waterYear = 0
      activity.coToDate = 0
      activity.coYear = 0
      activity.gasPriceToDate = 0
      activity.gasPriceYear = 0
      activity.waterPriceToDate = 0
      activity.waterPriceYear = 0
      activity.savings = 0

      let skipEnd = false
      for (let impact of activity.impacts) {


        let tempStart = null
        if (!impact.start_date) continue
        tempStart = new Date(impact.start_date)
        if (activity.startDate !== null) {
          if (new Date(impact.start_date) < new Date(activity.startDate)) activity.startDate = impact.start_date.split("T")[0]
        } else {
          activity.startDate = impact.start_date.split("T")[0]
        }

        if (impact.end_date === null) {
          activity.endDate = null
          skipEnd = true
        } else if (!skipEnd) {
          if (activity.endDate !== null) {
            if (new Date(impact.end_date) > new Date(activity.endDate)) activity.endDate = impact.end_date.split("T")[0]
          } else if (impact.end_date !== null) {
            activity.endDate = impact.end_date.split("T")[0]
          }
        }
        let formattedStart = impact.start_date.split("T")[0]
        let rangeStart = (range && moment(impact.start_date.split("T")[0]) < moment(range[0])) ? moment(range[0]).format('YYYY-MM-DD') : impact.start_date.split("T")[0]
        let rangeEnd = null
        if (range) {
          if (impact.end_date) {
            if (moment(impact.end_date.split("T")[0]) > moment(range[1])) {
              rangeEnd = moment(range[1]).format('YYYY-MM-DD')
            } else {
              rangeEnd = impact.end_date.split("T")[0]
            }
          } else {
            rangeEnd = moment(range[1]).format('YYYY-MM-DD')
          }
        } else {
          if (impact.end_date) {
            rangeEnd = impact.end_date.split("T")[0]
          } else {
            rangeEnd = moment().format('YYYY-MM-DD')
          }
        }

        let today_str = moment().format('YYYY-MM-DD')
        if (impact.end_date && moment(impact.end_date) <= moment()) {
          today_str = impact.end_date.split("T")[0]
        }

        if (tempStart !== null && impact.reduction && impact.frequency) {
          let lastYearOrStart = tempStart < lastYear ? lastYear.format('YYYY-MM-DD') : formattedStart
          let source = getBuildingInfo[typeDict[impact['type']]].find(e => e.circuit_name && e.circuit_name.trim() === impact['[source]'])
          if (!source) continue
          let applicableDates = GlobalFuncs.getApplicableDates(rangeStart, rangeEnd, impact.months, impact.days, impact.frequency, true, source, impact)
          let totalDates = GlobalFuncs.getApplicableDates(rangeStart, rangeEnd, impact.months, impact.days, impact.frequency, false, source, impact)
          let yearDates = GlobalFuncs.getApplicableDates(lastYearOrStart, today_str, impact.months, impact.days, impact.frequency, false, source, impact)

          switch (impact.type) {
            case 'kva':
              impact.reductionToDate = applicableDates.reduction
              impact.reductionTotal = totalDates.reduction
              impact.reductionYear = yearDates.reduction
              impact.priceToDate = applicableDates.cost
              impact.priceTotal = totalDates.cost
              impact.priceYear = yearDates.cost

              activity.kvaToDate += impact.reductionToDate
              activity.kvaTotal += impact.reductionTotal
              activity.kvaYear += impact.reductionYear

              activity.kvaPriceToDate += applicableDates.cost
              activity.kvaPriceYear += impact.priceYear

              break

            case 'kwh':
              impact.reductionToDate = applicableDates.reduction
              impact.reductionTotal = totalDates.reduction
              impact.reductionYear = yearDates.reduction
              impact.priceTotal = totalDates.cost
              impact.priceYear = yearDates.cost

              activity.kwhToDate += impact.reductionToDate
              activity.kwhTotal += impact.reductionTotal
              activity.kwhYear += impact.reductionYear

              activity.kwhPriceToDate += applicableDates.cost
              activity.kwhPriceYear += impact.priceYear

              activity.coToDate += applicableDates.co
              activity.coYear += yearDates.co

              break
            case 'gas':
              impact.reductionToDate = applicableDates.reduction
              impact.reductionTotal = totalDates.reduction
              impact.reductionYear = yearDates.reduction
              impact.priceTotal = totalDates.cost
              impact.priceYear = yearDates.cost

              activity.gasToDate += impact.reductionToDate
              activity.gasTotal += impact.reductionTotal
              activity.gasYear += impact.reductionYear
              activity.gasPriceToDate += applicableDates.cost
              activity.gasPriceYear += impact.priceYear
              activity.coToDate += applicableDates.co
              activity.coYear += yearDates.co

              break
            case 'water':
              impact.reductionToDate = applicableDates.reduction
              impact.reductionTotal = totalDates.reduction
              impact.reductionYear = yearDates.reduction
              impact.priceTotal = totalDates.cost
              impact.priceYear = yearDates.cost

              activity.waterToDate += impact.reductionToDate
              activity.waterTotal += impact.reductionTotal
              activity.waterYear += impact.reductionYear
              activity.waterPriceToDate += applicableDates.cost
              activity.waterPriceYear += impact.priceYear
              break
            default:
              break
          }
        }
      }
      activity.savings = activity.waterPriceToDate + activity.gasPriceToDate + activity.kwhPriceToDate + activity.kvaPriceToDate
      activity.savingsYear = activity.waterPriceYear + activity.gasPriceYear + activity.kwhPriceYear + activity.kvaPriceYear
      if (!activity.savings) activity.savings = 0
      if (!activity.savingsYear) activity.savingsYear = 0
    }
  },
  downloadExcel: (data, building = "Portfolio") => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, `Activities`);
    XLSX.writeFile(workbook, `Conservation Activities - ${building} - ${moment().format('D MMM YYYY')}.xlsx`);
  }
}

export default GlobalFuncs;