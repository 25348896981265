import { useState, useContext, useEffect } from "react";
import "./style.css"
import API from '../../utils/API';
import Swal from 'sweetalert2';
import { AppContext } from "../../utils/ContextProvider";
import Loader from "../Loader";

function CostValueE(props) {
    const [edit, setEdit] = useState(false)
    const [eValue, setEValue] = useState({
        "model_id": props.modelId,
        "start_date": "",
        "basic_monthly": "",
        "block_kwh_price": "",
        "subsequent_kwh_price": "",
        "block_kva_price": "",
        "subsequent_kva_price": "",
        "carbon_charge": "",
        "municipal_tax": "",
        "ps_tax": "",
        "gs_tax": ""
    })
    const [acceptable, setAcceptable] = useState(false)
    // const [error, setError] = useState(null)
    const [loading, setLoading] = useState(null)
    const context = useContext(AppContext)

    useEffect(() => {
        if (!props.value) return
        setEValue(props.value)
    }, [props])

    const updateValue = (e) => {
        const { name, value } = e.target;
        setEValue(prevValue => {
            return {
                ...prevValue,
                [name]: value.trim()
            }
        })
    }

    useEffect(() => {
        let mandatory = ['start_date', 'basic_monthly', 'block_kwh_price', 'subsequent_kwh_price',
            'block_kva_price', 'subsequent_kva_price', 'carbon_charge',
            'municipal_tax', 'ps_tax', 'gs_tax'
        ]

        for (let item of mandatory) {
            if (eValue[item] !== '') continue
            setAcceptable(false)
            return
        }
        setAcceptable(true)

    }, [eValue])

    const postValue = async (e) => {

        let tempValue = { ...eValue }
        const toFormat = ['basic_monthly', 'block_kwh_price', 'subsequent_kwh_price',
            'block_kva_price', 'subsequent_kva_price', 'carbon_charge',
            'municipal_tax', 'ps_tax', 'gs_tax'
        ]

        for (let item of toFormat) {
            tempValue[item] = tempValue[item] ? parseFloat(tempValue[item]).toFixed(6) : tempValue[item]
        }
        tempValue.start_date = tempValue.start_date.split(" ")[0]
        setLoading(true)
        const cancelToken = API.cancelToken();
        const auth = context.getUser.token;
        try {
            if (props.edit) {
                await API.postValueE(cancelToken, auth, tempValue)
            } else if (edit) {
                await API.putValueE(cancelToken, auth, tempValue)
            }
            if (props.edit) props.open(false)
            setEdit(false)
            props.setUpdate(!props.update)
        } catch (err) {
            console.log(err)
            if (err.message === 'cancelling') return
            if (err.response && err.response.data) {
                try {
                    if ('general' in err.response.data || 'message' in err.response.data) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data.general,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    }
                } catch (e) {
                    Swal.fire({
                        title: 'Error.',
                        text: err.response.data,
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            } else {
                Swal.fire({
                    title: 'Error.',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className='w-full flex flex-wrap input-border p-4'>
            <div className='tablet:w-2/12 mobile:w-full'>
                <div className='tablet:w-2/12 mobile:w-full min-w-[100px] mb-4'>
                    <p className='text-xs mb-1'>Start Date</p>
                    <input
                        type="date"
                        name="start_date"
                        className='text-xs input-color outline-none w-full hide-input-background p-1'
                        defaultValue={eValue.start_date !== "" ? eValue.start_date.split(' ')[0] : ""}
                        onChange={updateValue} />
                </div>
                <div className='tablet:w-2/12 mobile:w-full min-w-[100px] mb-4'>
                    <p className='text-xs mb-1'>Basic Monthly</p>
                    <div className='flex flex-nowrap items-center'>
                        <span className='mr-1'>$</span>
                        <input
                            placeholder='Price'
                            className='hide-input-background text-sm input-color outline-none w-full p-1'
                            type="number"
                            step="0.1"
                            disabled={!props.edit && !edit}
                            name="basic_monthly"
                            defaultValue={eValue.basic_monthly !== "" ? eValue.basic_monthly : ""}
                            onChange={updateValue} />
                    </div>
                </div>
            </div>
            <div className='tablet:w-2/12 mobile:w-full'>
                <div className='tablet:w-2/12 mobile:w-full min-w-[100px] mb-4'>
                    <p className='text-xs mb-1'>1st kWh Range</p>
                    <div className='flex flex-nowrap items-center'>
                        <span className='mr-1'>$</span>
                        <input
                            placeholder='Price'
                            className='hide-input-background text-sm input-color outline-none w-full p-1'
                            type="number"
                            step="0.1"
                            disabled={!props.edit && !edit}
                            name="block_kwh_price"
                            defaultValue={eValue.block_kwh_price !== "" ? eValue.block_kwh_price : ""}
                            onChange={updateValue} />
                    </div>
                </div>
                <div className='tablet:w-2/12 mobile:w-full min-w-[100px] mb-4'>
                    <p className='text-xs mb-1'>kWh other</p>
                    <div className='flex flex-nowrap items-center'>
                        <span className='mr-1'>$</span>
                        <input
                            placeholder='Price'
                            className='hide-input-background text-sm input-color outline-none w-full p-1'
                            type="number"
                            step="0.1"
                            disabled={!props.edit && !edit}
                            name="subsequent_kwh_price"
                            defaultValue={eValue.subsequent_kwh_price !== "" ? eValue.subsequent_kwh_price : ""}
                            onChange={updateValue} />
                    </div>
                </div>
            </div>
            <div className='tablet:w-2/12 mobile:w-full'>
                <div className='tablet:w-2/12 mobile:w-full min-w-[100px] mb-4'>
                    <p className='text-xs mb-1'>1st kVA Range</p>
                    <div className='flex flex-nowrap items-center'>
                        <span className='mr-1'>$</span>
                        <input
                            placeholder='Price'
                            className='hide-input-background text-sm input-color outline-none w-full p-1'
                            type="number"
                            step="0.1"
                            disabled={!props.edit && !edit}
                            name="block_kva_price"
                            defaultValue={eValue.block_kva_price !== "" ? eValue.block_kva_price : ""}
                            onChange={updateValue} />
                    </div>
                </div>
                <div className='tablet:w-2/12 mobile:w-full min-w-[100px] mb-4'>
                    <p className='text-xs mb-1'>kVA other</p>
                    <div className='flex flex-nowrap items-center'>
                        <span className='mr-1'>$</span>
                        <input
                            placeholder='Price'
                            className='hide-input-background text-sm input-color outline-none w-full p-1'
                            type="number"
                            step="0.1"
                            disabled={!props.edit && !edit}
                            name="subsequent_kva_price"
                            defaultValue={eValue.subsequent_kva_price !== "" ? eValue.subsequent_kva_price : ""}
                            onChange={updateValue} />
                    </div>
                </div>
            </div>
            <div className='tablet:w-2/12 mobile:w-full'>
                <div className='tablet:w-2/12 mobile:w-full min-w-[100px] mb-4'>
                    <p className='text-xs mb-1'>Carbon Charge</p>
                    <div className='flex flex-nowrap items-center'>
                        <span className='mr-1'>$</span>
                        <input
                            placeholder='Price'
                            className='hide-input-background text-sm input-color outline-none w-full p-1'
                            type="number"
                            step="0.1"
                            disabled={!props.edit && !edit}
                            name="carbon_charge"
                            defaultValue={eValue.carbon_charge !== "" ? eValue.carbon_charge : ""}
                            onChange={updateValue} />
                    </div>
                </div>
                <div className='tablet:w-2/12 mobile:w-full min-w-[100px] mb-4'>
                    <p className='text-xs mb-1'>Municipal Tax</p>
                    <div className='flex flex-nowrap items-center'>
                        <input
                            placeholder='Percent'
                            className='hide-input-background text-sm input-color outline-none w-full p-1'
                            type="number"
                            step="0.1"
                            disabled={!props.edit && !edit}
                            name="municipal_tax"
                            defaultValue={eValue.municipal_tax !== "" ? eValue.municipal_tax : ""}
                            onChange={updateValue} />
                        <span className='ml-1'>%</span>
                    </div>
                </div>
            </div>
            <div className='tablet:w-2/12 mobile:w-full'>
                <div className='tablet:w-2/12 mobile:w-full min-w-[100px] mb-4'>
                    <p className='text-xs mb-1'>PST</p>
                    <div className='flex flex-nowrap items-center'>
                        <input
                            placeholder='Percent'
                            className='hide-input-background text-sm input-color outline-none w-full p-1'
                            type="number"
                            step="0.1"
                            disabled={!props.edit && !edit}
                            name="ps_tax"
                            defaultValue={eValue.ps_tax !== "" ? eValue.ps_tax : ""}
                            onChange={updateValue} />
                        <span className='ml-1'>%</span>
                    </div>
                </div>
                <div className='tablet:w-2/12 mobile:w-full min-w-[100px] mb-4'>
                    <p className='text-xs mb-1'>GST</p>
                    <div className='flex flex-nowrap items-center'>
                        <input
                            placeholder='Percent'
                            className='hide-input-background text-sm input-color outline-none w-full p-1'
                            type="number"
                            step="0.1"
                            disabled={!props.edit && !edit}
                            name="gs_tax"
                            defaultValue={eValue.gs_tax !== "" ? eValue.gs_tax : ""}
                            onChange={updateValue} />
                        <span className='ml-1'>%</span>
                    </div>
                </div>
            </div>
            <div className='tablet:w-2/12 mobile:w-full'>
                <div className='flex flex-nowrap items-center justify-end h-full'>
                    {
                        edit && !loading &&
                        <div className='mr-8 border-light-green block text-sm text-green4 font-medium cursor-pointer' onClick={() => setEdit(false)}>
                            Cancel
                        </div>
                    }
                    {
                        (props.edit || edit) && acceptable && !loading &&
                        <button className='submit-button-small' onClick={postValue}>
                            Save
                        </button>
                    }
                    {
                        (!props.edit && !edit) && acceptable && !loading &&
                        <button className='submit-button-small' onClick={() => setEdit(true)}>
                            Edit
                        </button>
                    }
                    {
                        (props.edit || edit) && loading &&
                        <Loader />
                    }
                </div>
            </div>
        </div>
    )
}

export default CostValueE;
