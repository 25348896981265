import React, { useState } from 'react';

function SelectOrType(props) {
    const [inputValue, setInputValue] = useState('');
    const [isSelectMode, setIsSelectMode] = useState(false);

    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
        // Check if the current input value matches an option
        const matchingOption = props.options.find((option) =>
            option.toLowerCase().includes(value.toLowerCase())
        );

        props.onChange({ target: { value: value, name: props.name } });

        // If a matching option is found, switch to select mode
        if (matchingOption) {
            setIsSelectMode(true);
        } else {
            setIsSelectMode(false);
        }
    };

    const handleSelectOption = (event, option) => {
        event.stopPropagation();
        setInputValue(option);
        setIsSelectMode(false);
        props.onChange({ target: { value: option, name: props.name } });
    };

    const handleBlur = (e) => {
        if (e.target.tagName.toLowerCase() === 'li') return
        // If no option was selected, submit the custom input
        setIsSelectMode(false);
        props.onChange({ target: { value: inputValue, name: props.name } });
    };

    return (
        <div onMouseDown={handleBlur} onFocus={() => setIsSelectMode(true)} className='relative laptop:w-7/12 tablet:w-full mobile:w-full'>
            {isSelectMode && <div onClick={handleBlur} className='z-100 fixed top-0 left-0 w-screen h-screen'></div>}
            <input
                type="text"
                value={inputValue}
                name={props.name}
                className='p-1 w-full text-base input-color outline-none hide-input-background'
                onChange={handleInputChange}
                disabled={props.disabled}
                placeholder={props.placeholder}
            />
            {isSelectMode &&
                <div className="card dropdown absolute top-[100%] left-0 w-full">
                    <ul className='w-full'>
                        {props.options.map((option) => (
                            <li
                                key={option}
                                className='p-2 bg-[white] w-full cursor-pointer'
                                onClick={(e) => handleSelectOption(e, option)}
                            >
                                {option}
                            </li>
                        ))}
                    </ul>
                </div>
            }
        </div>
    );
}

export default SelectOrType;
