import { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import city from '../../assets/lottie/city.json'
import road from '../../assets/lottie/road.json'
import Lottie from 'lottie-react'
import API from '../../utils/API';
import Swal from 'sweetalert2';
import GlobalSVG from '../../utils/GlobalSVG';
import { useSpring, animated } from 'react-spring'

const PublicGenerationTotal = () => {

    const Integer = ({ n, from }) => {
        const { number } = useSpring({
            from: { number: from },
            number: n,
            delay: 3000,
            config: { mass: 1, tension: 20, friction: 10 },
            format: (value) => value.toLocaleString()
        })
        return <animated.div>{number.to((n) => Math.ceil(n).toLocaleString())}</animated.div>
    }

    const location = useLocation();
    const [amount, setAmount] = useState(null)
    const [animation, setAnimation] = useState(null)
    const [metadata, setMetadata] = useState(null)
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

    const style = {
        backgroundImage: `url(${require('../../assets/images/sloar-background.png')})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    };

    useEffect(() => {
        let id = queryParams.get('id')
        let arr = location.pathname.split("/")
        setAnimation(arr[arr.length - 1])

        const cancelToken = API.cancelToken();
        const getData = async () => {
            try {
                let reduction = await API.getPublicGenerationTotal(cancelToken, id)
                let tempMeta = {}
                tempMeta = await API.getPublicBuilding(cancelToken, id)
                setMetadata(tempMeta.data)
                setAmount(reduction.data)
            } catch (err) {
                console.log(err)
                if (err.message === 'cancelling') return
                if (err.response && err.response.data) {
                    try {
                        if (err.response.data.includes('error')) {
                            Swal.fire({
                                title: 'Error.',
                                text: err.response.data.error,
                                icon: 'warning',
                                confirmButtonText: 'Ok',
                                confirmButtonColor: '#46775A',
                            });
                        }
                    } catch (e) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    }
                } else {
                    Swal.fire({
                        title: 'Error.',
                        text: 'An error occurred, please try again later.',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    });
                }
            }
        }

        getData()

        return () => {
            API.cancel(cancelToken);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams, location.pathname])

    return (
        <div className='flex flex-col flex-nowrap item-center jusitfy-center h-screen relative'>
            <div className='flex flex-nowrap justify-between items-center z-[100] bg-[white]'>
                <div className='w-1/2 desktop:py-[40px] desktop:px-[130px] laptop:py-[30px] laptop:px-[40px]'>
                    {
                        metadata &&
                        <div className='flex flex-nowrap justify-start items-center h-[143px]'>
                            <div className="relative w-full h-full max-w-[143px] max-h-[143px] mr-4">
                                <div className="absolute inset-0 rounded-full overflow-hidden">
                                    <img className="w-full h-full object-cover object-center" src={metadata.image} alt="" />
                                </div>
                            </div>
                            <div className='flex flex-nowrap justify-start items-center h-[143px] border-y border-[#979797] '>
                                <div className='flex flex-col flex-nowrap justify-center item-center h-full'>
                                    <div className="flex flex-nowrap justify-center item-center desktop:text-[32px] laptop:text-[20px] text-[#383838] font-semibold pr-[36px]">
                                        {metadata.building}
                                    </div>
                                </div>
                                <div className='border-[#979797] border h-[60%]'></div>
                                <div className="flex flex-nowrap justify-start item-center h-full">
                                    <img className='h-full' src={metadata.logo} alt="" />
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className='flex flex-nowrap justify-between items-center w-1/2 desktop:py-[40px] desktop:px-[130px] laptop:py-[30px] laptop:px-[30px] bg-[white]'>
                    <img className='w-[353px] ' src={require('../../assets/images/greenwave-black.png')} alt="" />
                    <div>
                        <p className='italic text-[16px] text-[#252525] text-center'>
                            Making our world more responsible...
                        </p>
                        <p className='italic text-[16px] text-[#252525] text-center'>
                            one building at a time!
                        </p>
                    </div>
                </div>
            </div>
            <div className='flex flex-nowrap justify-between items-center flex-grow h-full'>
                <div className="w-1/2 flex flex-col justify-between items-stretch h-full">
                    <div className='flex flex-nowrap items-center justify-center h-full relative' style={style}>
                        {amount !== null &&
                            <div className={`w-full`}>
                                <h5 className={`text-[40px] font-semibold text-[#FFFFFF] text-center mt-[40px]`}>
                                    Renewable Generation <br /> Since Implementation
                                </h5>
                                <h5 className={`font-extrabold text-[150px] text-[#FFD44D] text-center`}>
                                    {amount.amount && <Integer n={(amount.amount)} from={(amount.amount - 10) < 0 ? 0 : (amount.amount - 10)} />}
                                </h5>
                                <h5 className={`text-[40px] text-[#FFFFFF] text-center`}>
                                    kWh
                                </h5>
                            </div>}
                    </div>
                </div>
                <div className="w-1/2 h-full relative">
                    <div className='w-full h-full'>
                        {amount && animation === 'house' && <Lottie animationData={city} style={{
                            width: '100%',
                            height: '100vh',
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            zIndex: 0
                        }} />}
                        {amount && animation === 'car' && <Lottie animationData={road} style={{
                            width: '100%',
                            height: '100vh',
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            zIndex: 0
                        }} />}
                    </div>
                    {amount && animation === 'house' &&
                        <div className='absolute top-0 right-0 flex flex-nowrap justify-center items-center w-full h-full'>
                            <div className='text-[white]'>
                                <p className='text-[40px] font-semibold text-center'>Household’s annual</p>
                                <p className='text-[40px] font-semibold text-center'>electricity use</p>
                                <div className='text-[150px] font-bold text-center'>
                                    <Integer n={amount.co / 1.4956} from={(amount.co - 10) / 1.4956 < 0 ? 0 : (amount.co - 10) / 1.4956} />
                                </div>
                            </div>
                        </div>}
                    {amount && animation === 'car' &&
                        <div className='absolute top-0 right-0 flex flex-nowrap justify-center items-center w-full h-full'>
                            <div className='text-[white]'>
                                <p className='text-[40px] font-semibold text-center'>Passenger vehicles taken</p>
                                <p className='text-[40px] font-semibold text-center'>off the road for 1 year</p>
                                <div className='text-[150px] font-bold text-center'>
                                    <Integer n={(amount.co / 3.26)} from={(amount.co - 10) / 3.26 < 0 ? 0 : (amount.co - 10) / 3.26} />
                                </div>
                            </div>
                        </div>}
                </div>
            </div>
            <div className={`absolute w-full flex flex-nowrap items-center justify-center z-[1000] top-[60%]`}>
                <div className={`flex flex-nowrap items-center justify-center rounded-full w-[105px] h-[105px] bg-[white] drop-shadow-md`}>
                    {GlobalSVG.equal()}
                </div>
            </div>
        </div>
    )
};

export default PublicGenerationTotal;