import './style.css'
import { useState, useEffect, useRef } from 'react';
import Loader from "../Loader";
import { HighchartsReact } from 'highcharts-react-official';
import Highcharts from "highcharts/highstock";
import moment from 'moment';
import GraphFuncs from '../../utils/GraphFuncs';

export default function PortfolioGraph2(props) {

    const [options, setOptions] = useState()
    const [currentData, setCurrentData] = useState(null)
    const [loading, setLoading] = useState(true)
    const chartRef = useRef(null);
    const [unit, setUnit] = useState('gas')
    const [totals, setTotals] = useState({ gas: 0, water: 0 })


    useEffect(() => {
        if (props.circuits === null) return
        setLoading(true)
        let tempSeriesE = []
        let tempTotal = { gas: 0, water: 0 }

        let sortedList = Object.keys(props.circuits)
        sortedList.sort(function (a, b) {
            var itemA = props.circuits[a];
            var itemB = props.circuits[b];

            switch (unit) {
                case "gas": default:
                    itemA = itemA.gas ? parseFloat(itemA.gas) : 0
                    itemB = itemB.gas ? parseFloat(itemB.gas) : 0
                    break
                case "water":
                    itemA = itemA.water ? parseFloat(itemA.water) : 0
                    itemB = itemB.water ? parseFloat(itemB.water) : 0
                    break
            }

            if (itemA > itemB) {
                return -1;
            }
            if (itemA < itemB) {
                return 1;
            }
            return 0;
        });

        for (let key of sortedList) {
            let item = props.circuits[key]
            switch (unit) {
                case "gas": default:
                    tempSeriesE.push({ name: item.building, y: parseFloat(item.gas) })
                    tempTotal[unit] += item.gas ? parseFloat(item.gas) : 0
                    break
                case "water":
                    tempSeriesE.push({ name: item.building, y: parseFloat(item.water) })
                    tempTotal[unit] += item.water ? parseFloat(item.water) : 0
                    break
            }
        }
        for (let key in tempTotal) {
            tempTotal[key]= parseInt(tempTotal[key]).toLocaleString() + (key === 'water' ? ' L' : key === 'gas' ? ' m³' : '')
        }
        let eSeries = {
            name: unit === 'gas' ? "Natural Gas" : "Water",
            data: tempSeriesE,
            color: "#F9941E",
        }
        setTotals(tempTotal)
        setCurrentData(eSeries)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.circuits, unit])



    useEffect(() => {
        if (!currentData || currentData.length === 0) return
        setOptions({
            chart: {
                type: 'column'
            },
            credits: {
                enabled: false,
            },
            legend: {
                enabled: true,
                align: 'left',
                layout: 'horizontal',
                verticalAlign: 'bottom',
                itemMarginBottom: 0,
                itemMarginTop: 0,
            },
            title: {
                text: '',
                align: 'left'
            },
            subtitle: {
                text: '',
                align: 'left'
            },
            xAxis: {
                type: 'category',
                min: 0,
                max: currentData.data.length < 20 ? currentData.data.length - 1 : 20,
                accessibility: {
                    description: 'Building'
                },
                scrollbar: {
                    enabled: true
                },
            },
            yAxis: {
                title: {
                    align: 'high',
                    offset: 0,
                    text: '',
                    rotation: 0,
                    y: 10,
                },
                labels: {
                    formatter: function () {
                        return this.value;
                    }
                }
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + Math.round(this.y).toLocaleString() + '</b><br/>'
                },
            },
            plotOptions: {
                column: {
                    dataLabels: {
                        enabled: false
                    }
                },
                series: {
                    stacking: 'normal',
                },
            },
            series: [currentData],
            exporting: {
                enabled: true,
                buttons: {
                    contextButton: {
                        menuItems: [
                            'viewFullscreen', 'separator', 'downloadCSV', 'downloadPNG', 'downloadPDF'
                        ]
                    },
                },
            }
        })
        setLoading(false)
    }, [currentData])

    const handleUnitChange = (e) => {
        setUnit(e.currentTarget.value)
    }

    return (
        <div>
            <div className={`card tablet:p-8 mobile:p-4 rounded-lg relative mt-8`}>
                <div className='flex flex-nowrap justify-between items-center'>
                    <div>
                        <h2 className="capitalize w-full laptop:text-3xl tablet:text-xl   font-bold text-[#332D41] md:decoration-dashed">Portfolio Gas & Water</h2>
                        {props.startEnd && <p className='mb-4 font-light text-sm'>Date Range: <span className='font-normal'>{moment(props.startEnd[0]).format("MMM DD, YYYY")} - {moment(props.startEnd[1]).format("MMM DD, YYYY")} ({GraphFuncs.getDifferenceInDays(props.startEnd[0], props.startEnd[1])} days)</span></p>}
                        {props.compare && props.compareStartEnd.length === 2 && <p className='mb-4 font-light text-sm'>Comparative Range: <span className='font-normal'>{moment(props.compareStartEnd[0]).format("MMM DD, YYYY")} - {moment(props.compareStartEnd[1]).format("MMM DD, YYYY")} ({GraphFuncs.getDifferenceInDays(props.compareStartEnd[0], props.compareStartEnd[1])} days)</span></p>}
                    </div>
                    <select className='w-1/2 p-2 border border-1 rounded  border-[#C1C1C1] text-[#377745] w-[220px]' value={unit} onChange={handleUnitChange}>
                        <option value="gas">Gas (m³)</option>
                        <option value="water">Water (L)</option>
                    </select>
                </div>
                <h2 className='mb-2 font-medium mt-4'>{props.compare ? "Portfolio Total Difference" : "Portfolio Total"}: {totals[unit]}</h2>
                <div className="flex flex-nowrap tablet:justify-end laptop:justify-start mobile:justify-end items-center rounded-lg min-h-[600px]">
                    <div className={`col-span-2 w-full`}>
                        {options && <HighchartsReact
                            highcharts={Highcharts}
                            options={options}
                            ref={chartRef}
                        />}
                        {loading && <Loader />}
                    </div>
                </div>
            </div >
        </div>
    )
};