import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import Loader from "../Loader";
import { Link, useNavigate} from 'react-router-dom';
import './style.css'
import API from '../../utils/API';
import Swal from 'sweetalert2';

const Roles = () => {
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const context = useContext(AppContext)
    const navigate = useNavigate();

    useEffect(() => {
        if (!context.getUserInfo) return
        let access = ["access_roles_list", "create_roles", "update_roles"]
        if (!access.some(item => context.getUserInfo[item] === true)) {
            navigate('/settings/clients')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.getUserInfo])

    useEffect(() => {
        setLoading(true)
        if (!context.getUser) return
        const cancelToken = API.cancelToken();
        const auth = context.getUser.token;
        API.getRoles(cancelToken, auth).then(res => {
            setData(res.data)
            setLoading(false)
            return
        }).catch(err => {
            setLoading(false)
            console.log(err)
            if (err.message === 'cancelling') return
            Swal.fire({
                title: 'Error.',
                text: 'Please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            });

        })

        return () => {
            API.cancel(cancelToken);
        }
    }, [context.getUser])

    return (
        <div className='tablet:p-8 mobile:p-3 min-h-[90vh]'>
            <div className='card tablet:p-8 mobile:p-3 min-h-[80vh]'>
                <div className='h-5/6 w-full border-light-green mt-8'>
                    <div className='h-[60vh] overflow-y-auto'>
                        {
                            loading === true ?
                                <Loader />
                                :
                                data.map((e, index) => (
                                    <Link key={index + "tableitem"} className='w-full p-4 input-border flex flex-wrap' to={`/role?role=${e.role_id}`}>
                                        <p>{e.role}</p>
                                    </Link>
                                ))
                        }
                    </div>
                </div>
                <Link to={`/role/create`} className='w-full border-light-green block mt-8 text-sm text-green4 font-medium'>
                    <span className='text-lg mr-2'>+</span>
                    Add new role
                </Link>
            </div>
        </div>
    );
};

export default Roles;