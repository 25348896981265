import { HighchartsReact } from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import { useRef, useEffect, useContext, useState } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import GlobalFuncs from "../../utils/GlobalFuncs";
import API from "../../utils/API";
import Loader from "../Loader";
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import HighchartsExporting from 'highcharts/modules/exporting';
import GlobalSVG from "../../utils/GlobalSVG";

export default function PieChart(props) {

    const context = useContext(AppContext);
    const [eOptions, setEOptions] = useState(null)
    const [wOptions, setWOptions] = useState(null)
    const [gOptions, setGOptions] = useState(null)
    const [currentData, setCurrentData] = useState(null)
    const [finalData, setFinalData] = useState(null)
    const [options, setOptions] = useState(null)
    const [type, setType] = useState('e')
    const chartRef = useRef(null);
    const [hightContrast, setHighContrast] = useState(false)
    let hcColors = ['#58826A', '#DD6B20', '#A22', '#17AEEE', '#FFD233', '#BFDA45', '#F75D5F', '#A6ABAD']
    let lcColors = ["#58826A", "#619E7B", "#75B08E", "#92C4A7", "#B2D8C3", "#377745", "#D2CECE", "#638971"]

    const today = new Date();
    const lastMonth = new Date();
    today.setDate(today.getDate());
    lastMonth.setDate(lastMonth.getDate() - 30);
    HighchartsAccessibility(Highcharts);
    HighchartsExporting(Highcharts);

    useEffect(() => {
        if (!props.mainCircuitE || !context.getBuildingInfo) return
        const cancelToken = API.cancelToken();
        if (context.getBuildingInfo.e_channels.length !== 0) {
            if (context.getFixed30DayData === null || context.getFixed30DayData.e_pie === null) {
                try {
                    let start = GlobalFuncs.formatDate(lastMonth, 'YYYY-MM-DD')
                    let end = GlobalFuncs.formatDate(today, 'YYYY-MM-DD')
                    let building = context.getBuildingInfo.building_id

                    API.getCatConsumption(cancelToken, start, end, building).then(res => {
                        let colors = hightContrast ? [...hcColors] : [...lcColors]
                        let data = res.data
                        let colorIndex = 0
                        let tempPieOpt = []
                        for (const key of Object.keys(data).sort()) {
                            if (key === 'total' || key.toLowerCase() === 'capacitor bank') continue
                            let tempObj = {}
                            tempObj.name = key
                            tempObj.y = parseFloat(((data[key] / data.total) * 100).toFixed(2))
                            tempObj.color = colors[colorIndex]
                            colorIndex++
                            if (colorIndex === colors.length) colorIndex = 0
                            tempPieOpt.push(tempObj)
                        }
                        setEOptions(tempPieOpt)
                        context.setFixed30DayData(e => ({ ...e, e_pie: tempPieOpt }))
                    })
                } catch (e) {
                    if (e.message === 'cancelling') return
                }
            } else {
                setEOptions(context.getFixed30DayData.e_pie)
            }
        } else {
            setEOptions([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.mainCircuitE, context.getBuildingInfo])

    useEffect(() => {
        if (!currentData) return
        let tempData = JSON.parse(JSON.stringify(currentData))
        let colors = hightContrast ? [...hcColors] : [...lcColors]
        let index = 0
        for (let item of tempData) {
            item.color = colors[index]
            index++
        }
        setFinalData(tempData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hightContrast, currentData])

    useEffect(() => {
        if (!props.mainCircuitW || !context.getBuildingInfo) return
        const cancelToken = API.cancelToken();
        if (context.getBuildingInfo.w_channels.length !== 0) {
            if (context.getFixed30DayData === null || context.getFixed30DayData.w_pie === null) {
                try {
                    let start = GlobalFuncs.formatDate(lastMonth, 'YYYY-MM-DD')
                    let end = GlobalFuncs.formatDate(today, 'YYYY-MM-DD')
                    let building = context.getBuildingInfo.building_id

                    const auth = context.getUser.token;
                    API.getCatConsumptionD(cancelToken, auth, start, end, building, 'Water').then(res => {
                        let colors = hightContrast ? [...hcColors] : [...lcColors]
                        let data = res.data
                        let colorIndex = 0
                        let tempPieOpt = []
                        for (const key of Object.keys(data).sort()) {
                            if (key === 'total') continue
                            let tempObj = {}
                            tempObj.name = key
                            tempObj.y = parseFloat(((data[key] / data.total) * 100).toFixed(2))
                            tempObj.color = colors[colorIndex]
                            colorIndex++
                            if (colorIndex === colors.length) colorIndex = 0
                            tempPieOpt.push(tempObj)
                        }
                        setWOptions(tempPieOpt)
                        context.setFixed30DayData(e => ({ ...e, w_pie: tempPieOpt }))
                    })
                } catch (e) {
                    if (e.message === 'cancelling') return
                }
            } else {
                setWOptions(context.getFixed30DayData.w_pie)
            }
        } else {
            setWOptions([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.mainCircuitW, context.getBuildingInfo])

    useEffect(() => {
        if (!props.mainCircuitG || !context.getBuildingInfo) return
        const cancelToken = API.cancelToken();
        if (context.getBuildingInfo.g_channels.length !== 0) {
            if (context.getFixed30DayData === null || context.getFixed30DayData.g_pie === null) {
                try {
                    let start = GlobalFuncs.formatDate(lastMonth, 'YYYY-MM-DD')
                    let end = GlobalFuncs.formatDate(today, 'YYYY-MM-DD')
                    let building = context.getBuildingInfo.building_id

                    const auth = context.getUser.token;
                    API.getCatConsumptionD(cancelToken, auth, start, end, building, 'Natural Gas').then(res => {
                        let colors = hightContrast ? [...hcColors] : [...lcColors]
                        let data = res.data
                        let colorIndex = 0
                        let tempPieOpt = []
                        for (const key of Object.keys(data).sort()) {
                            if (key === 'total') continue
                            let tempObj = {}
                            tempObj.name = key
                            tempObj.amount = parseFloat(((data[key])).toFixed(2))
                            tempObj.y = parseFloat(((data[key] / data.total) * 100).toFixed(2))
                            tempObj.color = colors[colorIndex]
                            colorIndex++
                            if (colorIndex === colors.length) colorIndex = 0
                            tempPieOpt.push(tempObj)
                        }
                        setGOptions(tempPieOpt)
                        context.setFixed30DayData(e => ({ ...e, g_pie: tempPieOpt }))
                    })
                } catch (e) {
                    if (e.message === 'cancelling') return
                }
            } else {
                setGOptions(context.getFixed30DayData.g_pie)
            }
        } else {
            setGOptions([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.mainCircuitG, context.getBuildingInfo])

    useEffect(() => {
        switch (type) {
            case 'e': default:
                if (eOptions === null) return
                setCurrentData(eOptions)
                break;

            case 'w':
                if (wOptions === null) return
                setCurrentData(wOptions)
                break;

            case 'g':
                if (gOptions === null) return
                setCurrentData(gOptions)
                break;
        }
    }, [type, eOptions, wOptions, gOptions])

    useEffect(() => {
        if (!finalData) return
        setOptions({
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                zooming: {
                    mouseWheel: {
                        enabled: false
                    }
                },
            },
            credits: {
                enabled: false,
            },
            title: {
                text: '',
                align: 'left'
            },
            tooltip: {
                formatter: function () {
                    return `<p style='font-size:12px'>${this.key}</p><br><p><span style='font-size:10px'>Consumption:</span> ${this.y}%</p`

                }
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: false
                    },
                    point: {
                        events: {
                            click: function () {
                                return false
                            }
                        }
                    },
                    showInLegend: true
                }
            },
            series: [{
                colorByPoint: true,
                data: finalData
            }],
            exporting: {
                enabled: true,
                chartOptions: {
                    plotOptions: {
                        pie: {
                            dataLabels: {
                                enabled: true,
                                pointFormat: '<p>{point.name}</p>: <b>{point.percentage:.1f}%</b>'
                            },
                        },
                    },
                },
            },
        })
    }, [finalData])

    const handleType = (e) => {
        setCurrentData(null)
        setType(e.currentTarget.value)
    }


    return (
        <div className='' >
            {
                Array.isArray(currentData) ?
                    <div className="w-full">
                        <div className='flex justify-between mb-2 w-full mobile:flex-col tablet:flex-row'>
                            <h2 className="capitalize w-full laptop:text-3xl tablet:text-xl font-bold text-[#332D41] md:decoration-dashed">Previous 30 days </h2>
                            <div className="flex laptop:justify-center tablet:justify-start">
                                <div className='flex justify-end items-center'>
                                    <p className="font-sans font-normal leading-5 text-sans text-[#332D41] md:decoration-dashed">Utility:</p>
                                </div>
                                <div className='flex justify-start'>
                                    <select value={type} onChange={handleType} className="outline-none mx-2 font-sans w-[134px] text-[#377745] border-2 border-solid border-neutral-200 rounded-lg text-sm leading-4 font-bold">
                                        <option value='e'>Electricity</option>
                                        <option value='w'>Water</option>
                                        <option value='g'>Gas</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="w-full">
                            {(Highcharts && currentData && chartRef && options) && <HighchartsReact
                                highcharts={Highcharts}
                                options={options}
                                ref={chartRef}
                            />}
                        </div>
                        <div className='w-full flex flex-nowrap justify-end items-center cursor-pointer' onClick={() => setHighContrast(!hightContrast)}>
                            {
                                GlobalSVG.colorPalette()
                            }
                            <p className='my-4 mr-4 ml-2 text-[#F75D5F]'>High Contrast {hightContrast ? "Off" : ""}</p>
                        </div>
                    </div>
                    :
                    <Loader />
            }
        </div>
    )
}