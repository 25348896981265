import { useContext, useEffect, useState, useRef } from "react";
import API from "../../utils/API";
import { AppContext } from '../../utils/ContextProvider';
import GlobalSVG from '../../utils/GlobalSVG';
import Swal from 'sweetalert2';
import LoaderWheel from "../LoaderWheel";
import ImpactLine from "../ImpactLine";
import Tooltip from '../ToolTip';

const DetailsModal = (props) => {

    const context = useContext(AppContext);
    const [activity, setActivity] = useState({})
    const [adding, setAdding] = useState(false)
    const [creating, setCreating] = useState(false)
    const [edit, setEdit] = useState(props.create ? true : false)
    const statusColor = { 'Implemented': '#107048', 'In Progress': '#F9941E', 'Investigating': '#B8BBB9', 'On Hold': 'black', 'Not Implemented': '#F75D5F' }
    // const statusBorder = { 'Implemented': '#107048', 'In Progress': '#F9941E', 'Investigating': '#B8BBB9', 'On Hold': 'black', 'Not Implemented': '#F75D5F' }
    const modal = useRef()

    useEffect(() => {
        if (props.create) setActivity({ building_id: context.getBuildingInfo.building_id })
        else {
            let tempActivity = JSON.parse(JSON.stringify(props.activities.find((e) => e.id === props.activity)))
            for (let key in tempActivity) {
                if (typeof tempActivity[key] === 'number' && !Number.isInteger(tempActivity[key]) && key.includes('Price')) {
                    tempActivity[key] = Math.round(tempActivity[key])
                }
            }
            setActivity(tempActivity)
        }
    }, [props.activity, props.activities, props.create, context.getBuildingInfo])

    useEffect(() => {
        if (adding) {
            if (modal.current) {
                modal.current.scrollTo({ top: modal.current.scrollHeight, behavior: 'smooth' });
            }
        }
    }, [adding])

    const handleSave = () => {
        const auth = context.getUser.token;
        setCreating(true)
        if (props.create) {
            API.createActivity(auth, activity).then(async res => {
                res.data.impacts = []
                props.setActivities(e => [...e, res.data]);
                setActivity(res.data)
                setAdding(false)
                props.setShowCreate(false)
                props.setShowDetails(res.data.id)
                setEdit(false)
            }).catch(e => {
                console.log(e)
                Swal.fire({
                    title: 'Oops!',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }).finally(f => {
                setCreating(false)
            })
        } else {
            let allowed = ["name", "status", "description"]
            let payload = JSON.parse(JSON.stringify(activity))
            for (let key in payload) {
                if (!allowed.includes(key)) delete payload[key]
            }
            API.updateActivity(auth, payload, activity.id).then(async res => {
                let tempActivities = JSON.parse(JSON.stringify(props.activities))
                let updatedActivities = tempActivities.map(e => {
                    if (e.id === activity.id) {
                        return JSON.parse(JSON.stringify(activity));
                    }
                    else return e
                });
                updatedActivities.sort((a, b) => a.id - b.id);
                props.setActivities(updatedActivities);
                setAdding(false)
                setEdit(false)
            }).catch(e => {
                console.log(e)
                Swal.fire({
                    title: 'Oops!',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }).finally(f => {
                setCreating(false)
            })
        }
    }

    const updateActivity = (event) => {
        setActivity(e => ({ ...e, [event.target.name]: event.target.value }))
    }

    return (
        <div className='fixed w-screen h-screen top-0 left-0 modal-background flex flex-nowrap justify-center items-center'>
            <div ref={modal} className='card w-[75vw] h-[75vh] m-2 p-[40px] pt-[0] background-white rounded-md overflow-auto' onClick={(e) => { e.stopPropagation() }}>
                <div className='min-w-[920px]'>
                    <div className="flex flex-nowrap justify-end items-center pt-[20px] w-full">
                        {
                            !creating && !edit &&
                            <div className="w-[15px] h-[15px] cursor-pointer" onClick={() => setEdit(true)}>
                                {GlobalSVG.edit('black')}
                            </div>
                        }
                        {
                            !creating && edit &&
                            <div className="py-[2px] px-2 bg-[#107048] text-[white] text-[12px] rounded-[4px] cursor-pointer" onClick={handleSave}>
                                Save
                            </div>
                        }
                        {
                            creating &&
                            <LoaderWheel />
                        }

                        <div className="cursor-pointer ml-4" onClick={() => { props.setShowDetails(false); props.setShowCreate(false) }}>
                            {GlobalSVG.closeXBig('black')}
                        </div>
                    </div>
                    <div className="flex flex-nowrap justify-between items-start">
                        <div className="w-5/12 mr-4">
                            <p className="text-[12px] text-[#B8BBB9] font-semibold mb-[10px]">Action Title</p>
                            <input
                                type="text"
                                onChange={updateActivity}
                                name="name"
                                disabled={!edit}
                                className="tablet:text-4xl mobile:text-xl font-bold text-[#332D41] mb-[20px] w-full outline-none hide-input-background"
                                defaultValue={activity.name} />
                        </div>
                        <div className="w-5/12">
                            <p className="text-[12px] text-[#B8BBB9] font-semibold mb-[10px]">Building</p>
                            <div className="flex flex-row flex-nowrap laptop:gap-14 tablet:gap-10 justify-between mobile:pr-4 tablet:pr-6 min-h-[44px] items-center">
                                <div className="flex flex-row items-center gap-2">
                                    <div className="justify-end">
                                        <img className='navbar-image-limiter rounded-[4px]' src={context.getBuildingInfo.image ? context.getBuildingInfo.image : require('../../assets/images/placeholder-image.png')} alt="" />
                                    </div>
                                    <div className="justify-start">
                                        <p className="font-bold laptop:text-sm tablet:text-xs leading-5 truncate laptop:max-w-none tablet:max-w-[180px] text-start">
                                            {context.getBuildingInfo.building}
                                        </p>
                                        <div className="flex gap-0 laptop:w-content">
                                            <p className="font-normal text-[11px] leading-5 truncate laptop:max-w-none tablet:max-w-[180px]" >
                                                {`${context.getBuildingInfo.address} ${context.getBuildingInfo.city} ${context.getBuildingInfo.province}`}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-[25px]">
                            <select
                                onChange={updateActivity}
                                className={`w-[160px] rounded-md p-1 font-semibold border border-[${statusColor[activity.status]}] text-[${statusColor[activity.status]}]`}
                                disabled={!edit}
                                name="status"
                                value={activity.status}>
                                <option value=""> - </option>
                                <option value="Implemented">Implemented</option>
                                <option value="In Progress">In Progress</option>
                                <option value="Investigating">Investigating</option>
                                <option value="On Hold">On Hold</option>
                                <option value="Not Implemented">Not Implemented</option>
                            </select>
                        </div>
                    </div>
                    <div className="flex flex-nowrap justify-between items-start">
                        <div className="w-10/12">
                            <p className="text-[12px] text-[#B8BBB9] font-semibold mb-[10px]">Description & Notes</p>
                            <textarea
                                data-gramm="false"
                                data-gramm_editor="false"
                                data-enable-grammarly="false"
                                type="text"
                                name="description"
                                disabled={!edit}
                                className="text-sm text-[#332D41] mb-[10px] w-full outline-none hide-input-background"
                                defaultValue={activity.description}
                                onChange={updateActivity}></textarea>
                        </div>
                        <div className="w-[160px]">
                            <p className="text-[12px] text-[#B8BBB9] font-semibold mb-[10px]">Created On</p>
                            <p className="text-[12px] font-semibold mb-[20px] ">{activity.created_at && activity.created_at.split("T")[0]}</p>
                        </div>
                    </div>
                    <div className="flex flex-nowrap justify-between items-center">
                        <div className='mr-[15px] min-w-[320px] w-[320px] bg-[#FAFAFA] rounded-md p-[30px]'>
                            <div className="flex flex-nowrap justify-start items-center">
                                <p className='font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Electricity Reductions</p>
                            </div>
                            <div className=''>
                                <div className="flex flex-nowrap justify-between items-center">
                                    <div className='mb-2 w-[160px] border-r border-[#B8BBB9]'>
                                        <p className='font-semibold mb-1'>{activity.kwhToDate ? activity.kwhToDate.toLocaleString() : "0"} kWh</p>
                                        <p className='font-semibold text-[#107048]'>${activity.kwhPriceToDate ? activity.kwhPriceToDate.toLocaleString() : "0"}</p>
                                    </div>
                                    <div>
                                        <Tooltip text="Summation of total monthly peak kVA reduced" show={true}>
                                            <p className='font-semibold mb-1'>{activity.kvaToDate ? activity.kvaToDate.toLocaleString() : "0"} kVA <span className='text-[10px] align-super'>&#9432;</span></p>
                                        </Tooltip>
                                        <p className='font-semibol text-[#107048]'>${activity.kvaPriceToDate ? activity.kvaPriceToDate.toLocaleString() : "0"}</p>
                                    </div>
                                </div>
                                <p className='text-[10px] text-[#B8BBB9] mb-2'>Since Implemented</p>
                                <div className="flex flex-nowrap justify-between items-center">
                                    <div className='mb-2 w-[160px] border-r border-[#B8BBB9]'>
                                        <p className='font-semibold mb-1'>{activity.kwhYear ? activity.kwhYear.toLocaleString() : "0"} kWh</p>
                                        <p className='font-semibol text-[#107048]'>${activity.kwhPriceYear ? activity.kwhPriceYear.toLocaleString() : "0"}</p>
                                    </div>
                                    <div>
                                        <Tooltip text="Summation of total monthly peak kVA reduced" show={true}>
                                            <p className='font-semibold mb-1'>{activity.kvaYear ? activity.kvaYear.toLocaleString() : "0"} kVA <span className='text-[10px] align-super'>&#9432;</span></p>
                                        </Tooltip>
                                        <p className='font-semibol text-[#107048]'>${activity.kvaPriceYear ? activity.kvaPriceYear.toLocaleString() : "0"}</p>
                                    </div>
                                </div>
                                <p className='text-[10px] text-[#B8BBB9] mb-2'>Last 12 Months</p>
                            </div>
                        </div>
                        <div className='mr-[15px] min-w-[190px] w-[190px] bg-[#FAFAFA] rounded-md p-[30px]'>
                            <div className="flex flex-nowrap justify-start items-center">
                                <p className='w-[150px] font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Gas Reductions</p>
                            </div>
                            <div className=''>
                                <div className="flex flex-nowrap justify-between items-center">
                                    <div className='mb-2'>
                                        <p className='font-semibold mb-1'>{activity.gasToDate ? activity.gasToDate.toLocaleString() : "0"} ft³</p>
                                        <p className='font-semibol text-[#107048]'>${activity.gasPriceToDate ? activity.gasPriceToDate.toLocaleString() : "0"}</p>
                                    </div>
                                </div>
                                <p className='text-[10px] text-[#B8BBB9] mb-2'>Since Implemented</p>
                                <div className="flex flex-nowrap justify-between items-center">
                                    <div className='mb-2'>
                                        <p className='font-semibold mb-1'>{activity.gasYear ? activity.gasYear.toLocaleString() : "0"} ft³</p>
                                        <p className='font-semibol text-[#107048]'>${activity.gasPriceYear ? activity.gasPriceYear.toLocaleString() : "0"}</p>
                                    </div>
                                </div>
                                <p className='text-[10px] text-[#B8BBB9] mb-2'>Last 12 Months</p>
                            </div>
                        </div>
                        <div className='mr-[15px] min-w-[190px] w-[190px] bg-[#FAFAFA] rounded-md p-[30px]'>
                            <div className="flex flex-nowrap justify-start items-center">
                                <p className='w-[150px] font-semibold mb-[10px] text-[12px] text-[#B8BBB9]'>Water Reductions</p>
                            </div>
                            <div className=''>
                                <div className="flex flex-nowrap justify-between items-center">
                                    <div className='mb-2'>
                                        <p className='font-semibold mb-1'>{activity.waterToDate ? activity.waterToDate.toLocaleString() : "0"} L</p>
                                        <p className='font-semibol text-[#107048]'>${activity.waterPriceToDate ? activity.waterPriceToDate.toLocaleString() : "0"}</p>
                                    </div>
                                </div>
                                <p className='text-[10px] text-[#B8BBB9] mb-2'>Since Implemented</p>
                                <div className="flex flex-nowrap justify-between items-center">
                                    <div className='mb-2'>
                                        <p className='font-semibold mb-1'>{activity.waterYear ? activity.waterYear.toLocaleString() : "0"} L</p>
                                        <p className='font-semibol text-[#107048]'>${activity.waterPriceYear ? activity.waterPriceYear.toLocaleString() : "0"}</p>
                                    </div>
                                </div>
                                <p className='text-[10px] text-[#B8BBB9] mb-2'>Last 12 Months</p>
                            </div>
                        </div>
                        <div className='mr-[15px] min-w-[190px] w-[190px] bg-[#F2F8F5] rounded-md p-[30px]'>
                            <div className="flex flex-nowrap justify-start items-center">
                                <p className='w-[150px] font-semibold mb-[10px] text-[12px] text-[#B8BBB9] text-center'>Total Savings</p>
                            </div>
                            <div className='text-center'>
                                <div className="flex flex-nowrap justify-center items-center">
                                    <div className='mb-2'>
                                        <p className='font-semibold mb-1'>{activity.coToDate ? Math.round(activity.coToDate).toLocaleString() : "0"} t CO₂e</p>
                                        <p className='font-semibol text-[#107048]'>${(
                                            (activity.kwhPriceToDate ? activity.kwhPriceToDate : 0) +
                                            (activity.kvaPriceToDate ? activity.kvaPriceToDate : 0) +
                                            (activity.gasPriceToDate ? activity.gasPriceToDate : 0) +
                                            (activity.waterPriceToDate ? activity.waterPriceToDate : 0)
                                        ).toLocaleString()}</p>
                                    </div>
                                </div>
                                <p className='text-[10px] text-[#107048] font-semibold mb-2'>Since Implemented</p>
                                <div className="flex flex-nowrap justify-center items-center">
                                    <div className='mb-2'>
                                        <p className='font-semibold mb-1'>{activity.coYear ? Math.round(activity.coYear).toLocaleString() : "0"} t CO₂e</p>
                                        <p className='font-semibol text-[#107048]'>${(
                                            (activity.kwhPriceYear ? activity.kwhPriceYear : 0) +
                                            (activity.kvaPriceYear ? activity.kvaPriceYear : 0) +
                                            (activity.gasPriceYear ? activity.gasPriceYear : 0) +
                                            (activity.waterPriceYear ? activity.waterPriceYear : 0)
                                        ).toLocaleString()}</p>
                                    </div>
                                </div>
                                <p className='text-[10px] text-[#107048] font-semibold mb-2'>Last 12 Months</p>
                            </div>
                        </div>
                    </div>
                    {!props.create &&
                        <div className="flex flex-nowrap justify-between items-center mt-[30px] mb-[10px]">
                            <h2 className="text-xl font-semibold">Circuit Impacts <span className="text-base font-normal text-[#B8BBB9] ml-2"> {activity.impacts ? activity.impacts.length : 0}</span></h2>
                            <div className="flex flex-nowrap justify-end items-center">
                                {GlobalSVG.plus2()}
                                <p className='text-[#107048] tablet:text-[16px] mobile:text-[12px] font-medium cursor-pointer ml-2' onClick={() => setAdding(true)}>Add Impact</p>
                            </div>
                        </div>}
                    {
                        !props.create && activity && activity.impacts && activity.impacts.map((e, ix) => (
                            <ImpactLine key={e.id + "impact" + ix} impact={e.id} impacts={activity.impacts} activities={props.activities} setActivities={props.setActivities} activity={props.activity} />
                        ))
                    }
                    {
                        adding &&
                        <ImpactLine impact={{}} create={true} activities={props.activities} setActivities={props.setActivities} activity={props.activity} setAdding={setAdding} />
                    }
                </div>
            </div>
        </div>
    )
}

export default DetailsModal;