import "./style.css"

function Loader() {
    return (
        <div className="loader text-center m-4">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}
export default Loader;